import React from 'react'
import { useAuth } from 'utils/auth'
import { Navigate } from 'react-router-dom'
export const CandidateDone = ({ children }) => {
    const auth = useAuth()
    // const navigate = useNavigate()
    console.log("auth.candidateDone")
    
    console.log(auth.candidateDone)

    if (auth.candidateDone == false || auth.candidateDone == "false") {
        return <Navigate to="/candidate/profile" />

    }
  return  children
}
