import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ShareButton from '../../images/components/shareButton.svg';
import downArrow from '../../images/components/downArrow.svg';
import rightArrow from '../../images/components/rightArrow.svg';
import SkillsRequiredSection from './SkillsRequiredSection';
import fallback_image from '../../images/fallback_image.png';
import { useAuth } from 'utils/auth';
import { applyJob } from 'components/Fetchers';
import { percentageCalculator, totalUniqueSkillsCalculator } from 'utils/job';
// import { useAuth } from 'utils/auth';
import { MuiAlert, Snackbar, Alert } from 'components/MUI';
import { useQuery, useMutation } from '@tanstack/react-query';

import NewSocialShare from 'components/SocialMedia/NewSocialShare';
import PercentageMatch from './PercentageMatch';
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const OpenCloseBox = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': {
            display: 'none',
          },
          '.expandIconWrapper': {
            display: 'none',
          },
          '.Mui-expanded & > .expandIconWrapper': {
            display: 'block',
          },
        }}
      >
        {children}
      </Box>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="sm:mt-2">{children}</div>}
    </div>
  );
}

const toLowerCase = (array) => {
  return array.map((item) => {
    return item.toLowerCase();
  });
};

const uniqueSkillsArray = (job) => {
  // concate must have and good to have skills and convert to lowercase
  const totalSkills = [...job.mustHaveSkills, ...job.goodToHaveSkills];
  // get only unique skills
  const lowerCaseUniqueTotalSkills = [...new Set(toLowerCase(totalSkills))];
  return lowerCaseUniqueTotalSkills;
};

function NewJobsList(props) {
  const { job, handleApplyAfterEffect, candidateSkills, candidateData } = props;
  const auth = useAuth();

  const [value, setValue] = useState(0);
  const [errorIncompleteProfile, setErrorIncompleteProfile] = useState(false);
  const [shareShow, setShareShow] = useState(false);
  const onErrorImcompleteProfile = () => {
    console.log('errorTrue');
    setErrorIncompleteProfile(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorIncompleteProfile(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const mutation = useMutation(applyJob, {
    onSuccess: (data) => {
      console.log(data);
      handleApplyAfterEffect(job._id, true);
    },
    onError: (error) => {
      console.log(error);
      handleApplyAfterEffect(job._id, false);
    },
  });
  const handleApply = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      (auth.candidateDone == false || auth.candidateDone == 'false') &&
      (candidateData.resume == null || candidateData.resume == undefined)
    ) {
      onErrorImcompleteProfile();
      return;
    }
    mutation.mutate({ id: job._id });
  };

  const addImageFallback = (event) => {
    event.currentTarget.src = fallback_image;
  };
  if (candidateSkills) {
    var lowerCaseUniqueCandidateSkills =
      totalUniqueSkillsCalculator(candidateSkills);
  }
  // console.log(job);
  const handleShare = (e) => {
    // stop the propagation of the event
    e.stopPropagation();

    setShareShow((prev) => !prev);
    // console.log("share");
  };
  return (
    <div className="center-align">
      <Accordion
        className="mb-[22px] rounded-xl py-3 sm:pt-7 sm:pb-8"
        sx={{
          boxShadow: 'none',
        }}
        key={job._id}
      >
        <AccordionSummary
          onClick={() => setShareShow(false)}
          disableGutters
          className="zeroMargin"
        >
          <div className="uppertextsection sm:pl-3 w-full flex">
            <div className="jobheader flex gap-2 sm:gap-5 font-sans justify-between ">
              <img
                onError={addImageFallback}
                src={job.company?.photo ? job.company?.photo : fallback_image}
                alt=""
                // className="jobDetails__logo"
                className="h-[75px] w-[75px] md:ml-1 sm:h-[86px] sm:w-[86px] object-contain items-center"
                border="0"
              />
              <div className=" flex flex-col flex-grow">
                <div className="">
                  {/* <div className=""> */}
                  <div className="max-w-full ">
                    {/* <div className="truncate line-clamp-2 whitespace-normal break-all  font-bold  capitalize sm:text-[19px] mb-1.5 ">
                      {job.title}
                    </div> */}
                    <OpenCloseBox>
                      <div className="expandIconWrapper">
                        <div className="truncate  whitespace-normal break-all  font-bold  capitalize sm:text-[19px] mb-1.5 ">
                          {job.title}
                        </div>
                      </div>
                      <div className="collapsIconWrapper">
                        <div className="truncate line-clamp-2 whitespace-normal break-all  font-bold  capitalize sm:text-[19px] mb-1.5 ">
                          {job.title}
                        </div>
                      </div>
                    </OpenCloseBox>
                    {/* <div className=" whitespace-normal break-all  font-bold  capitalize sm:text-[19px] mb-1.5 "> */}
                  </div>
                </div>

                <div className="text-sm font-normal  mb-[3px]">
                  {job.company?.companyName}
                </div>
                <div className="text-[#939393] text-sm align-middle flex">
                  <div> {job.location}</div>
                </div>
                <div className="mt-2 block xs:hidden">
                  <PercentageMatch
                    big
                    candidateSkills={candidateSkills}
                    job={job}
                  />
                </div>

                <OpenCloseBox>
                  <div className="expandIconWrapper"></div>
                  <div className="collapsIconWrapper mt-3 ">
                    <div
                      onClick={handleApply}
                      className="applyNowButton max-fit"
                    >
                      Apply Now
                    </div>
                  </div>
                </OpenCloseBox>
              </div>
            </div>

            <div className="ml-auto hidden xs:block">
              <PercentageMatch
                big
                candidateSkills={candidateSkills}
                job={job}
              />
            </div>
            <div className="ml-auto xs:hidden"></div>

            <div className="ml-2 sm:twml-4 md:ml-8 sm:mr-[10px] md:mr-[60px] lg:mr-[118px]">
              <div className="flex flex-col space-y-[35px] h-full mt-2 ">
                <img
                  src={ShareButton}
                  alt="share"
                  className="rightUserIcons"
                  onClick={handleShare}
                />
                {shareShow && (
                  <NewSocialShare setShareShow={setShareShow} job={job} />
                )}
                <OpenCloseBox>
                  <div className="expandIconWrapper">
                    <img
                      src={downArrow}
                      alt="arrow"
                      className="rightUserIcons"
                    />
                  </div>
                  <div className="collapsIconWrapper">
                    <img
                      src={rightArrow}
                      alt="arrow"
                      className="rightUserIcons"
                    />
                  </div>
                </OpenCloseBox>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '0px 16px 10px',
          }}
          disableGutters
        >
          <div className="mx-0 sm:mx-[10px] md:mx-[60px] lg:mx-[100px]">
            <div className="p-[1px]  bg-[#DFDFDF]"></div>
            <div className="flex flex-col sm:flex-row py-1.5 sm:py-2.5 spacePadding justify-between text-sm text-[#BDBDBD] border-2 border-black">
              <div>
                Type : <span className="text-black">{job.type}</span>
              </div>
              <div>
                Total Employees :{' '}
                <span className="text-black">
                  {job.company?.aboutCompany?.teamSize}
                </span>
              </div>
              <div>
                Company Stage :{' '}
                <span className="text-black">
                  {job.company?.aboutCompany?.stage}
                </span>
              </div>
            </div>
            <div className="p-[1px]  bg-[#DFDFDF]"></div>

            {/* <div className="p-[1px] mx-2 bottom-2 relative bg-[#DFDFDF]"></div> */}
            <div className="w-full mx-auto ">
              <div className="flex">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  // variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      height: '0px',
                    },
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'justify-between',
                    width: '100%',
                  }}
                >
                  <Tab
                    // className="p-0"
                    sx={{
                      color: 'black',
                    }}
                    className="pl-0 sm:pl-2 md:pl-3 lg:pl-5"
                    disableRipple
                    label={
                      <div className="font-bold text-base capitalize ">
                        Skills Required
                      </div>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      color: 'black',
                    }}
                    className="px-2 sm:px-0"
                    disableRipple
                    label={
                      <div className="font-bold text-base capitalize">
                        Job Description
                      </div>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{
                      color: 'black',
                    }}
                    className="pr-0 sm:pr-2 md:pr-3 lg:pr-5"
                    disableRipple
                    label={
                      <div className="font-bold text-base capitalize">
                        About Company
                      </div>
                    }
                    {...a11yProps(3)}
                  />
                </Tabs>
              </div>
              <TabPanel value={value} index={0}>
                <SkillsRequiredSection
                  key={job._id}
                  skills={job.mustHaveSkills}
                  mustHaveSkills={job.mustHaveSkills}
                  goodToHaveSkills={job.goodToHaveSkills}
                  data={job}
                  candidateSkills={lowerCaseUniqueCandidateSkills}
                  job={job}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <div className="spacePadding">
                  <div className="text-lg">Job Description</div>
                  <div className="p-2 whitespace-pre-line text-base overflow-y-auto max-h-80">
                    {job.description}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="spacePadding">
                  <div className=" text-lg">About Company</div>
                  <div className="p-2 whitespace-pre-line">
                    {job.company?.about}
                  </div>
                  <div className=" text-lg">Video Link</div>
                  <div className="p-2 whitespace-pre-line">
                    <a
                      className="text-blue-600 underline underline-offset-2"
                      href={job.company?.aboutCompany?.video}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {job.company?.aboutCompany?.video}
                    </a>
                  </div>
                  <div className=" text-lg">Website</div>
                  <div className="p-2 whitespace-pre-line">
                    <a
                      className="text-blue-600 underline underline-offset-2"
                      href={job.company?.aboutCompany?.website}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {job.company?.aboutCompany?.website}
                    </a>
                  </div>
                </div>
              </TabPanel>
            </div>
            <OpenCloseBox>
              <div className="expandIconWrapper mt-5 spacePadding">
                <div onClick={handleApply} className="applyNowButton ">
                  Apply Now
                </div>
              </div>
            </OpenCloseBox>
          </div>
          {/* <button className="featured__button" onClick={handleApply}>
          Apply Now
        </button>
        <button className="whiteButton" onClick={() => toggle("apply")}>
          Apply Now
        </button> */}
        </AccordionDetails>
        {/* <div className="my-auto flex ">
        <button className="whiteButton " onClick={() => toggle("apply")}>
          Apply Now
        </button>
      </div> */}
      </Accordion>
      <Snackbar
        open={errorIncompleteProfile}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">
          Please Complete Your Profile
        </Alert>
      </Snackbar>
    </div>
  );
}

export default NewJobsList;
