import React from "react";
import CountUp from "react-countup";
import SearchMenu from "./SearchMenu";
import leftVector from "../../images/components/left-vector.svg";
import rightVector from "../../images/components/right-vector.svg";
const counterList = [
  {
    number: 1000,
    title: "Candidate",
  },
  {
    number: 400,
    title: "Recruiters",
  },
  {
    number: 4000,
    title: "Job Posted",
  },
];

const HeroSection = ({ jobs }) => {
  return (
    <>
      <div className="center-align">
        <div className="px-0  z-0">
          <div className="relative">
            <div className="absolute lg:inline hidden w-[287.53px] top-[110px] h-[382.41px]">
              <img src={leftVector} alt="" />
            </div>
          </div>
          <div className="relative">
            <div className="absolute lg:inline hidden right-0  w-[332.13px] top-[90px] h-[431.81px] ">
              <img src={rightVector} alt="" />
            </div>
          </div>
        </div>
        <div className="flex  flex-col font-sans text-center mx-auto sm:mb-10">
          <div className="heading z-10 text-4xl md:text-6xl mt-16 sm:mt-20 md:mt-[200px]">
            <div className="font-semibold  text-black md:mb-4 mx-2">
              Job Matchmaker for
            </div>
            <div className="font-black text-primarycolor mb-4">
              Tech Talent
            </div>
          </div>
          <div className="subheading w-8/12 md:w-2/4 mx-auto">
            Techies! get hired smartly. Our AI deeptech will increase your
            visibility by data matchmaking, which means a fair screening process
            and getting hired for the skills relevant to the job.
          </div>
          <div className="mt-5 sm:mt-8 md:mt-12 lg:mt-[74px]">
            <div className="searchmenu ">
              <SearchMenu jobs={jobs} />
            </div>
          </div>
          <div className="flex mx-auto gap-8 xs:gap-12 sm:gap-28 justify-between mt-10 sm:mt-[86px] mb-[80px] sm:mb-[172px]">
            {counterList.map((counter, index) => {
              return (
                <div className="box flex flex-col">
                  <div className="">
                    <CountUp
                      start={0}
                      end={counter.number}
                      duration={0.5}
                      className="text-2xl font-bold text-black"
                    />
                    +
                  </div>
                  <div className="text-base font-normal">
                    {counter.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
