import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import "../style.css";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs, { Dayjs } from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useState } from "react";
export const EditEducationField = ({ data, editVariable, fullData, index, updateState, doneHandler, deleteHandler,handleDateChange,candidate }) => {
  const [edit, setEdit] = useState(editVariable);

  const [inputFields, setInputFields] = useState(data);

  return (
    <>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      {inputFields ? (
        <>
          {edit ? (
            <>
              <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                <Box
                  key={inputFields.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "85%",
                    marginLeft: "2rem",
                  }}
                >
                  <MobileDatePicker
                      inputFormat="MM/DD/YYYY"
                      label="Start Date"
                      value={fullData[index].startDate}
                      onChange={(e) => {
                        // const date= dayjs(e).format('DD-MM-YYYY').toString()
                        handleDateChange(index,e,'startDate');
                      }}
                      renderInput={(params) => <TextField name="startDate" variant="standard" sx={{
                        display: "inline",
                        marginBottom: "1rem",
                        width: "50%"
                      }}
                        {...params} />}
                    />

                      <MobileDatePicker
                      inputFormat="MM/DD/YYYY"
                      label="End Date"
                      value={fullData[index].endDate}
                      onChange={(e) => {
                     
                        // const date= dayjs(e).format('DD-MM-YYYY').toString()
                        
                        // handleDateChange(index,date,'endDate');
                        handleDateChange(index,e,'endDate');
                        
                      }}
                      renderInput={(params) => <TextField name="endDate" variant="standard" sx={{
                        display: "inline",
                        marginBottom: "1rem",
                        width: "50%"
                      }}
                        {...params} />}
                    />
                  <TextField
                    variant="standard"
                    label="Duration"
                    name="duration"
                    size="small"
                    sx={{
                      display: "inline",
                      marginBottom: "1rem",
                    }}
                    value={fullData[index].duration}
                    onChange={(e) => {
                      updateState(index, e);
                    }}
                  />
                  <TextField
                    variant="standard"
                    label="Course Name"
                    size="small"
                    name="courseName"
                    sx={{
                      width: "50%",
                      marginBottom: "1rem",
                    }}
                    value={fullData[index].courseName}
                    onChange={(e) => {
                      console.log("e value is", e.target.value);
                      console.log(fullData);
                      updateState(index, e);
                    }}
                  />
                  <TextField
                    variant="standard"
                    label="College Name"
                    name="collegeName"
                    size="small"
                    sx={{
                      width: "50%",
                      marginBottom: "1rem",
                    }}
                    value={fullData[index].collegeName}
                    onChange={(e) => {
                      updateState(index, e);
                    }}
                  />
                  <TextField
                    variant="standard"
                    label="Description"
                    size="small"
                    name="description"
                    multiline
                    sx={{
                      width: "85%",
                      marginBottom: "1rem",
                    }}
                    value={fullData[index].description}
                    onChange={(e) => {
                      updateState(index, e);
                    }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                
                  <DoneIcon
                    sx={{
                      fontSize: 20,
                      color: "gray",
                      margin: "auto",
                      "&:hover": { color: "black", cursor: "pointer" },
                      display: "inline",
                    }}
                    onClick={() => {
                      if (fullData[index].duration === "" || fullData[index].courseName === "" || fullData[index].collegeName === "" || fullData[index].description === "") {
                        alert("Please fill all the fields");
                      }
                      else {
                        setEdit(!edit);
                        doneHandler()
                      }
                    }}
                  />
                  <DeleteIcon
                    sx={{
                      fontSize: 15,
                      color: "lightgray",
                      margin: "auto 0rem",
                      "&:hover": { color: "gray", cursor: "pointer" },
                      display: "flex",
                    }}
                    onClick={() => {
                      deleteHandler(fullData[index]._id)
                    }}
                  />

                </Box>

              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: "flex" ,  justifyContent: "space-between"}}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <div className="projectsW__details">
                      <p className="details__brownBox">{`${dayjs(fullData[index].startDate).format('MMM-YYYY')}   -   ${dayjs(fullData[index].endDate).format('MMM-YYYY')}`} </p>
                      <p className="details__title">{fullData[index].courseName}</p>
                    <p className="details__thinText">
                        {fullData[index].collegeName}
                    </p>
                    <p className="details__description">
                        {fullData[index].description}
                    </p>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "auto",
                    gap: 2,
                    marginBottom: "auto",
                  }}
                >
                    {candidate ? (<> <EditIcon
                    sx={{
                      fontSize: 15,
                      color: "lightgray",
                      margin: "auto",
                      "&:hover": { color: "gray", cursor: "pointer" },
                      display: "inline",
                    }}
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  />
                  <DeleteIcon
                    sx={{
                      fontSize: 15,
                      color: "lightgray",
                      margin: "auto 0rem",
                      "&:hover": { color: "gray", cursor: "pointer" },
                      display: "flex",
                    }}
                    onClick={() => {
                      deleteHandler(fullData[index]._id)
                    }}
                  /></>):(<></>)}
                 
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    
    </LocalizationProvider>
    </>
  );
};


export default EditEducationField;
