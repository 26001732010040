import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {cities} from 'data/cities';
import {
  Box, Container, Grid, StepLabel, TextField, Button, Typography,
  Input, MenuItem, Menu, InputAdornment, Chip, Autocomplete, Modal,
  Slide, Stepper, Step, Snackbar, ArrowBackIcon, ChevronRightIcon, EditIcon, DoneIcon, Alert
} from "components/MUI";
import "./style.css";
import { styled } from "@mui/system";
import InputTextField from "components/PostJob/InputTextField";
import axois from "axios";
import { useAuth } from 'utils/auth';
import Navbar from "components/Navbar"
import {
  recruiterData,
  imageUpload,
  getAiSkills,
} from 'components/Fetchers';
import { StyledNextButton, StyledBackButton, StyledAutocomplete, StyleTextField } from "components/StyledComponents";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from 'uuid';




function Postjob() {
  const auth = useAuth();
  const [companyID, setCompanyID] = useState("")
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);


  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const commaSplitter = (string) => {
    return string.split(",").map((item) => item.trim());
  };
  const idGenerator = (myArray) => {
    let arrayOfObjects = []
    myArray.map((item) => {
      arrayOfObjects.push({ id: uuid(), content: item })
    })
    return arrayOfObjects
  }
  const goodToHaveSkillsValueAdder = (string) => {
    // const newArray = idGenerator(commaSplitter(string))
    const newArray = idGenerator(duplicateRemover(columns.goodToHaveSkills.items, (commaSplitter(string))))

    setColumns({
      ...columns,
      goodToHaveSkills: {
        items: [...columns.goodToHaveSkills.items,
        ...newArray
        ],
      }
    });
  }
  const mustHaveSkillsValueAdder = (string) => {
    // const newArray = idGenerator(commaSplitter(string))
    const newArray = idGenerator(duplicateRemover(columns.mustHaveSkills.items, (commaSplitter(string))))
    // console.log(columns.mustHaveSkills.items)
    setColumns({
      ...columns,
      mustHaveSkills: {
        items: [...columns.mustHaveSkills.items,
        ...newArray
        ],
      }
    });
  }

  const duplicateRemover = (oldArray, newArray) => {
    const oldArrayContent = oldArray.map((item) => item.content.toLowerCase())
    console.log(oldArray)
    const newArrayContent = newArray.map((item) => item)
    console.log(newArray)
    const finalArray = newArrayContent.filter((item) => !oldArrayContent.includes(item.toLowerCase()))
    console.log(finalArray)
    return finalArray
  }




  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [editData, setEditData] = useState({
    description: false,
    skills: false,
    salaryCompensation: false,
  });
  const [value, setValue] = useState(null);
  const locations = cities;
  const education = [
    "Secondary Education",
    "Higher Secondary Education",
    "Graduation",
    "Post Graduation",
    "Doctorate",
  ];
  const types = ["Full Time", "Part Time", "Contract", "Internship"];
  const jobCategories = [
    "Blockchain",
    "Business Intelligence",
    "Cloud Computing",
    "Data Science",
    "DevOps",
    "Full Stack",
    "Game Development",
    "Machine Learning",
    "Mobile Development",
    "Product Management",
    "Project Management",
    "Quality Assurance",
    "UI/UX Design",
    "Web Development",
    "Other",
  ];
  const modeOfWork = ["Office", "Hybrid", "Work From Home"];
  const [inputValues, setInputValues] = useState({
    title: "",
    location: "",
    type: "",
    category: "",
    jobPostEmail: "",
    requiredExperience: "",
    description: "",
    goodToHaveSkills: [],
    mustHaveSkills: [],
    minSalary: "",
    maxSalary: "",
    minRate: "",
    maxRate: "",
    hoursPerWeek: "",
    externalLink: "",
  });
  // console.log({ value });
  // console.log({ inputValues });

  // attach bearer token from context api and send it to axois
  const token = auth.token

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // send api rest to axois with bearer token

  // const dummyData = {
  //   title: "SDE",
  //   location: "Bangalore",
  //   type: "Full Time",
  //   category: "Full Stack",
  //   description: "sdgsdg",
  //   goodToHaveSkills: [
  //     "sdfb"
  //   ],
  //   mustHaveSkills: [
  //     "sdfg"
  //   ],
  //   minSalary: "",
  //   maxSalary: "",
  //   minRate: "",
  //   maxRate: "",
  //   hoursPerWeek: "",
  //   externalApplyLink: ""
  // }
  // console.log(dummyData)



  const handleSubmit = () => {
    const data = {
      title: inputValues.title,
      location: inputValues.location,
      type: inputValues.type,
      category: inputValues.category,
      jobPostEmail: inputValues.jobPostEmail,
      requiredExperience: inputValues.requiredExperience,
      description: inputValues.description,
      goodToHaveSkills: inputValues.goodToHaveSkills,
      mustHaveSkills: inputValues.mustHaveSkills,
      minSalary: inputValues.minSalary,
      maxSalary: inputValues.maxSalary,
      minRate: inputValues.minRate,
      maxRate: inputValues.maxRate,
      hoursPerWeek: inputValues.hoursPerWeek,
      externalApplyLink: inputValues.externalLink,
    };
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API__URL}/api/jobs`, data, config)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (res.status === 201) {
          onSuccess()
          setTimeout(() => {
            navigate("/recruiter/dashboard");
          }, 3500);

        }
      })
      .catch((err) => {
        console.log(err);
        onError()
        // console.log('handle click')

      });
  };

  const skills = ["HTML", "CSS", "JavaScript"];

  const [imageLink, setImageLink] = useState("")
  const handleInputChange = (e, inputValueName) => {
    let variable = e.target.name;
    setInputValues({
      ...inputValues,
      [inputValueName]: e.target.value,
    });
    console.log(inputValues);
  };


  // write a function that only accept numbers and decimal numbers and not any other character and replace invalid characters with empty string



  const DecimalChecker = value => {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '')
  }

  const uploadFileHandler = async (e) => {
    imageUpload(e.target.files[0], companyID, auth.token).then((res) => {
      setImageLink(res);
    }).catch((e) => {
      console.log(e)
    })
  };





  // const file = e.target.files[0];
  // const imageData = new FormData();
  // imageData.append("companyImage", file);
  // // console.log(data);
  // // const imageData = { "companyImage": file }
  // console.log(imageData)
  // console.log(companyID)
  // try {
  //   const imageUploadconfig = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       "Authorization": `Bearer ${auth.token}`,

  //     },
  //   };
  //   const { data } = await axios.patch(`${process.env.REACT_APP_API__URL}/api/company/${companyID}/image`, imageData, imageUploadconfig);
  //   console.log(data);
  //   if (data.result.photo) {
  //     console.log("success");
  //     console.log(data.result.photo)
  //     setImageLink(data.result.photo)

  //   }
  //   else {
  //     console.log("error");

  //   }
  // }
  // catch (error) {
  //   console.log(error);
  // }}

  useEffect(() => {
    recruiterData().then((res) => {
      // console.log(res);
      if (res.company) {
        // console.log(res.company);
        setCompanyID(res.company._id)
      }
    });
  }, []);


  const hiddenFileInput = React.useRef(null);
  const handleImageUploadClick = event => {
    hiddenFileInput.current.click();
  };


  const postFlaskApi = (data) => {
    console.log("Getting data from flask api")
    console.log(data)
    getAiSkills(data).then((res) => {
      console.log(res.skills)
      setColumns({
        ...columns,
        skillList: {
          ...columns.skillList,
          items: generateIDWithSkills(res.skills)
        }
      })
    })
  }
  const generateIDWithSkills = (skills) => {
    const arrayList = skills.map((skill) => {
      return {
        id: uuid(),
        content: skill,
      };
    });
    return arrayList;
  };



  const renderSwitchButtons = (param) => {
    switch (param) {
      case 0:
        return (<>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}>
            <StyledNextButton onClick={handleFirstNext}>Save & Continue <ChevronRightIcon /></StyledNextButton>
          </Box></>)
      case 1:
        return (<>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
            <StyledNextButton onClick={handleBack}>Back</StyledNextButton>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}>

              <StyledNextButton onClick={handleSecondNext}>Save & Continue  <ChevronRightIcon /></StyledNextButton>
            </Box>
          </Box>
        </>)
      case 2: return (<>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
          <StyledNextButton onClick={handleBack}>Back</StyledNextButton>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}>

            <StyledNextButton onClick={handleThirdNext}>Submit <ChevronRightIcon /></StyledNextButton>
          </Box>
        </Box></>)
      case 3:
        return (<>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
            <StyledNextButton onClick={handleBack}>Back</StyledNextButton>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}>

              <StyledNextButton onClick={handleFourthNext}>Submit <ChevronRightIcon /></StyledNextButton>
            </Box>
          </Box></>)
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleFirstNext = () => {
    if (
      inputValues.title == "" ||
      inputValues.description == "" ||
      inputValues.location == "" ||
      inputValues.category == "" ||
      inputValues.jobPostEmail == "" ||
      inputValues.type == "" ||
      inputValues.requiredExperience == ""
    ) {
      alert("Please fill all the required fields");
      return;
    } else {
      postFlaskApi(inputValues.description)
      setActiveStep(activeStep + 1);
    }
  }
  const handleSecondNext = () => {
    if (
      columns.goodToHaveSkills.items.length == 0 ||
      columns.mustHaveSkills.items.length == 0
    ) {
      alert("Please complete both of the skill fields.");
      return;
    } else {
      console.log("COLUMNS")
      console.log(columns)
      console.log("GOOD TO HAVE SKILLS")
      console.log(columns.goodToHaveSkills)
      console.log(columns.goodToHaveSkills.items.length > 0)
      console.log(columns.goodToHaveSkills.items.length < 0)
      setActiveStep(activeStep + 1);

      console.log(columns)

      const goodToHaveSkills = columns.goodToHaveSkills.items.map((item) => {
        return item.content;
      });
      const mustHaveSkills = columns.mustHaveSkills.items.map((item) => {
        return item.content;
      });
      console.log("NICE TO HAVE SKILLS")
      console.log(goodToHaveSkills)
      console.log("MUST TO HAVE SKILLS")
      console.log(mustHaveSkills)
      setInputValues({
        ...inputValues,
        goodToHaveSkills: goodToHaveSkills,
        mustHaveSkills: mustHaveSkills,
      });

    }
  }



  const handleNumber = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleDecimalInputChange = (value) => {
    const DecimalValue = DecimalChecker(value)
    console.log(DecimalValue)
    // check if it is blank or not
    // if (value === "") {
    //   console.log("returning")
    //   return
    // }

      console.log("adding change")
      setInputValues({
        ...inputValues,
        requiredExperience: DecimalValue,
      })


  }
  const handleThirdNext = () => {
    setInputValues({
      ...inputValues,
      minSalary: handleNumber(inputValues.minSalary),
      maxSalary: handleNumber(inputValues.maxSalary),
      minRate: handleNumber(inputValues.minRate),
      maxRate: handleNumber(inputValues.maxRate),
      hoursPerWeek: handleNumber(inputValues.hoursPerWeek),
    })
    setActiveStep(activeStep + 1);
  }


  const handleFourthNext = () => {
    // console.log(editData);
    // console.log(activeStep);
    // if (
    //   editData.description == true ||
    //   editData.skills == true ||
    //   editData.salaryCompensation == true
    // ) {
    //   alert("Please save the changes");
    //   return;
    // }
    if (activeStep == 3) {
      // console.log('submitiing data')
      handleSubmit();
      return
    }
    setActiveStep(activeStep + 1);
  }


  const [apiSkills, setApiSkills] = useState(["HTML", "CSS", "Express", "React", "Node", "MongoDB", "Python", "Java", "C++", "C", "C#", "Ruby", "PHP", "Swift", "Kotlin", "Go", "Rust", "Scala", "SQL", "NoSQL", "Git", "Docker", "Kubernetes", "AWS", "Azure", "GCP", "Firebase", "Heroku", "Netlify", "Vercel", "Jenkins", "Travis CI", "Circle CI", "Ansible", "Terraform", "Puppet", "Chef", "Linux", "Windows", "MacOS", "Android"])
  const itemsFromBackend = [
    { id: uuid(), content: "HTML" },
    { id: uuid(), content: "CSS" },
    { id: uuid(), content: "Javascript" },
  ]
  const columnsFromBackend = {

    mustHaveSkills: {
      // name: "Must Have Skills",
      items: []
    },
    skillList: {
      // name: "Requested",
      items: itemsFromBackend
    },
    goodToHaveSkills: {
      // name: "Good to Have Skills",
      items: []
    },
  };
  const [columns, setColumns] = useState(columnsFromBackend);




  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };



  return (
    <Box style={{ backgroundColor: "#F9F9F9" }}>
      <div style={{ marginBottom: '3rem' }}>
        {/* Navbar */}
        <Navbar />

        {/* <button onClick={onError}>click me error</button>
      <button onClick={onSuccess} >click me success</button> */}

        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "3rem",
            borderRadius: 5,
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
            maxWidth: "lg",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            component="h2"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              margin: "3rem 0rem 2rem 0rem",

            }}
          >
            Post Job
          </Typography>

          <Box
            sx={{
              width: "70%",
              margin: "0rem 0rem 2rem 1rem",
            }}
          >
            <Stepper
              activeStep={activeStep}
              sx={{
                "& .MuiStepIcon-active": { color: "red" },
                "& .MuiStepIcon-completed": { color: "green" },
                "& .Mui-disabled .MuiStepIcon-root": {
                  color: "#043047",
                },
              }}
              alternativeLabel
            >
              <Step >
                <StepLabel >

                  <Typography variant="h6" sx={{ textAlign: "center", fontFamily: 'Outfit', marginBottom: '0.5rem' }}>
                    Job Description
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>

                  <Typography variant="h6" sx={{ textAlign: "center", fontFamily: 'Outfit', marginBottom: '0.5rem' }}>
                    Skills
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography variant="h6" sx={{ textAlign: "center", fontFamily: 'Outfit', marginBottom: '0.5rem' }}>
                    Salary Compensation
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography variant="h6" sx={{ textAlign: "center", fontFamily: 'Outfit', marginBottom: '0.5rem' }}>
                    Preview
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
          {/* row1 */}

          {(() => {
            switch (activeStep) {
              case 0:
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        width: "90%",
                        fontFamily: "Outfit",
                        left: "3rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <InputTextField
                          onChange={handleInputChange}
                          inputValueName="title"
                          inputValues={inputValues.title}
                          label="Job Title*"
                          placeholder="eg. Software Engineer"
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <label className="postjob__label">Job Location*</label>
                        <StyledAutocomplete
                          options={locations}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{}}
                              className="postjob__input"
                              placeholder="eg. Hyderabad"
                            />
                          )}
                          value={inputValues.location}
                          onChange={(e, newValue) =>
                            setInputValues({
                              ...inputValues,
                              location: newValue,
                            })
                          }
                        />
                      </div>
                      <Box className="flex__col_item" style={{ width: "100%", display: "flex", flexDirection: 'column' }}>
                        <>
                          <label className="postjob__label">Company Photo

                          </label>

                          <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={(e) => uploadFileHandler(e)}
                            style={{ display: 'none', }}
                          />

                          <Button
                            // className="postjob__input"
                            onClick={handleImageUploadClick}
                            sx={{
                              marginTop: "1rem",
                              backgroundColor: "#E0E0E0",
                              padding: "0.9rem",
                              borderRadius: "10px",
                              color: "#335869",
                              "&:hover": {
                                background: "lightgray",
                              },
                              fontWeight: "bold",
                              fontFamily: "Outfit",
                            }}
                          >
                            {" "}
                            {imageLink == "" ? "Upload Image" : "Image Uploaded"}

                          </Button>


                        </>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "space-between",
                        width: "90%",
                        fontFamily: "Outfit",
                        marginTop: "1rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <label className="postjob__label">Job Type*</label>
                        <StyledAutocomplete
                          options={types}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{}}
                              className="postjob__input"
                              placeholder="eg Full Time"
                            />
                          )}
                          value={inputValues.type}
                          onChange={(e, newValue) =>
                            setInputValues({
                              ...inputValues,
                              type: newValue,
                            })
                          }
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <label className="postjob__label">Job Category*</label>
                        <StyledAutocomplete
                          options={jobCategories}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{}}
                              className="postjob__input"
                              placeholder="Choose Category"
                            />
                          )}
                          value={inputValues.category}
                          onChange={(e, newValue) =>
                            setInputValues({
                              ...inputValues,
                              category: newValue,
                            })
                          }
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <label className="postjob__label">Company Email*</label>
                        <StyleTextField
                          className="postjob__input"
                          placeholder="Email"
                          value={inputValues.jobPostEmail}
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              jobPostEmail: e.target.value,
                            })
                          }
                        />
                      </div>
                    </Box>



                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        width: "90%",
                        fontFamily: "Outfit",
                        left: "3rem",
                        marginTop: "1rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>


                        <label className="postjob__label">Required Experience*</label>

                        <InputTextField
                          placeholder="eg Experience required in Years"

                          onChange={(e)=> handleDecimalInputChange(e.target.value)}
                          inputValueName="requiredExperience"
                          inputValues={inputValues.requiredExperience}
                        />
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        margin: "0rem 0rem 1.5rem 0rem",
                        justifyContent: "center",
                        width: "90%",
                        fontFamily: "Outfit",
                        marginTop: "1rem",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <label className="postjob__label">Job Description*</label>

                        <StyleTextField
                          inputProps={{
                            style: { color: "gray", marginLeft: "1rem" },
                          }}
                          className="postjob__input"
                          placeholder="eg Full Time"
                          multiline
                          value={inputValues?.description}
                          rows={15}
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              description: e.target.value,
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </>
                );
              case 1:
                return (
                  <>
                    <Box
                      sx={{
                        width: "90%",
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "2rem",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          justifyContent: "space-between",
                          width: "100%",
                          fontFamily: "Outfit",
                          marginTop: "1rem",
                        }}
                      >
                        <div style={{ width: '40%' }}>
                          <Typography
                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            Must have Skills*
                          </Typography>
                          {/* <button onClick={()=> console.log(columns)}>submit</button> */}
                          <StyledAutocomplete
                            options={skills}
                            freeSolo
                            autoSelect
                            renderInput={(params) => (
                              <TextField
                                {...params}

                                sx={{ borderRadius: "0.3rem" }}
                                className="postjob__input"
                              // placeholder="e.g.Java Scripts"
                              />
                            )}
                            onChange={(e, newValue) => {
                              // console.log(newValue);
                              if (newValue === null) {
                                return;
                              } else {
                                mustHaveSkillsValueAdder(newValue);

                                // setColumns({
                                //   ...columns,
                                //   mustHaveSkills: {
                                //     items: [...columns.mustHaveSkills.items,
                                //     { id: uuid(), content: newValue }
                                //     ],
                                //   }
                                // });

                                // mustHaveSkillsValueAdder(newValue);
                              }
                              console.log(columns);
                              console.log(columns.mustHaveSkills);
                              console.log(inputValues.mustHaveSkills);
                            }}
                          />
                        </div>
                        <div style={{ width: '40%' }}>
                          <Typography
                            sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                          >
                            Good to have Skills*
                          </Typography>
                          <StyledAutocomplete
                            options={skills}
                            freeSolo
                            autoSelect
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ borderRadius: "0.3rem" }}
                                className="postjob__input"
                                placeholder="e.g.Java Script"
                              />
                            )}
                            onChange={(e, newValue) => {
                              console.log(newValue);
                              if (newValue === null) {
                                return;
                              } else {
                                // console.log(idGenerator(commaSplitter(newValue)))
                                goodToHaveSkillsValueAdder(newValue)
                                // idGenerator(commaSplitter(newValue)).forEach((item) => {
                                //   console.log(item);
                                //   setColumns({
                                //     ...columns,
                                //     goodToHaveSkills: {
                                //       items: [...columns.goodToHaveSkills.items,
                                //       // { id: uuid(), content: newValue }
                                //       item
                                //       // idGenerator(commaSplitter(newValue))
                                //       ],
                                //     }
                                //   });
                                // })
                                // setColumns({
                                //   ...columns,
                                //   goodToHaveSkills: {
                                //     items: [...columns.goodToHaveSkills.items,
                                //     { id: uuid(), content: newValue }
                                //     // idGenerator(commaSplitter(newValue))
                                //     ],
                                //   }
                                // });
                              }
                            }}

                          />
                        </div>
                      </Box>


                      <Box sx={{ width: "100%" }}>
                        <div style={{ display: "flex" }}>
                          <DragDropContext
                            onDragEnd={result => onDragEnd(result, columns, setColumns)}
                          >
                            {Object.entries(columns).map(([columnId, column], index) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",

                                  }}
                                  key={columnId}
                                >
                                  <h2>{column.name}</h2>
                                  <div style={{ width: '100%' }}>
                                    <Droppable droppableId={columnId} key={columnId}>
                                      {(provided, snapshot) => {
                                        return (
                                          <Box
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            sx={{
                                              background: snapshot.isDraggingOver
                                                ? "lightgray"
                                                : "white",
                                              padding: "1rem",
                                              // width: 250,
                                              // minHeight: 500
                                              boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.75)",
                                              borderRadius: "5px",
                                              overflow: "auto",
                                              margin: "0rem 0.2rem 2rem 0.2rem",
                                              height: "14rem",
                                              width: "20rem",

                                            }}
                                          >
                                            {column.items.map((item, index) => {
                                              return (
                                                <Draggable
                                                  key={item.id}
                                                  draggableId={item.id}
                                                  index={index}
                                                >
                                                  {(provided, snapshot) => {
                                                    return (
                                                      <Chip
                                                        onDelete={() => {
                                                          setColumns({
                                                            ...columns,
                                                            [columnId]: {
                                                              ...column,
                                                              items: column.items.filter(
                                                                (currentItem) => currentItem.id !== item.id
                                                              )
                                                            }
                                                          });

                                                        }}
                                                        label={item.content}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                          userSelect: "none",
                                                          // padding: 16,
                                                          margin: "0.2rem",
                                                          minHeight: "50px",
                                                          backgroundColor: snapshot.isDragging
                                                            ? "#FFC0CB"
                                                            : "#FBE4E4",
                                                          color: "brown",
                                                          ...provided.draggableProps.style
                                                        }}

                                                      >
                                                        {item.content}
                                                      </Chip>
                                                    );
                                                  }}
                                                </Draggable>
                                              );
                                            })}
                                            {provided.placeholder}
                                          </Box>
                                        );
                                      }}
                                    </Droppable>
                                  </div>
                                </div>
                              );
                            })}
                          </DragDropContext>
                        </div>

                      </Box>

                    </Box>
                  </>
                );
              case 2:
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "space-between",
                        width: "90%",
                        fontFamily: "Outfit",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <InputTextField
                          onChange={handleInputChange}
                          inputValueName="minSalary"
                          inputValues={inputValues.minSalary}
                          label="Min Salary (optional)"
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputTextField
                          onChange={handleInputChange}
                          inputValueName="maxSalary"
                          inputValues={inputValues.maxSalary}
                          label="Max Salary (optional)"
                        />
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        justifyContent: "space-between",
                        width: "90%",
                        fontFamily: "Outfit",
                        marginTop: "2rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <InputTextField
                          onChange={handleInputChange}
                          inputValueName="minRate"
                          inputValues={inputValues.minRate}
                          label="Min rate/h (optional)"
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <InputTextField
                          onChange={handleInputChange}
                          inputValueName="maxRate"
                          inputValues={inputValues.maxRate}
                          label="Max rate/h (optional)"
                        />
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        // gap: "2rem",
                        // justifyContent: "space-between",
                        width: "90%",
                        fontFamily: "Outfit",
                        marginTop: "2rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <InputTextField
                          onChange={handleInputChange}
                          inputValueName="hoursPerWeek"
                          inputValues={inputValues.hoursPerWeek}
                          label="Hours Per Week (optional)"
                        />
                      </div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        // gap: "2rem",
                        justifyContent: "space-between",
                        width: "90%",
                        fontFamily: "Outfit",
                        marginTop: "2rem",
                        marginBottom: "3rem",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <InputTextField
                          onChange={handleInputChange}
                          inputValueName="externalLink"
                          inputValues={inputValues.externalLink}
                          label="External 'Apply for Job' Link (optional)"
                        />
                      </div>
                    </Box>
                  </>
                );
              case 3:
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "90%",
                      }}
                    >
                      <Box className="flex__item" style={{ marginTop: "3rem" }}>
                        <Typography
                          variant="h5"
                          component="h2"
                          sx={{
                            fontWeight: "bold",
                          }}
                          className='title'
                        >
                          Job Description
                        </Typography>
                        {editData.description ? (
                          <DoneIcon
                            className='title'
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setEditData({
                                ...editData,
                                description: false,
                              });
                            }}
                          />
                        ) : (
                          <EditIcon
                            className='title'
                            sx={{ cursor: "pointer" }}
                            className='title'

                            onClick={() => {
                              console.log(editData);
                              setEditData({
                                ...editData,
                                description: !editData.description,
                              });
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "flex-start",
                          marginTop: "3rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h5" component="p"
                            className='title'
                          >
                            Job Title*
                          </Typography>
                          {editData.description ? (
                            <TextField
                              value={inputValues.title}
                              variant="standard"
                              onChange={(e) => {
                                setInputValues({
                                  ...inputValues,
                                  title: e.target.value,
                                });
                              }}
                            ></TextField>
                          ) : (
                            <Typography variant="h6" component="p">
                              {inputValues.title}
                            </Typography>
                          )}

                          <Typography variant="h6" component="p"
                            className='title'>
                            Job Type*
                          </Typography>
                          {editData.description ? (
                            <Autocomplete
                              options={types}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Job Type"
                                  variant="standard"
                                />
                              )}
                              onChange={(e, value) => {
                                setInputValues({
                                  ...inputValues,
                                  type: value,
                                });
                              }}
                            />
                          ) : (
                            <Typography variant="h6" component="p">
                              {inputValues.type}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h5" component="p"
                            className='title'
                          >
                            Job Location*
                          </Typography>
                          {editData.description ? (
                            <Autocomplete
                              options={locations}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{}}
                                  variant="standard"
                                  placeholder="eg. Hyderabad"
                                />
                              )}
                              value={inputValues.location}
                              onChange={(e, newValue) =>
                                setInputValues({
                                  ...inputValues,
                                  location: newValue,
                                })
                              }
                            />
                          ) : (
                            <Typography variant="h6" component="p">
                              {inputValues.location}
                            </Typography>
                          )}

                          <Typography variant="h5" component="p"
                            className='title'
                          >
                            Job Category*
                          </Typography>
                          {editData.description ? (
                            <Autocomplete
                              options={jobCategories}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Job Category"
                                  variant="standard"
                                />
                              )}
                              onChange={(e, value) => {
                                setInputValues({
                                  ...inputValues,
                                  category: value,
                                });
                              }}
                            ></Autocomplete>
                          ) : (
                            <Typography variant="h6" component="p">
                              {inputValues.category}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h5" component="p" className='title'>
                            Company Photo
                          </Typography>
                          <Box>
                            <img src={imageLink} alt="" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                          </Box>

                          {/* {console.log(imageLink)} */}
                          {/* <img src="https://workcrewstorage.blob.core.windows.net/company-images/63777e1b417fbd23f7953749.jpg"  alt="" srcset=""/> */}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "1rem",
                          marginTop: "3rem",
                        }}
                      >
                        <Typography variant="h5" component="p"
                          className='title'
                        >
                          Company Email*
                        </Typography>
                        {editData.description ? (
                          <TextField
                            value={inputValues.jobPostEmail}
                            variant="standard"
                            onChange={(e) => {
                              setInputValues({
                                ...inputValues,
                                jobPostEmail: e.target.value,
                              });
                            }}
                          ></TextField>
                        ) : (
                          <Typography variant="h6" component="p">
                            {inputValues.jobPostEmail}
                          </Typography>
                        )}

<Typography variant="h5" component="p"
                          className='title'
                        >
                          Required Experience*
                        </Typography>
                        {editData.description ? (
                          <TextField
                            value={inputValues.requiredExperience}
                            variant="standard"
                            onChange={(e)=> handleDecimalInputChange(e.target.value)}

                          ></TextField>
                        ) : (
                          <Typography variant="h6" component="p">
                            {inputValues.requiredExperience} {inputValues.requiredExperience > 1? 'years' : 'year'}
                          </Typography>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "1rem",
                            marginTop: "3rem",
                          }}
                        >
                          <Typography variant="h5" component="p"
                            className='title'
                          >
                            Job Description*
                          </Typography>
                          {editData.description ? (
                            <TextField
                              value={inputValues.description}
                              variant="standard"
                              multiline
                              // rows={5}
                              maxRows={25}
                              fullWidth
                              onChange={(e) => {
                                setInputValues({
                                  ...inputValues,
                                  description: e.target.value,
                                });
                              }}
                            ></TextField>
                          ) : (
                            <Typography sx={{ whiteSpace: 'break-spaces' }}
                              variant="h6" component="p"
                            >
                              {inputValues.description}
                            </Typography>
                          )}
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            gap: "2rem",
                            flexDirection: "column",
                            marginTop: "3rem",
                          }}
                        >
                          <div className="flex__item">
                            <Typography
                              variant="h5"
                              component="p"
                              sx={{
                                fontWeight: "bold",
                              }}
                              className='title'
                            >
                              Skills
                            </Typography>
                            {editData.skills ? (
                              <DoneIcon
                                className='title'
                                sx={{
                                  cursor: "pointer",
                                  fontSize: "2rem",
                                }}
                                onClick={() => {
                                  console.log(editData);
                                  setEditData({
                                    ...editData,
                                    skills: !editData.skills,
                                  });
                                }}
                              />
                            ) : (
                              <EditIcon
                                className='title'
                                sx={{ cursor: "pointer" }}
                                className='title'
                                onClick={() => {
                                  setEditData({
                                    ...editData,
                                    skills: !editData.skills,
                                  });
                                }}
                              />
                            )}
                          </div>
                          <Typography
                            variant="h6"
                            component="p"
                            sx={{
                              fontWeight: "bold",
                            }}
                            className='title'
                          >
                            Must Have Skills*
                          </Typography>
                          <div>
                            {editData.skills
                              ? inputValues.mustHaveSkills.map((skill) => (
                                <Chip
                                  sx={{
                                    margin: "0.2rem",
                                    color: "brown",
                                    backgroundColor: "#FBE4E4",
                                    padding: "0.2rem",
                                  }}
                                  label={skill}
                                  onDelete={() => {
                                    setInputValues({
                                      ...inputValues,
                                      mustHaveSkills:
                                        inputValues.mustHaveSkills.filter(
                                          (item) => item !== skill
                                        ),
                                    });
                                  }}
                                />
                              ))
                              : inputValues.mustHaveSkills.map((skill) => (
                                <Chip
                                  sx={{
                                    margin: "0.2rem",
                                    color: "brown",
                                    backgroundColor: "#FBE4E4",
                                    padding: "0.2rem",
                                  }}
                                  label={skill}
                                />
                              ))}
                          </div>

                          <Typography
                            variant="h6"
                            component="p"
                            sx={{
                              fontWeight: "bold",
                            }}
                            className='title'
                          >
                            Good to Have Skills*
                          </Typography>
                          <div>
                            {editData.skills
                              ? inputValues.goodToHaveSkills.map((skill) => (
                                <Chip
                                  sx={{
                                    margin: "0.2rem",
                                    color: "brown",
                                    backgroundColor: "#FBE4E4",
                                    padding: "0.2rem",
                                  }}
                                  label={skill}
                                  onDelete={() => {
                                    setInputValues({
                                      ...inputValues,
                                      goodToHaveSkills:
                                        inputValues.goodToHaveSkills.filter(
                                          (item) => item !== skill
                                        ),
                                    });
                                  }}
                                />
                              ))
                              : inputValues.goodToHaveSkills.map((skill) => (
                                <Chip
                                  sx={{
                                    margin: "0.2rem",
                                    color: "brown",
                                    backgroundColor: "#FBE4E4",
                                    padding: "0.2rem",
                                  }}
                                  label={skill}
                                />
                              ))}
                          </div>
                        </Box>

                        <div className="flex__item" style={{ marginTop: "3rem" }}>
                          <Typography
                            variant="h5"
                            component="p"
                            sx={{
                              fontWeight: "bold",
                              marginBottom: "2rem",
                            }}
                            className='title'
                          >
                            Salary Compensation
                          </Typography>
                          {editData.salaryCompensation ? (
                            <DoneIcon
                              className='title'
                              sx={{
                                cursor: "pointer",
                                fontSize: "2rem",
                              }}
                              onClick={() => {
                                setEditData({
                                  ...editData,
                                  salaryCompensation:
                                    !editData.salaryCompensation,
                                });
                              }}
                            />
                          ) : (
                            <EditIcon
                              className='title'
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setEditData({
                                  ...editData,
                                  salaryCompensation:
                                    !editData.salaryCompensation,
                                });
                              }}
                            />
                          )}
                        </div>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "80%",
                          }}
                        >
                          <Box
                            className="left"
                            sx={{
                              display: "flex",
                              gap: "4rem",
                              flexDirection: "column",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="h5"
                                component="p"
                                sx={{ marginBottom: "1rem" }}
                                className='title'
                              >
                                Min Salary(optional)
                              </Typography>
                              {editData.salaryCompensation ? (
                                <TextField
                                  value={inputValues.minSalary}
                                  variant="standard"
                                  onChange={(e) => {
                                    setInputValues({
                                      ...inputValues,
                                      minSalary: e.target.value,
                                    });
                                  }}
                                ></TextField>
                              ) : (
                                <>
                                  {" "}
                                  {inputValues.minSalary ? (
                                    <Typography variant="h6" component="p">
                                      {inputValues.minSalary}
                                    </Typography>
                                  ) : (
                                    <Typography variant="h6" component="p">
                                      Not Specified
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Box>
                            <Box>
                              <Typography
                                variant="h5"
                                component="p"
                                sx={{ marginBottom: "1rem" }}
                                className='title'
                              >
                                Min rate/h (optional)
                              </Typography>
                              {editData.salaryCompensation ? (
                                <TextField
                                  value={inputValues.minRate}
                                  variant="standard"
                                  onChange={(e) => {
                                    setInputValues({
                                      ...inputValues,
                                      minRate: e.target.value,
                                    });
                                  }}
                                ></TextField>
                              ) : (
                                <>
                                  {inputValues.minRate ? (
                                    <Typography variant="h6" component="p">
                                      {inputValues.minRate}
                                    </Typography>
                                  ) : (
                                    <Typography variant="h6" component="p">
                                      Not Specified
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Box>
                            <Box>
                              <Typography
                                variant="h5"
                                component="p"
                                sx={{ marginBottom: "1rem" }}
                                className='title'
                              >
                                Hours Per Week (optional)
                              </Typography>
                              {editData.salaryCompensation ? (
                                <TextField
                                  value={inputValues.hoursPerWeek}
                                  variant="standard"
                                  onChange={(e) => {
                                    setInputValues({
                                      ...inputValues,
                                      hoursPerWeek: e.target.value,
                                    });
                                  }}
                                ></TextField>
                              ) : (
                                <>
                                  {inputValues.hoursPerWeek ? (
                                    <Typography variant="h6" component="p">
                                      {inputValues.hoursPerWeek}
                                    </Typography>
                                  ) : (
                                    <Typography variant="h6" component="p">
                                      Not Specified
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Box>
                          </Box>
                          <Box
                            className="Right"
                            sx={{
                              display: "flex",
                              gap: "4rem",
                              flexDirection: "column",
                              marginBottom: "4rem",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="h5"
                                component="p"
                                sx={{ marginBottom: "1rem" }}
                                className='title'
                              >
                                Max Salary(optional)
                              </Typography>
                              {editData.salaryCompensation ? (
                                <TextField
                                  value={inputValues.maxSalary}
                                  variant="standard"
                                  onChange={(e) => {
                                    setInputValues({
                                      ...inputValues,
                                      maxSalary: e.target.value,
                                    });
                                  }}
                                ></TextField>
                              ) : (
                                <>
                                  {inputValues.maxSalary ? (
                                    <Typography variant="h6" component="p">
                                      {inputValues.maxSalary}
                                    </Typography>
                                  ) : (
                                    <Typography variant="h6" component="p">
                                      Not Specified
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Box>
                            <Box>
                              <Typography
                                variant="h5"
                                component="p"
                                sx={{ marginBottom: "1rem" }}
                                className='title'
                              >
                                Max rate/h (optional)
                              </Typography>
                              {editData.salaryCompensation ? (
                                <TextField
                                  value={inputValues.maxRate}
                                  variant="standard"
                                  onChange={(e) => {
                                    setInputValues({
                                      ...inputValues,
                                      maxRate: e.target.value,
                                    });
                                  }}
                                ></TextField>
                              ) : (
                                <>
                                  {inputValues.maxRate ? (
                                    <Typography variant="h6" component="p">
                                      {inputValues.maxRate}
                                    </Typography>
                                  ) : (
                                    <Typography variant="h6" component="p">
                                      Not Specified
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Box>
                            <Box>
                              <Typography
                                variant="h5"
                                component="p"
                                sx={{ marginBottom: "1rem" }}
                                className='title'
                              >
                                External “Apply for Job Link (optional)
                              </Typography>

                              {editData.salaryCompensation ? (
                                <TextField
                                  value={inputValues.externalLink}
                                  variant="standard"
                                  onChange={(e) => {
                                    setInputValues({
                                      ...inputValues,
                                      externalLink: e.target.value,
                                    });
                                  }}
                                ></TextField>
                              ) : (
                                <>
                                  {inputValues.externalLink ? (
                                    <Typography variant="h6" component="p">
                                      {inputValues.externalLink}
                                    </Typography>
                                  ) : (
                                    <Typography variant="h6" component="p">
                                      Not Specified
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                );

                return (
                  <>

                    <Box>
                      <Typography

                        sx={{
                          margin: "4rem",
                          fontSize: "2rem",
                          fontWeight: "bold",
                        }}
                      >
                        Job is Submitted
                      </Typography>
                      <Typography
                        sx={{
                          margin: "4rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        Your job is submitted and will be reviewed by our team.
                      </Typography>
                      <Typography
                        sx={{
                          margin: "4rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        You will be notified once your job is approved.
                      </Typography>
                      <Typography
                        sx={{
                          margin: "4rem",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        You can edit your job anytime by clicking on the edit
                        button on the job details page.
                      </Typography>
                      <Typography
                        sx={{
                          margin: "4rem",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Navigating to dashboard in 5 seconds
                      </Typography>


                    </Box>
                    {/* {setTimeout(() => {
                    navigate("/recruiterdashboard")
                  }, 5000)} */}
                  </>
                );
            }
          })()}


          {renderSwitchButtons(activeStep)}

        </Box>
        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Cant Make a Job Post Please Try Again</Alert>
        </Snackbar>
        <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Job is Posted</Alert>
        </Snackbar>
      </div>
    </Box>
  );
}

export default Postjob;
