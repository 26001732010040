
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react";
import { Box, Container, Grid, StepLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import "./style.css";
import { styled } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import InputTextField from "components/PostJob/InputTextField";
import EditIcon from "@mui/icons-material/Edit";
import axois from "axios";
import { useAuth } from 'utils/auth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'
import Navbar from "components/Navbar"
import { useQuery } from '@tanstack/react-query';
import _ from "lodash"
import {cities} from 'data/cities';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Job() {
    const navigate = useNavigate();
    const auth = useAuth()
    const token = auth.token
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const { id } = useParams()

    const fetchJob = async () => {
        return axios.get(`${process.env.REACT_APP_API__URL}/api/jobs/${id}`, config)
    }


    const { isLoading, data, isError, error, status } = useQuery(['job'], fetchJob)
    const [baseValues, setBaseValues] = useState({})
    const [inputValues, setInputValues] = useState({
        title: "",
        location: "",
        type: "",
        category: "",
        description: "",
        requiredExperience: "",
        goodToHaveSkills: [],
        mustHaveSkills: [],
        minSalary: "",
        MaxSalary: "",
        minRate: "",
        maxRate: "",
        hoursPerWeek: "",
        externalLink: "",
    });
    useEffect(() => {
        // console.log(data.data.result)
        try {
            console.log(data.data.result)
            setInputValues({ ...data.data.result })
            setBaseValues({ ...data.data.result })
        }
        catch (err) {
            console.log(err)
        }

    }, [data])
    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_API__URL}/api/jobs/${id}`, config)
    //         .then((res) => {
    //             setJobDetail(res.data.result)
    //             console.log("SUCCESS")
    //             console.log(jobDetail)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, [])

    const patchJob = async () => {
        if(_.isEqual(baseValues, inputValues)) return
        const result = await axios.patch(`${process.env.REACT_APP_API__URL}/api/jobs/${id}`, inputValues, config)
        if (result.status === 200) {
            setSuccessOpen(true)
            setBaseValues({ ...inputValues })
        }
        else {
            setErrorOpen(true)
        }
    }
    const DecimalChecker = value => {
        return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '')
    }
    const handleDecimalInputChange = (value) => {
        const DecimalValue = DecimalChecker(value)
        console.log(DecimalValue)
        // check if it is blank or not

        console.log("adding change")
        setInputValues({
            ...inputValues,
            requiredExperience: DecimalValue,
        })

    }



    const [editData, setEditData] = useState({
        description: false,
        skills: false,
        salaryCompensation: false,
    });
    const locations = cities;
    const education = [
        "Secondary Education",
        "Higher Secondary Education",
        "Graduation",
        "Post Graduation",
        "Doctorate",
    ];
    const types = ["Full Time", "Part Time", "Contract", "Internship"];
    const jobCategories = [
        "Blockchain",
        "Business Intelligence",
        "Cloud Computing",
        "Data Science",
        "DevOps",
        "Full Stack",
        "Game Development",
        "Machine Learning",
        "Mobile Development",
        "Product Management",
        "Project Management",
        "Quality Assurance",
        "UI/UX Design",
        "Web Development",
        "Other",
    ];
    const modeOfWork = ["Office", "Hybrid", "Work From Home"];

    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);


    const onSuccess = () => {
        setSuccessOpen(true);
    };
    const onError = () => {
        setErrorOpen(true);
    };
    console.log(inputValues)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
        setErrorOpen(false);
    };



    if (isLoading) {
        return (<>
            <Box sx={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}>
                <h2>Loading</h2>
            </Box>
        </>)
    }
    if (isError) {
        return <h2>{error.message}</h2>
    }


    if (status === "success") {
        console.log(data.data.result)

        return (
            <>
                <div style={{ backgroundColor: "#F9F9F9" }}>
                    {/* Navbar */}
                    <Navbar />
                    <Box
                        sx={{
                            backgroundColor: "white",
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            alignItems: "center",
                            flexDirection: "column",
                            marginBottom: "3rem",
                            borderRadius: 5,
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                            maxWidth: "lg",
                            marginBottom: "4rem",
                        }}
                    >

                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    width: "90%",
                                    marginTop: "3rem",
                                }}
                            >
                                <Box className="flex__item" style={{ marginTop: "3rem" }}>
                                    <Typography
                                        variant="h5"
                                        component="h2"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                        className='title'
                                    >
                                        Job Description
                                    </Typography>
                                    {editData.description ? (
                                        <DoneIcon
                                            className='title'
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                patchJob()
                                                setEditData({
                                                    ...editData,
                                                    description: false,
                                                });
                                            }}
                                        />
                                    ) : (
                                        <EditIcon
                                            className='title'
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                console.log(editData);
                                                setEditData({
                                                    ...editData,
                                                    description: !editData.description,
                                                });
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "flex-start",
                                        marginTop: "3rem",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography variant="h5" component="p" className='title'>
                                            Job Title*
                                        </Typography>
                                        {editData.description ? (
                                            <TextField
                                                value={inputValues.title}
                                                variant="standard"
                                                onChange={(e) => {
                                                    setInputValues({
                                                        ...inputValues,
                                                        title: e.target.value,
                                                    });
                                                }}
                                            ></TextField>
                                        ) : (
                                            <Typography variant="h6" component="p">
                                                {inputValues.title}
                                            </Typography>
                                        )}

                                        <Typography variant="h5" component="p" className='title'>
                                            Job Type*
                                        </Typography>
                                        {editData.description ? (
                                            <Autocomplete
                                                options={types}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Job Type"
                                                        variant="standard"
                                                    />
                                                )}
                                                value={inputValues.type}

                                                onChange={(e, value) => {
                                                    setInputValues({
                                                        ...inputValues,
                                                        type: value,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="h6" component="p">
                                                {inputValues.type}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography variant="h5" component="p" className='title'>
                                            Job Location*
                                        </Typography>
                                        {editData.description ? (
                                            <Autocomplete
                                                options={locations}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{}}
                                                        variant="standard"
                                                        placeholder="eg. Hyderabad"
                                                    />
                                                )}
                                                value={inputValues.location}
                                                onChange={(e, newValue) =>
                                                    setInputValues({
                                                        ...inputValues,
                                                        location: newValue,
                                                    })
                                                }
                                            />
                                        ) : (
                                            <Typography variant="h6" component="p">
                                                {inputValues.location}
                                            </Typography>
                                        )}

                                        <Typography variant="h5" component="p" className='title'>
                                            Job Category*
                                        </Typography>
                                        {editData.description ? (
                                            <Autocomplete
                                                options={jobCategories}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Job Category"
                                                        variant="standard"
                                                    />
                                                )}
                                                value={inputValues.category}
                                                onChange={(e, value) => {
                                                    setInputValues({
                                                        ...inputValues,
                                                        category: value,
                                                    });
                                                }}
                                            ></Autocomplete>
                                        ) : (
                                            <Typography variant="h6" component="p">
                                                {inputValues.category}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "1rem",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography variant="h5" component="p" className='title'>
                                            Company Photo
                                        </Typography>
                                        <Box>
                                            <img src={inputValues?.company?.photo} alt="" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "1rem",
                                        marginTop: "3rem",
                                    }}
                                >
                                    <Typography variant="h5" component="p" className='title'>
                                        Company Email*
                                    </Typography>
                                    {editData.description ? (
                                        <TextField
                                            value={inputValues?.jobPostEmail}
                                            variant="standard"
                                            onChange={(e) => {
                                                setInputValues({
                                                    ...inputValues,
                                                    jobPostEmail: e.target.value,
                                                });
                                            }}
                                        ></TextField>
                                    ) : (
                                        <Typography variant="h6" component="p">
                                            {inputValues?.jobPostEmail}
                                        </Typography>
                                    )}


                                    <Typography variant="h5" component="p"
                                        className='title'
                                    >
                                        Required Experience*
                                    </Typography>
                                    {editData.description ? (
                                        <TextField
                                            value={inputValues.requiredExperience}
                                            variant="standard"
                                            onChange={(e) => handleDecimalInputChange(e.target.value)}

                                        ></TextField>
                                    ) : (
                                        <Typography variant="h6" component="p">
                                            {inputValues.requiredExperience} {inputValues.requiredExperience > 1 ? 'years' : 'year'}
                                        </Typography>
                                    )}


                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            gap: "1rem",
                                            marginTop: "3rem",
                                        }}
                                    >
                                        <Typography variant="h5" component="p" className='title'>
                                            Job Description*
                                        </Typography>
                                        {editData.description ? (
                                            <TextField
                                                value={inputValues.description}
                                                variant="standard"
                                                multiline
                                                rows={5}
                                                fullWidth
                                                onChange={(e) => {
                                                    setInputValues({
                                                        ...inputValues,
                                                        description: e.target.value,
                                                    });
                                                }}
                                            ></TextField>
                                        ) : (
                                            <Typography variant="h6" component="p">
                                                {inputValues.description}
                                            </Typography>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "2rem",
                                            flexDirection: "column",
                                            marginTop: "3rem",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="flex__item">
                                            <Typography
                                                variant="h5"
                                                component="p"
                                                sx={{
                                                    fontWeight: "bold",

                                                }}
                                                className='title'
                                            >
                                                Skills
                                            </Typography>
                                            {editData.skills ? (
                                                <DoneIcon
                                                    className='title'
                                                    sx={{
                                                        cursor: "pointer",
                                                        fontSize: "2rem",
                                                    }}
                                                    onClick={() => {
                                                        patchJob()
                                                        console.log(editData);
                                                        setEditData({
                                                            ...editData,
                                                            skills: !editData.skills,
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                <EditIcon
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setEditData({
                                                            ...editData,
                                                            skills: !editData.skills,
                                                        });
                                                    }}
                                                    className='title'
                                                />
                                            )}
                                        </div>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                            className='title'
                                        >
                                            Must Have Skills*
                                        </Typography>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                // backgroundColor: '#FBE4E4',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '70%',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {editData.skills
                                                    ? inputValues.mustHaveSkills.map((skill) => (
                                                        <Chip
                                                            sx={{
                                                                margin: "0.2rem",
                                                                color: "brown",
                                                                backgroundColor: "#FBE4E4",
                                                                padding: "0.2rem",
                                                            }}
                                                            label={skill}
                                                            onDelete={() => {
                                                                setInputValues({
                                                                    ...inputValues,
                                                                    mustHaveSkills:
                                                                        inputValues.mustHaveSkills.filter(
                                                                            (item) => item !== skill
                                                                        ),
                                                                });
                                                            }}
                                                        />
                                                    ))
                                                    : inputValues.mustHaveSkills.map((skill) => (
                                                        <Chip
                                                            sx={{
                                                                margin: "0.2rem",
                                                                color: "brown",
                                                                backgroundColor: "#FBE4E4",
                                                                padding: "0.2rem",
                                                            }}
                                                            label={skill}
                                                        />
                                                    ))}

                                            </div>
                                            <div>

                                            </div>

                                            {editData.skills ? (<>
                                                <TextField label="Must Have Skills" variant="outlined"
                                                    onKeyDown={
                                                        (e) => {
                                                            if (e.key === 'Enter') {
                                                                setInputValues({
                                                                    ...inputValues,
                                                                    mustHaveSkills: [...inputValues.mustHaveSkills, e.target.value]
                                                                })
                                                                e.target.value = ''
                                                            }
                                                        }}

                                                />
                                            </>) : (<>

                                            </>)}
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="p"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                            className='title'
                                        >
                                            Good to Have Skills*
                                        </Typography>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                // backgroundColor: '#FBE4E4',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '70%',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {editData.skills
                                                    ? inputValues.goodToHaveSkills.map((skill) => (
                                                        <Chip
                                                            sx={{
                                                                margin: "0.2rem",
                                                                color: "brown",
                                                                backgroundColor: "#FBE4E4",
                                                                padding: "0.2rem",
                                                            }}
                                                            label={skill}
                                                            onDelete={() => {
                                                                setInputValues({
                                                                    ...inputValues,
                                                                    goodToHaveSkills:
                                                                        inputValues.goodToHaveSkills.filter(
                                                                            (item) => item !== skill
                                                                        ),
                                                                });
                                                            }}
                                                        />
                                                    ))
                                                    : inputValues.goodToHaveSkills.map((skill) => (
                                                        <Chip
                                                            sx={{
                                                                margin: "0.2rem",
                                                                color: "brown",
                                                                backgroundColor: "#FBE4E4",
                                                                padding: "0.2rem",
                                                            }}
                                                            label={skill}
                                                        />
                                                    ))}
                                            </div>
                                            <div>
                                                {editData.skills ? (<>
                                                    <TextField label="Good to Have Skills" variant="outlined"
                                                        onKeyDown={
                                                            (e) => {
                                                                if (e.key === 'Enter') {
                                                                    setInputValues({
                                                                        ...inputValues,
                                                                        goodToHaveSkills: [...inputValues.goodToHaveSkills, e.target.value]
                                                                    })
                                                                    e.target.value = ''
                                                                }
                                                            }}

                                                    />
                                                </>) : (<>

                                                </>)}
                                            </div>
                                        </div>


                                    </Box>

                                    <div className="flex__item" style={{ marginTop: "3rem" }}>
                                        <Typography
                                            variant="h5"
                                            component="p"
                                            sx={{
                                                fontWeight: "bold",
                                                marginBottom: "2rem",
                                            }}
                                            className='title'
                                        >
                                            Salary Compensation
                                        </Typography>
                                        {editData.salaryCompensation ? (
                                            <DoneIcon
                                                className='title'
                                                sx={{
                                                    cursor: "pointer",
                                                    fontSize: "2rem",
                                                }}
                                                onClick={() => {
                                                    patchJob()

                                                    setEditData({
                                                        ...editData,
                                                        salaryCompensation:
                                                            !editData.salaryCompensation,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <EditIcon
                                                className='title'
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setEditData({
                                                        ...editData,
                                                        salaryCompensation:
                                                            !editData.salaryCompensation,
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "80%",
                                        }}
                                    >
                                        <Box
                                            className="left"
                                            sx={{
                                                display: "flex",
                                                gap: "4rem",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    sx={{ marginBottom: "1rem" }}
                                                    className='title'
                                                >
                                                    Min Salary(optional)
                                                </Typography>
                                                {editData.salaryCompensation ? (
                                                    <TextField
                                                        value={inputValues.minSalary}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            setInputValues({
                                                                ...inputValues,
                                                                minSalary: e.target.value,
                                                            });
                                                        }}
                                                    ></TextField>
                                                ) : (
                                                    <>
                                                        {" "}
                                                        {inputValues.minSalary ? (
                                                            <Typography variant="h6" component="p">
                                                                {inputValues.minSalary}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="h6" component="p">
                                                                Not Specified
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    sx={{ marginBottom: "1rem" }}
                                                    className='title'
                                                >
                                                    Min rate/h (optional)
                                                </Typography>
                                                {editData.salaryCompensation ? (
                                                    <TextField
                                                        value={inputValues.minRate}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            setInputValues({
                                                                ...inputValues,
                                                                minRate: e.target.value,
                                                            });
                                                        }}
                                                    ></TextField>
                                                ) : (
                                                    <>
                                                        {inputValues.minRate ? (
                                                            <Typography variant="h6" component="p">
                                                                {inputValues.minRate}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="h6" component="p">
                                                                Not Specified
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    sx={{ marginBottom: "1rem" }}
                                                    className='title'
                                                >
                                                    Hours Per Week (optional)
                                                </Typography>
                                                {editData.salaryCompensation ? (
                                                    <TextField
                                                        value={inputValues.hoursPerWeek}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            setInputValues({
                                                                ...inputValues,
                                                                hoursPerWeek: e.target.value,
                                                            });
                                                        }}
                                                    ></TextField>
                                                ) : (
                                                    <>
                                                        {inputValues.hoursPerWeek ? (
                                                            <Typography variant="h6" component="p">
                                                                {inputValues.hoursPerWeek}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="h6" component="p">
                                                                Not Specified
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box
                                            className="Right"
                                            sx={{
                                                display: "flex",
                                                gap: "4rem",
                                                flexDirection: "column",
                                                marginBottom: "4rem",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    sx={{ marginBottom: "1rem" }}
                                                    className='title'
                                                >
                                                    Max Salary(optional)
                                                </Typography>
                                                {editData.salaryCompensation ? (
                                                    <TextField
                                                        value={inputValues.maxSalary}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            setInputValues({
                                                                ...inputValues,
                                                                maxSalary: e.target.value,
                                                            });
                                                        }}
                                                    ></TextField>
                                                ) : (
                                                    <>
                                                        {inputValues.maxSalary ? (
                                                            <Typography variant="h6" component="p">
                                                                {inputValues.maxSalary}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="h6" component="p">
                                                                Not Specified
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    sx={{ marginBottom: "1rem" }}
                                                    className='title'
                                                >
                                                    Max rate/h (optional)
                                                </Typography>
                                                {editData.salaryCompensation ? (
                                                    <TextField
                                                        value={inputValues.maxRate}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            setInputValues({
                                                                ...inputValues,
                                                                maxRate: e.target.value,
                                                            });
                                                        }}
                                                    ></TextField>
                                                ) : (
                                                    <>
                                                        {inputValues.maxRate ? (
                                                            <Typography variant="h6" component="p">
                                                                {inputValues.maxRate}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="h6" component="p">
                                                                Not Specified
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    component="p"
                                                    sx={{ marginBottom: "1rem" }}
                                                    className='title'
                                                >
                                                    External “Apply for Job Link (optional)
                                                </Typography>

                                                {editData.salaryCompensation ? (
                                                    <TextField
                                                        value={inputValues.externalLink}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            setInputValues({
                                                                ...inputValues,
                                                                externalLink: e.target.value,
                                                            });
                                                        }}
                                                    ></TextField>
                                                ) : (
                                                    <>
                                                        {inputValues.externalLink ? (
                                                            <Typography variant="h6" component="p">
                                                                {inputValues.externalLink}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="h6" component="p">
                                                                Not Specified
                                                            </Typography>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                            <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Update Failed! Please Try Again</Alert>
                        </Snackbar>
                        <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                            <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Job is Edited!</Alert>
                        </Snackbar>
                    </Box>
                </div>
            </>
        )
    }
}

export default Job




//     < Autocomplete
// // sx={{ mx: '2rem' }}
// sx = {{ width: 300 }}
// // key={chipData.length}
// options = { []}
// getOptionLabel = {(option) => option}
// renderInput = {(params) => (
//     <TextField
//         {...params}
//         sx={{ border: "2!important" }}
//         placeholder="Must Have Skills"
//     />
// )}
// value = { defaultSkill.mustHaveSkills }
// freeSolo
// onChange = {(e, value) => {
//     if (value) {
//         console.log(value)
//         setInputValues({
//             ...inputValues,
//             mustHaveSkills: [...inputValues.mustHaveSkills, value]
//         })
//         setDefaultSkill({
//             ...defaultSkill,
//             mustHaveSkills: ''
//         })
//     }
// }}
// />