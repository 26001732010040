import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import NewJobsList from './NewJobsList';
// import {fetchCandidateData} from 'components/Fetchers/candidate'
// import {publicJobPosts,getUnappliedJobs} from 'components/Fetchers/candidate'
import {
  fetchCandidateData,
  publicJobPosts,
  getUnappliedJobs,
} from 'components/Fetchers';
import { useAuth } from 'utils/auth';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function NewAccordion(props) {
  const { setJobs } = props;
  const navigate = useNavigate();
  const auth = useAuth();
  const [candidateData, setCandidateData] = useState([]);
  const [showJobs, setShowJobs] = useState([]);

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorOpenPublic, setErrorOpenPublic] = useState(false);
  const [errorIncompleteProfile, setErrorIncompleteProfile] = useState(false);
  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };
  const onErrorPublic = () => {
    // setErrorOpenPublic(true);
    navigate('/signup?alertCode=1');
  };
  const onErrorImcompleteProfile = () => {
    setErrorIncompleteProfile(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorIncompleteProfile(false);
    setErrorOpenPublic(false);
    setSuccessOpen(false);
    setErrorOpen(false);
  };

  // useEffect(() => {
  //     publicJobPosts().then((res) => {
  //         setShowJobs(res)
  //     })
  // }, [])

  const handleApplyAfterEffect = (id, bool) => {
    if (bool) {
      const newJobs = showJobs.filter((job) => job._id !== id);
      console.info(newJobs.length);
      setShowJobs(newJobs);
      onSuccess();
    } else {
      console.info('not deleted', showJobs.length);
      if (auth.userType === 'candidate') {
        onError();
      } else {
        onErrorPublic();
      }
    }
  };

  // const { data: candidateData } = useQuery(
  //   "fetchingCandidateData",
  //   () => fetchCandidateData(auth.userId),
  //   {
  //     enabled: auth.userType === "candidate",
  //     onSuccess: (res) => {
  //       console.log("unapplied jobs");
  //       setCandidateSkills(res.data.result.skills);
  //     },
  //   }
  // );
  // const { data: unappliedJobs } = useQuery(
  //   "fetchingUnappliedJobs",
  //   () => getUnappliedJobs(),
  //   {
  //     enabled: !!candidateData,
  //     onSuccess: (res) => {
  //       setShowJobs(res);
  //       setJobs(res);
  //     },
  //   }
  // );

  // const { data: publicJobs } = useQuery(
  //   "fetchingPublicJobs",
  //   () => publicJobPosts(),
  //   {
  //     enabled: auth.userType !== "candidate",
  //     onSuccess: (res) => {
  //       console.log("public jobs");
  //       setShowJobs(res);
  //       setJobs(res);
  //     },
  //   }
  // );

  useEffect(() => {
    if (auth.userType === 'candidate') {
      fetchCandidateData(auth.userId).then((res) => {
        setCandidateData(res.data.result);
      });
      getUnappliedJobs().then((res) => {
        setShowJobs(res);
        setJobs(res);
      });
    } else {
      publicJobPosts().then((res) => {
        setShowJobs(res);
        setJobs(res);
      });
    }
  }, []);

  //get only latest 5 jobs
  const reverseJobs = showJobs.reverse();
  // select 5 random jobs out of the list
  // shuffleArray;
  // const latestJobs = shuffleArray(reverseJobs).slice(0, 5);
  // .sort(() => Math.random() - 0.5)
  const latestJobs = reverseJobs.sort(() => Math.random() - 0.5).slice(0, 5);

  // const latestJobs = reverseJobs.slice(0, 5)

  // const latestJobs = reverseJobs.slice(0, 5)
  // const latestJobs = randomJobGenerator(reverseJobs)

  return (
    <div>
      <div className="flew flex-col justify-between m-auto">
        {/* <div className="px-2 xs:px-8 sm:px-16 md:px-16 lg:px-32 xl:px-48"> */}
        <div className="px-4 xs:px-8 sm:px-16 md:px-16 lg:px-32 xl:px-[138px]">
          {/* <div className="px-2 xs:px-8 sm:px-16 md:px-[8.5rem] lg:px-64 xl:px-[300px]"> */}
          {latestJobs?.map((job, index) => {
            return (
              <div key={index}>
                <NewJobsList
                  job={job}
                  handleApplyAfterEffect={(e, bool) =>
                    handleApplyAfterEffect(e, bool)
                  }
                  candidateSkills={candidateData.skills}
                  candidateData={candidateData}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Snackbar
        open={errorOpenPublic}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">
          Please Sign Up
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">
          Cant Apply to the job, Please Try Again
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">
          Job Applied!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default NewAccordion;
