import { Box } from "@mui/material";
import * as html2pdf from "html2pdf.js";
import React, { useEffect, useState, useRef } from "react";
import BasicInfo from "components/CandidateProfile/BasicInfo";
// import EducationP from "components/CandidateProfile/EducationP";
import LanguagesProjects from "components/CandidateProfile/LanguagesProjects";
// import ProjectWork from "components/CandidateProfile/ProjectWork";
import "components/CandidateProfile/style.css";
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { useAuth } from "utils/auth";
import LeftSection from "components/CandidateProfile/LeftSection";
import RightSection from "components/CandidateProfile/RightSection";
import {
  useParams,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Skills from "components/CandidateProfile/Skills";
import Navbar from "components/Navbar";
import {
  fetchCandidateData,
  patchCandidateDone,
  patchCandidateData,
  rejectJobApplicant,
  shortListJobApplicant,
  uploadResume,
} from "components/Fetchers";
import { Snackbar, MuiAlert } from "components/MUI";
import DevProfileDialog from "components/CandidateProfile/DevProfileDialog";
import { useReactToPrint } from "react-to-print";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import Helmet from "components/MetaInformation/Helmet";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
var FormData = require("form-data");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyDoc = () => (
  <Document>
    <Page bookmark="Harry Potter and the Philosopher's Stone">
      <Text bookmark={{ title: "Chapter 1: The Boy Who Lived", fit: true }}>
        nice
      </Text>
    </Page>
  </Document>
);

function Candidateprofile() {
  const navigate = useNavigate();
  const auth = useAuth();
  var { id } = useParams();
  // console.log("PARAMID")
  // console.log(id)
  if (id) {
    var candidateId = id;
  } else {
    var candidateId = auth.userId;
  }
  const [jobApplicantId, setJobApplicantId] = useState(false);
  const location = useLocation();
  const printRef = useRef();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const handlePrint = useReactToPrint({
  //   // ...,
  //   print: async (printIframe: HTMLIframeElement) => {
  //     // Do whatever you want here, including asynchronous work
  //     await generateAndSavePDF(printIframe);
  //   },
  // });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const param = query.get("jobapplicantid");
    // console.log(param)
    if (param) {
      setJobApplicantId(param);
    }
  }, []);

  const [candidate, setCandidate] = useState(false);
  const userType = auth.userType;
  useEffect(() => {
    if (userType == "candidate") {
      setCandidate(true);
    }
  }, [userType]);

  const isCandidateProfileComplete = (candidateData) => {
    const result = candidateData;
    if (
      candidateDataChecker(result) &&
      contactInfoChecker(result.contactInfo) &&
      result.isProfileComplete == false
    ) {
      return true;
    }
    // }
    // else return false
  };

  const contactInfoChecker = (contactInfo) => {
    if (
      contactInfo.address &&
      contactInfo.phone &&
      contactInfo.exp &&
      contactInfo.languages.length > 0
    ) {
      return true;
    } else return false;
  };

  const candidateDataChecker = (candidateData) => {
    if (
      candidateData.jobPosition &&
      candidateData.education.length > 0 &&
      candidateData.personalProject.length > 0 &&
      candidateData.latestProjectUploaded.length > 0 &&
      candidateData.skills.length > 0 &&
      candidateData.workExperience.length > 0
    ) {
      return true;
    } else return false;
  };

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [resumeSucessOpen, setResumeSucessOpen] = useState(false);
  const [resumeErrorOpen, setResumeErrorOpen] = useState(false);
  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };
  const onResumeSuccess = () => {
    setResumeSucessOpen(true);
  };
  const onResumeError = () => {
    setResumeErrorOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
    setResumeErrorOpen(false);
    setResumeSucessOpen(false);
  };

  useEffect(() => {
    // console.log('auth.candidateData')
    // console.log(auth.candidateDone)
    if (auth.candidateDone == "false" || auth.candidateDone == false) {
      onError();
    }
    // console.log(auth.candidateDone)
  }, [auth.candidateDone]);

  const hiddenFileInput = React.useRef(null);
  const handleResumeClick = (event) => {
    hiddenFileInput.current.click();
  };
  const uploadFileHandler = async (e) => {
    console.log("uploading resume");
    var data = new FormData();
    data.append("resume", e.target.files[0]);
    const result = await uploadResume(data);
    if (result.status == 201) {
      onResumeSuccess();
      resumeDataToCandidateData(result.data.result);
    } else {
      onResumeError();
    }
    console.log(result);
  };

  const [resumeData, setResumeData] = useState({});

  const [candidateData, setCandidateData] = useState({});

  const { isLoading, data, isError, error, status } = useQuery(['candidateData'], () => fetchCandidateData(candidateId), {
    onSuccess: (data) => {
      // console.log(data)
      setCandidateData(data.data.result);
    },
  });

  const resumeDataToCandidateData = (resumeData) => {
    // console.log(candidateData)
    console.log(resumeData);
    // console.log(myData)
    if (resumeData) {
      const cd = {
        name: candidateData?.name,
        email:
          resumeData?.["Email Address"]?.length > 0
            ? resumeData?.["Email Address"].slice(-1)[0]
            : candidateData?.email,
        jobPosition:
          resumeData?.["Designation"]?.length > 0
            ? resumeData?.["Designation"].slice(-1)[0]
            : candidateData?.jobPosition,
        contactInfo: {
          address:
            resumeData?.["Location"]?.length > 0
              ? resumeData?.["Location"].slice(-1)[0]
              : candidateData?.contactInfo?.address,
          phone:
            resumeData?.PHONE?.length > 0
              ? resumeData?.PHONE
              : candidateData?.contactInfo?.phone,
          exp:
            resumeData?.EXPERIENCE?.length > 0
              ? resumeData?.EXPERIENCE
              : candidateData?.contactInfo?.exp,
          languages: candidateData?.contactInfo?.languages,
        },
        latestProjectUploaded: candidateData?.latestProjectUploaded,
        workExperience: candidateData?.workExperience,
        personalProject: candidateData?.personalProject,
        education:
          resumeData?.EDUCATION?.length > 0
            ? resumeData?.EDUCATION
            : candidateData?.education,
        skills:
          resumeData?.["Skills"]?.length > 0
            ? [...candidateData?.skills, ...resumeData?.["Skills"]]
            : candidateData?.skills,
      };
      console.log("CD IS ");
      console.log(cd);
      console.log("skills is ", cd.skills);

      setCandidateData(cd);
      patchCandidateData(candidateId, cd)
        .then(console.log("PATCHED CANDIDATE DATA"))
        .catch((e) => console.log(e));
      console.log(cd);
    }
  };

  // useEffect(() => {
  //   resumeDataToCandidateData()
  // }, [resumeData])

  useEffect(() => {
    if (data) {
      // console.log("CANDIDATE PROFILE IS COMPLETE OR NOT")
      const candidateData = data.data.result;
      // console.log(candidateData)
      // isCandidateProfileComplete(candidateData))
      const result = isCandidateProfileComplete(candidateData);
      // console.log(result)
      if (result) {
        console.log(result);
        patchCandidateDone(candidateId, candidateData)
          .then(onSuccess())
          .catch((e) => console.log(e));
        auth.candidateProfileComplete(true);
      }
    }
  }, [data]);
  if (isLoading) {
    return (
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 800,
        }}
      >
        Loading...
      </h2>
    );
  }
  if (isError) {
    return <h2>{error.message}</h2>;
  }

  const print = () => {
    console.log("printing");
    const reff = document.getElementById("printcomponent");
    let opt = {
      margin: 0.5,
      filename: `${candidateData?.name}_resume.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "A2", orientation: "portrait" },
    };
    html2pdf(reff, opt);
  };

  const handleReject = async (jobApplicantId) => {
    const response = await rejectJobApplicant(jobApplicantId);
    console.log(response);
    if (response.message === "Job Application Updated") {
      navigate("/recruiter/dashboard");
    }
  };
  const handleShortlist = async (jobApplicantId) => {
    const response = await shortListJobApplicant(jobApplicantId);
    console.log(response);
    if (response.message === "Job Application Updated") {
      navigate("/recruiter/dashboard");
    }
  };
  const downloadCandidateResume = () => {
    const newWindow = window.open(
      candidateData.resume,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
    // const resumeLink = candidateData.resume;
    // fetch(resumeLink).then((response) => {
    //   response.blob().then((blob) => {
    //     // Creating new object of PDF file
    //     const fileURL = window.URL.createObjectURL(blob);
    //     // Setting various property values
    //     let alink = document.createElement("a");
    //     alink.href = fileURL;
    //     alink.download = `${candidateData.name}_${candidateData._id.substring(
    //       0,
    //       3
    //     )}_resume.pdf`;
    //     alink.click();
    //   });
    // });
  };

  // return candidateData

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });

  if (status === "success") {
    // console.log(data.data.result);
    return (
      <Box className="candidateprofile">
        {/* <Helmet /> */}
        <Navbar />

        <Box
          sx={{
            // marginLeft:"3rem",

            // border:1,
            padding: {
              xs: "0rem 0rem",
              sm: "0rem 0.5rem",
              md: "0rem 1rem",
              lg: "0rem 1.3rem",
              xl: "0rem 1.6rem",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "xl",
              margin: "auto",
              // mx: "auto",
              // border: 1,
              // paddingLeft: "2rem",
              justifyContent: "center",
            }}
          >
            {/* Buttons */}
            {candidate ? (
              <>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <DevProfileDialog
                    title="Enter your Developer Profiles"
                    candidateId={candidateId}
                    candidateData={candidateData}
                  ></DevProfileDialog>

                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => uploadFileHandler(e)}
                    style={{ display: "none" }}
                  />
                  <button
                    style={{ margin: "0rem 2rem 2rem 0rem" }}
                    className="buttons__downloadcv"
                    onClick={handleResumeClick}
                  >
                    Upload CV
                  </button>
                  {/* <button onClick={resumeDataToCandidateData}></button> */}
                </Box>
              </>
            ) : (
              <>
                {" "}
                <div className="candidateprofile__buttons">
                  <p
                    className="buttons__shortlist"
                    onClick={() => handleShortlist(jobApplicantId)}
                  >
                    Shortlist
                  </p>
                  <p
                    className="buttons__reject"
                    onClick={() => handleReject(jobApplicantId)}
                  >
                    Reject
                  </p>
                  <button className="buttons__downloadcv" onClick={print}>
                    Download CV
                  </button>
                  {candidateData?.resume ? (
                    <>
                      <button
                        className="buttons__downloadcv"
                        onClick={downloadCandidateResume}
                      >
                        Download Original CV
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}

            {/* Info and Skills */}
            {/* //done */}

            {/* <button className="buttons__downloadcv" onClick={print}>
              Download CV
            </button> */}

            {/* <button className="buttons__downloadcv" onClick={handlePrint}>
              Download React print CV
            </button> */}
            <Box
              ref={componentRef}
              id="printcomponent"
              sx={{
                maxWidth: "xl",
                margin: "auto",
              }}
            >
              {/* <PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download now!'
      }
    </PDFDownloadLink> */}
              <BasicInfo
                candidateData={candidateData}
                candidateId={candidateId}
                candidate={candidate}
                print={print}
              />

              {/* Languages and Project */}
              {/* <LanguagesProjects /> */}

              <Skills
                candidate={candidate}
                candidateData={candidateData}
                candidateId={candidateId}
              />

              <Box sx={{ display: "flex", marginBottom: "2rem" }}>
                <Box
                  className="left"
                  sx={{
                    width: "30%",
                  }}
                >
                  <LeftSection
                    candidateData={candidateData}
                    candidateId={candidateId}
                    candidate={candidate}
                  />
                </Box>
                <Box
                  className="right"
                  sx={{
                    width: "70%",
                  }}
                >
                  <RightSection
                    candidateData={candidateData}
                    candidateId={candidateId}
                    candidate={candidate}
                  />
                </Box>
              </Box>
            </Box>
            {/* Project and Work Experience
          // <ProjectWork candidateData={data.data.result} candidateId={candidateId} />

          // {/* Personal Project and Education Section */}
            {/* <EducationP candidateData={data.data.result} candidateId={candidateId} /> */}
          </Box>
        </Box>
        <Snackbar
          open={errorOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
            Please Complete the Profile
          </Alert>
        </Snackbar>
        <Snackbar
          open={successOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            sx={{ width: "100%" }}
            severity="success"
          >
            Profile is completed
          </Alert>
        </Snackbar>
        <Snackbar
          open={resumeSucessOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            sx={{ width: "100%" }}
            severity="success"
          >
            Resume is uploaded
          </Alert>
        </Snackbar>
        <Snackbar
          open={resumeErrorOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
            Resume upload Failed
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

export default Candidateprofile;
