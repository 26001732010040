import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import { Login, Signup, ResetPassword, SetPassword } from "./pages/auth";
import Homepage from "./pages/Homepage.js";
import { Candidateprofile, Candidatedashboard } from "./pages/candidate";
import Recruiterdashboard from "./pages/recruiter/Recruiterdashboard.js";
import RecruiterProfile from "./pages/recruiter/RecruiterProfile.js";

import NewRecruiterDashboard from "pages/recruiter/NewRecruiterDashboard";
import NewCandidateDashboard from "pages/candidate/NewCandidateDashboard";

import {
  AdminDashboard,
  SwitchUser,
  AdminJobs,
  AdminRecruiters,
  AdminCompanies,
  AdminSubscription,
} from "pages/admin";
import VerifyAccount from "pages/auth/VertifyAccount";
import NewJobPost from "pages/NewJobPost";
import ContactUs from "pages/recruiter/ContactUs";
import Jobboard from "./pages/Jobboard.js";
import Job from "./pages/Job.js";
import Jobpost from "./pages/job/Jobpost";
import Postjob from "pages/recruiter/Postjob";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AuthProvider } from "./utils/auth";
import {
  RequiredAdminAuth,
  RequiredCandidateAuth,
  RequiredRecruiterAuth,
  CandidateDone,
} from "components/AuthContext";
import CompanyProfile from "pages/recruiter/CompanyProfile";
import Helmet from "components/MetaInformation/Helmet";
import NewHomePage from "pages/NewHomePage.js";
import NewLogin from "pages/auth/NewLogin";
import NewSignup from "pages/auth/NewSignup";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";
import TNC from 'pages/tnc';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: true,
      // // staleTime: 50000,
      // staleTime: 0,
      // // cacheTime: 25000,
      // cacheTime: 0,
    },
  },
});
function App() {
  // const [recruiter, setRecuruiter] = useState(false);

  axios.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <div className="App" style={{ margin: 0, backgroundColor: "#ffffff" }}>
      <Helmet />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              <Route exact path="/" element={<NewHomePage />} />
              <Route exact path="/tnc" element={<TNC />} />

              <Route exact path="/account/verify" element={<VerifyAccount />} />
              {/* <Route exact path="/" element={<Homepage />} /> */}
              <Route exact path="/jobboard" element={<Jobboard />} />
              <Route
                exact
                path="/job/:id"
                element={
                  <RequiredRecruiterAuth>
                    {" "}
                    <Job />{" "}
                  </RequiredRecruiterAuth>
                }
              />
              {/* <Route exact path="/new/jobpost/:id" element={<NewJobPost />} /> */}
              <Route exact path="/jobpost/:id" element={<NewJobPost />} />
              {/* <Route exact path="/candidate/:id" element={<RequiredRecruiterAuth> <Candidateprofile /></RequiredRecruiterAuth>} /> */}
              <Route
                exact
                path="/candidate/:id"
                element={<Candidateprofile />}
              />
              <Route
                exact
                path="/candidate/dashboard"
                element={
                  <CandidateDone>
                    {" "}
                    <RequiredCandidateAuth>
                      {" "}
                      <NewCandidateDashboard />{" "}
                    </RequiredCandidateAuth>{" "}
                  </CandidateDone>
                }
              />
              <Route
                exact
                path="/recruiter/dashboard"
                element={
                  <RequiredRecruiterAuth>
                    {" "}
                    <NewRecruiterDashboard />{" "}
                  </RequiredRecruiterAuth>
                }
              />
              <Route
                exact
                path="/recruiter/profile"
                element={
                  <RequiredRecruiterAuth>
                    <RecruiterProfile />
                  </RequiredRecruiterAuth>
                }
              />
              <Route
                exact
                path="/company/profile"
                element={
                  <RequiredRecruiterAuth>
                    <CompanyProfile />
                  </RequiredRecruiterAuth>
                }
              />
              <Route
                exact
                path="/company/profile/:id"
                element={
                  <RequiredRecruiterAuth>
                    <CompanyProfile />
                  </RequiredRecruiterAuth>
                }
              />
              <Route
                exact
                path="/postjob"
                element={
                  <RequiredRecruiterAuth>
                    <Postjob />{" "}
                  </RequiredRecruiterAuth>
                }
              />
              <Route
                exact
                path="/candidate/profile"
                element={
                  <RequiredCandidateAuth>
                    {" "}
                    <Candidateprofile />{" "}
                  </RequiredCandidateAuth>
                }
              />
              <Route
                exact
                path="/admin/dashboard"
                element={
                  <RequiredAdminAuth>
                    <AdminDashboard />
                  </RequiredAdminAuth>
                }
              />
              <Route
                exact
                path="/admin/jobs"
                element={
                  <RequiredAdminAuth>
                    <AdminJobs />
                  </RequiredAdminAuth>
                }
              />
              <Route
                exact
                path="/admin/recruiters"
                element={
                  <RequiredAdminAuth>
                    <AdminRecruiters />
                  </RequiredAdminAuth>
                }
              />
              <Route
                exact
                path="/admin/recruiter/add/subscription/:id"
                element={
                  <RequiredAdminAuth>
                    <AdminSubscription />
                  </RequiredAdminAuth>
                }
              />
              <Route
                exact
                path="/admin/companies"
                element={
                  <RequiredAdminAuth>
                    <AdminCompanies />
                  </RequiredAdminAuth>
                }
              />
              <Route
                exact 
                path="/admin/su"
                element={
                  <RequiredAdminAuth>
                    <SwitchUser />
                  </RequiredAdminAuth>
                }
              />
              <Route exact path="/contactus" element={<ContactUs />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/reset/password" element={<ResetPassword />} />
              <Route exact path="/set/password" element={<SetPassword />} />
            </Routes>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
