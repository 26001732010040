import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar";
import { fetchAdminJobs } from "components/Fetchers";
import { Box, TextField, CancelIcon, CheckCircleIcon } from "components/MUI";
import { AdminReactTable } from "components/Admin";
import { useNavigate } from "react-router-dom";
import {
  makeFeatured,
  makeUnfeatured,
  deleteJobPost,
} from "components/Fetchers";

// import AdminJobTable from 'components/Admin/ReactTable/AdminJobTable';

const AdminJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const fetchAdminJobsAndApply = (offset) => {
    if (offset) {
      fetchAdminJobs(
        limit,
        offset,
        input.jobTitle,
        input.recruiterId,
        input.companyId
      )
        .then((data) => {
          setJobs(data);
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      fetchAdminJobs(
        limit,
        0,
        input.jobTitle,
        input.recruiterId,
        input.companyId
      )
        .then((data) => {
          setJobs(data);
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const deleteThisJob = (id) => {
    console.log("deleting");
    deleteJobPost(id)
      .then((data) => {
        console.log("deleted");
        console.log(data);
        setJobs(jobs.filter((job) => job._id !== id));
        // fetchAdminJobsAndApply(offset)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "companyName", // accessor is the "key" in the data
      },
      {
        Header: "Job Title",
        accessor: "jobTitle",
      },
      {
        Header: "Job Category",
        accessor: "jobCategory",
      },
      {
        Header: "No. of Applicants",
        accessor: "noOfApplicants",
      },
      {
        Header: "Featured",
        accessor: "featured",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );
  useEffect(() => {
    fetchAdminJobsAndApply(offset);
  }, [limit, offset]);
  // console.log(jobs)
  const rowMaker = (jobs) => {
    const rows = jobs.map((job) => {
      return {
        id: job._id,
        companyName: job?.recruiter?.company?.companyName,
        jobTitle: viewGen(job._id, job.title),
        jobCategory: job.category,
        noOfApplicants: job.numOfApplicants,
        featured: featuredGen(job.featured, job._id),
        action: actionGen(job._id),
      };
    });
    return rows;
  };
  const featuredGen = (featured, id) => {
    if (featured) {
      return (
        <CheckCircleIcon
          sx={{ color: "green", cursor: "pointer" }}
          onClick={() => handleUnfeature(id)}
        />
      );
    } else {
      return (
        <CancelIcon
          sx={{ color: "red", cursor: "pointer" }}
          onClick={() => handleFeature(id)}
        />
      );
    }
  };

  const viewGen = (id, title) => {
    return (
      <div style={{ cursor: "pointer" }} onClick={() => handleView(id)}>
        {title}
      </div>
    );
  };
  const actionGen = (id) => {
    return (
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <div
          style={{
            fontWeight: "bold",
            textDecoration: "none",
            cursor: "Pointer",
            textAlign: "center",
            margin: "0",
            padding: ".3rem .6rem",
            backgroundColor: "#2BC155",
            borderRadius: "10.4496px",
            color: "#fff",
          }}
          onClick={() => handleEdit(id)}
        >
          Edit
        </div>
        <div
          style={{
            fontWeight: "bold",
            textDecoration: "none",
            cursor: "Pointer",
            textAlign: "center",
            margin: "0",
            padding: ".3rem .6rem",
            backgroundColor: "red",
            borderRadius: "10.4496px",
            color: "#fff",
          }}
          onClick={() => deleteThisJob(id)}
        >
          Delete
        </div>
      </div>
    );
  };
  const handleSearch = (e) => {
    e.preventDefault();
    console.log("search");
    console.log(input);
    fetchAdminJobsAndApply();
  };
  const [input, setInput] = useState({
    jobTitle: "",
    recruiterId: "",
    companyId: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/job/${id}`);
  };
  const handleView = (id) => {
    navigate(`/jobpost/${id}`);
  };
  const handleFeature = (id) => {
    makeFeatured(id)
      .then((res) => {
        console.log(res);
        //   console.log(jobs)
        setJobs(
          jobs.map((job) => {
            if (job._id === id) {
              return {
                ...job,
                featured: true,
              };
            } else {
              return job;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUnfeature = (id) => {
    makeUnfeatured(id)
      .then((res) => {
        console.log(res);
        setJobs(
          jobs.map((job) => {
            if (job._id === id) {
              return {
                ...job,
                featured: false,
              };
            } else {
              return job;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Navbar />
      <Box>
        <form onSubmit={handleSearch}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 3rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <TextField
                label="Job Title"
                variant="outlined"
                name="jobTitle"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                label="Recruiter ID"
                variant="outlined"
                name="recruiterId"
                onChange={(e) => handleChange(e)}
              />
              <TextField
                label="Company ID"
                variant="outlined"
                name="companyId"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <button className="filters__search">Search</button>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem 3rem",
          }}
        >
          <AdminReactTable
            columns={columns}
            rowMaker={rowMaker}
            rowData={jobs}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </Box>
    </>
  );
};

export default AdminJobs;
