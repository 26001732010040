import React, { useEffect, useState } from "react";
import { Box, Container } from "components/MUI";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import { useNavigate } from "react-router-dom";
import NewJobtable from "components/RecruiterDashboard/NewJobTable";
import SideBar from "components/RecruiterDashboard/SideBar";
import BigCard from "components/Cards/BigCard";
import NewSmallCard from "components/Cards/NewSmallCard";
import { useAuth } from "utils/auth";
import NewNavbar from "components/Layout/NewNavbar";
import NewFooter from "components/Layout/NewFooter";
import NewJobApplications from "components/RecruiterDashboard/NewJobApplications";
import { pendingJobApplicant, recruiterData, recruiterJobPosts, recruiterNotifications, recruiterJobApplications } from 'components/Fetchers';

import JobApplications from "components/RecruiterDashboard/JobApplications";

function NewRecruiterDashboard() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [userInfo, setUserInfo] = useState({});

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showRecruiterData, setShowRecruiterData] = useState({ jobposts: [] });
    const [showJobPosts, setShowJobPosts] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [totalApplications, setTotalApplications] = useState(0);
    const [jobApplicationsList, setJobApplicationsList] = useState([]);
    const [totalShortlisted, setTotalShortlisted] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);



    const toggleCreateModal = () => {
        setShowCreateModal(!showCreateModal);
    };

    // auth.logout()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data1 = await recruiterData();
                setUserInfo(data1);

                const data2 = await recruiterNotifications();
                setNotifications(data2);

                const data3 = await recruiterJobPosts();
                setShowJobPosts(data3);

                const data4 = await recruiterJobApplications();
                setTotalApplications(data4.length);
                totalShortListedCalc(data4);
                setJobApplicationsList(data4);

                const data5 = await pendingJobApplicant();
                setPendingCount(data5.length);
            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, []);

    const totalShortListedCalc = (data) => {
        var total = 0;
        data.forEach((job) => {
            if (job.status === "Shortlisted")
                total++;
        });
        setTotalShortlisted(total);
    };


    const rejectAfterEffect = (id) => {
        setJobApplicationsList(jobApplicationsList.filter((item) => item._id !== id));
    };
    return (
        <div className="bg-[#F9F9F9] w-full ">
            <div className="w-full h-full center-align">
                <div className="flex justify-center w-full">
                    <Box
                        component="main"
                        className="w-full"
                    >

                        <Container
                            className="flex md:flex-row flex-col md:items-start mb-6 md:ml-0 md:pl-0 items-center md:w-full max-w-full md:h-full"
                        >
                            <div className="w-full flex md:hidden">
                                <NewNavbar disableLogo={true} className="z-20" />
                            </div>
                            <h1 className="font-sans text-[18px] md:hidden">Welcome {userInfo.name}!</h1>

                            <SideBar notifications={notifications} userInfo={userInfo} className="" />
                            <div className="flex flex-col justify-center items-center md:items-start space-y-0 md:max-w-[calc(100%-237px)] xl:max-w-full">
                                <div className="w-full hidden md:flex">
                                    <NewNavbar disableLogo={true} className="z-20" />
                                </div>
                                <div className="mt-[45px]">
                                    <h1 className="font-sans text-[18px] hidden md:block mt-[45px]">Welcome {userInfo.name}!</h1>
                                </div>
                                <Box className="flex justify-center items-center md:block">
                                    <div className="flex mt-[20px] gap-[20px] flex-col 2xl:flex-row lg:w-full w-fit space-y-0">
                                        <div className="small-cards-div">
                                            <NewSmallCard
                                                number={showJobPosts.length}
                                                heading={showJobPosts.length === 1 ? "Job Posted" : "Jobs Posted"}
                                                secondaryColor="#E8FFEF"
                                                icon={<ArticleRoundedIcon className="check-rounded-icon text-[#2BC155]" />}
                                                color="#2BC155"

                                            />
                                            <NewSmallCard
                                                color="#3F9AE0"
                                                heading={totalApplications === 1 ? "Total Application" : "Total Applications"}
                                                number={totalApplications ? totalApplications : 0}
                                                secondaryColor="#E0F2FF"
                                                icon={<FolderRoundedIcon className="check-rounded-icon text-[#3F9AE0]" />}
                                            />
                                        </div>
                                        <div className="small-cards-div mt-5 pt-5 2xl:mt-0 2xl:pt-0">
                                            <NewSmallCard
                                                color="#FF9B52"
                                                heading="Total Shortlisted"
                                                number={totalShortlisted ? totalShortlisted : 0}
                                                secondaryColor="#FFEFEB"
                                                icon={<HowToRegRoundedIcon className="check-rounded-icon text-[#FF9B52]" />}
                                            />
                                            <NewSmallCard
                                                color="#9C6ADE"
                                                secondaryColor="#E6E6FA"

                                                heading={pendingCount === 1 ? "Pending Review" : "Pending Reviews"}
                                                number={pendingCount ? pendingCount : 0}
                                                icon={<ScheduleRoundedIcon className="check-rounded-icon text-[#9C6ADE]" />}
                                            />
                                        </div>
                                    </div>
                                </Box>
                                <Box className="mt-3 pt-3 w-full flex flex-col">
                                    <NewJobtable jobs={showJobPosts} className="w-full" />
                                    <NewJobApplications jobs={jobApplicationsList} rejectAfterEffect={(e) => rejectAfterEffect(e)} className="w-full" />
                                </Box>
                            </div>
                        </Container>

                    </Box>
                </div>
            </div>
            <NewFooter />
        </div>
    );
}

export default NewRecruiterDashboard;
