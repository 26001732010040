import React, { useState } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TelegramIcon,
  LinkedinIcon,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';
import wrong from '../../images/components/wrong.svg';

import LinkIcon from '@mui/icons-material/Link';
import { MuiAlert, Snackbar, Alert } from 'components/MUI';

const titleGen = (job) => {
  if (job) {
    const companyName = job?.company?.companyName;
    const jobTitle = job?.title;
    const title = companyName + ' is hiring for ' + jobTitle;
    return title;
  }
};
const summaryGen = (job) => {
  if (job) {
    const companyName = job?.company?.companyName;
    const jobTitle = job?.title;
    const jobDescription = job?.description;
    const summary =
      companyName + ' is hiring for ' + jobTitle + '\n' + jobDescription;
    // const summary=`${companyName} is hiring for "+${jobTitle}
    // ${jobDescription}`
    return summary;
  }
};
const linkedinGen = (job, jobTitle) => {
  const linkedInPost = {
    'https://www.linkedin.com/shareArticle?mini': 'true',
    source: 'workCrew',
    title: jobTitle,
    //  summary:"jobSummary",
    url: 'https://google.com',
  };
  return linkedInPost;
};
const linkedinLinkGen = (data) => {
  return Object.keys(data)
    .map((key) => key + '=' + data[key])
    .join('&');
};

const NewSocialShare = (props) => {
  const [copySuccess, setCopySucess] = useState(false);

  const onCopySuccess = () => {
    console.log('copied');
    setCopySucess(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopySucess(false);
  };
  const { setShareShow, job } = props;
  const shareUrl = `${window.origin}/jobpost/${job._id}`;

  const jobTitle = titleGen(job);

  const jobSummary = summaryGen(job);

  const linkedinPost = linkedinGen(job, jobTitle);

  const queryString = linkedinLinkGen(linkedinPost);
  const linkedinSummary = linkedinGen(job);

  return (
    <>
      {/* show as vertical popup with tailwind */}
      <div className="flex flex-col absolute z-40 p-2 bg-white shadowComponent rounded-2xl gap-2">
        <WhatsappShareButton
          onClick={(e) => e.stopPropagation()}
          url={shareUrl}
          title={jobTitle}
          separator={'\n'}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={shareUrl}
          // url="google.com"
          title={jobTitle}
          // summary={jobSummary}
          // source={shareUrl}
          onClick={(e) => e.stopPropagation()}
        >
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
        <FacebookShareButton
          url={shareUrl}
          quote={jobTitle}
          // url={"google.com"}
          onClick={(e) => e.stopPropagation()}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton
          onClick={(e) => e.stopPropagation()}
          title={jobTitle}
          url={shareUrl}
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <EmailShareButton
          subject={jobTitle}
          body={jobSummary}
          separator={'\n'}
          url={shareUrl}
          // onClick={(e) => console.log(e)}
        >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
        <TelegramShareButton
          onClick={(e) => e.stopPropagation()}
          url={shareUrl}
          title={jobTitle}
        >
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <EmailShareButton
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(shareUrl);
            onCopySuccess();
          }}
        >
          <LinkIcon />
        </EmailShareButton>

        <img
          src={wrong}
          className="w-5 mx-auto"
          alt="cross"
          onClick={(e) => {
            e.stopPropagation();
            setShareShow(false);
          }}
        ></img>
        <Snackbar
          open={copySuccess}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            sx={{ width: '100%' }}
            severity="success"
          >
            Successfully Copied to Clipboard
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default NewSocialShare;
