import React,{useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import "../style.css";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { bgcolor } from '@mui/system';
import InputTextField from '../../PostJob/InputTextField';
export const EditField = ({ data, index, updateState, editVariable, fullData, deleteHandler, doneHandler ,candidate}) => {
    const [edit, setEdit] = useState(editVariable);
    const [inputFields, setInputFields] = useState(data);
    // console.log(data)
    return (
        <>
            {inputFields ? (
                <>
                    {edit ? (
                        <>
                            <Box sx={{
                                display: "flex",

                            }}>
                                <Box
                                    key={inputFields.id}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        marginLeft: "2rem",
                                    }}
                                >
                                    <TextField
                                        variant="standard"
                                        label="Name"
                                        name="name"
                                        size="small"
                                        sx={{
                                            display: "inline",
                                            marginBottom: "1rem",
                                            width: "75%",
                                        }}
                                        value={fullData[index].name}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            console.log(e.target.name);
                                            updateState(index,e);
                                        }}
                                    />
                                    <TextField
                                        variant="standard"
                                        label="Type"
                                        size="small"
                                        name="type"
                                        sx={{
                                            width: "80%",
                                            marginBottom: "1rem",
                                        }}
                                        value={fullData[index].type}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            console.log(e.target.name);
                                            updateState(index, e);
                                        }}

                                    />
                                    <TextField
                                        variant="standard"
                                        label="Live Link"
                                        size="small"
                                        name="liveLink"
                                        sx={{
                                            width: "90%",
                                            marginBottom: "1rem",
                                        }}

                                        value={fullData[index].liveLink}

                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            console.log(e.target.name);
                                            updateState(index, e);
                                        }}

                                    />
                                    <TextField
                                        variant="standard"
                                        label="Description"
                                        size="small"
                                        multiline
                                        name="description"
                                        sx={{
                                            width: "100%",
                                            marginBottom: "1rem",
                                        }}
                                        value={fullData[index].description}

                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            console.log(e.target.name);
                                            updateState(index, e);
                                        }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <DoneIcon
                                        sx={{
                                            fontSize: 20,
                                            color: "gray",
                                            margin: "auto",
                                            "&:hover": { color: "black", cursor: "pointer" },
                                            display: "inline",
                                        }}
                                        onClick={() => {
                                            if (
                                                fullData[index].name === "" ||
                                                fullData[index].type === "" ||
                                                fullData[index].liveLink === "" ||
                                                fullData[index].description === ""
                                            ) {
                                                alert("Please fill all the fields");
                                            } else {
                                                console.log("full data at thisw index");
                                                console.log(fullData[index]);
                                                setEdit(!edit);
                                                doneHandler()

                                            }
                                        }}
                                    />
                                    <DeleteIcon
                                        sx={{
                                            fontSize: 15,
                                            color: "lightgray",
                                            margin: "auto 0rem",
                                            "&:hover": { color: "gray", cursor: "pointer" },
                                            display: "flex",
                                        }}
                                        onClick={() => {
                                            deleteHandler(fullData[index]._id)
                                        }}
                                    />

                                </Box>


                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: "flex", justifyContent: "space-between"
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex", flexDirection: "column",
                                        flex: "1"

                                    }}
                                >
                                    <div className="projectsW__details">
                                        <div className="projectsW__project">
                                            <p
                                                className="details__brownBox_Projects"
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-evenly",

                                                }}
                                                    onClick={() => window.open(fullData[index].liveLink, "_blank")}
                                            >

                                                {fullData[index].name}

                                                <OpenInNewIcon
                                                    sx={{
                                                        fontSize: "15px",
                                                        marginLeft: "0.1rem",
                                                    }}
                                                />
                                            </p>

                                                <p className="details__title">{fullData[index].type}</p>

                                            <Box sx={{
                                                width: "100%",
                                            }}>
                                                <p className="projectsW__project__description">
                                                        {fullData[index].description}
                                                </p>

                                            </Box>

                                        </div>
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "auto",
                                        gap: 2,
                                        marginBottom: "auto",

                                    }}
                                >
                                    {candidate ? (<> <EditIcon
                                        sx={{
                                            fontSize: 15,
                                            color: "lightgray",
                                            margin: "auto 0rem",
                                            "&:hover": { color: "gray", cursor: "pointer" },
                                            display: "flex",
                                        }}
                                        onClick={() => {
                                            setEdit(!edit);
                                        }}
                                    />
                                    <DeleteIcon
                                        sx={{
                                            fontSize: 15,
                                            color: "lightgray",
                                            margin: "auto 0rem",
                                            "&:hover": { color: "gray", cursor: "pointer" },
                                            display: "flex",
                                        }}
                                        onClick={() => {
                                            deleteHandler(fullData[index]._id)
                                        }}
                                    /></>) : (<>  </>)}
                                   
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <></>
            )}
        </>
    );
};
