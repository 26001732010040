import React, { useEffect } from "react";
import "./style.css";
import Rating from "@mui/material/Rating";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { bgcolor } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import EditContactField from "./EditFields/EditContactField";
import axios from "axios";
import { useAuth } from 'utils/auth';
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";


function BasicInfo({ candidateData, candidateId, candidate,print }) {
  // console.log('devprofile')
  // console.log(candidateData.devProfile.flaskData)

  const [categories, setCategories] = React.useState(
    candidateData?.devProfile?.flaskData?.Github ? Object.keys(candidateData?.devProfile?.flaskData?.Github) : []
  )

  var colorArray = ["#6d77dd",
    "#bdb23f",
    "#543f96",
    "#83b551",
    "#82317c",
    "#63c477",
    "#cf7bce",
    "#45bd86",
    "#d7485b",
    "#44c4ac",
    "#dd6298",
    "#4d7629",
    "#6e8dd7",
    "#cb782b",
    "#912c5d",
    "#ac8940",
    "#d66d82",
    "#92381f",
    "#e07455",
    "#a03745"]

  // https://medialab.github.io/iwanthue/

  const getSkillsNumberArray = (skills) => {
    if (!skills) return
    const skillarrray = [];
    var repoList = {}
    const percentageList = {}
    // console.log(skills)
    const skillNameArray = Object.keys(skills)
    const skillValueArray = []
    for (const [key, value] of Object.entries(skills)) {
      // console.log(key)
      repoList[key] = value[0]
      skillValueArray.push(value[0])
      // percentageList[key]=value[1]*100
    }

    return { nameArray: skillNameArray, valueArray: skillValueArray }
  }
  // console.log(candidateData?.devProfile?.flaskData)
  // console.log("getSkillsNumberArray")
  if (candidateData?.devProfile?.flaskData?.Github) {
    const { nameArray, valueArray } = getSkillsNumberArray(candidateData?.devProfile?.flaskData?.Github)

  }
  // console.log(nameArray)
  // console.log(valueArray)

  const [options, setOptions] = React.useState({
    chart: {
      id: 'chart'
    },
    xaxis: {
      categories: [],
    },
    // colors: colorArray,

  });

  const [series, setSeries] = useState([{
    name: 'No of Repositories',
    data: [],
  }])

  useEffect(() => {
    if (!candidateData?.devProfile?.flaskData?.Github) return
    const { nameArray, valueArray } = getSkillsNumberArray(candidateData?.devProfile?.flaskData?.Github)

    setOptions({
      ...options,
      xaxis: {
        categories: nameArray
      },
      plotOptions: {
        bar: {
          // columnWidth: '45%',
          distributed: true,
        }
      },
    })
    setSeries([{
      ...series,
      data: valueArray
    }])
  }, [candidateData])




  // console.log(categories)
  // const [series, setSeries] = React.useState([
  //   { name: "Skills", data: [20, 90, 40, 30, 50, 80, 33] },
  // ]);
  // const [options, setOptions] = React.useState({
  //   chart: {
  //     height: 350,
  //     type: "radar",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   plotOptions: {
  //     radar: {
  //       size: 140,
  //       polygons: {
  //         strokeColors: "#C0C0C0",
  //         fill: {
  //           colors: ["#E6E6E6", "#fff"],
  //         },
  //       },
  //     },
  //   },
  //   colors: ["#000000"],
  //   markers: {
  //     size: 4,
  //     colors: ["#fff"],
  //     strokeColor: "#000000",
  //     strokeWidth: 2,
  //   },
  //   tooltip: {
  //     y: {
  //       formatter: function (val) {
  //         return val;
  //       },
  //     },
  //   },
  //   xaxis: {
  //     categories: categories,
  //   },
  //   yaxis: {
  //     tickAmount: 7,
  //     labels: {
  //       formatter: function (val, i) {
  //         if (i % 2 === 0) {
  //           return val;
  //         } else {
  //           return "";
  //         }
  //       },
  //     },
  //   },
  // });
  // const [platform, setPlatform] = React.useState("");

  // const handleChange = (e) => {
  //   setPlatform(e.target.value);
  // };
  // const { address,email,phone,exp ,languages,position } = candidateData;



  const position = candidateData?.jobPosition ? candidateData.jobPosition : (candidate ? "Enter Job Position" : "Job Position")
  // setCandidate()



  const [contactInfo, setContactInfo] = useState({
    name: candidateData?.name ? candidateData.name : (candidate ? "Enter Name" : "Candidate Name"),
    address: candidateData?.contactInfo?.address ? candidateData.contactInfo.address : (candidate ? "Enter Address" : "Candidate Address"),
    email: candidateData?.email ? candidateData.email : (candidate ? "Enter Email" : "Candidate Email"),
    phone: candidateData?.contactInfo?.phone ? candidateData.contactInfo.phone : (candidate ? "Enter Phone" : "Candidate Phone"),
    exp: candidateData?.contactInfo?.exp ? candidateData.contactInfo.exp : (candidate ? "Enter Experience" : "Candidate Experience"),
    languages: candidateData?.contactInfo?.languages ? (
      //split the array into string
      (candidateData.contactInfo.languages.join(", "))
    ) : (
      (candidate ? "Enter Languages" : "Candidate Languages")
    ),
  });


  useEffect(() => {
    setContactInfo({
      name: candidateData?.name ? candidateData.name : (candidate ? "Enter Name" : "Candidate Name"),
      address: candidateData?.contactInfo?.address ? candidateData.contactInfo.address : (candidate ? "Enter Address" : "Candidate Address"),
      email: candidateData?.email ? candidateData.email : (candidate ? "Enter Email" : "Candidate Email"),
      phone: candidateData?.contactInfo?.phone ? candidateData.contactInfo.phone : (candidate ? "Enter Phone" : "Candidate Phone"),
      exp: candidateData?.contactInfo?.exp ? candidateData.contactInfo.exp : (candidate ? "Enter Experience" : "Candidate Experience"),
      languages: candidateData?.contactInfo?.languages ? (
        //split the array into string
        (candidateData.contactInfo.languages.join(", "))
      ) : (
        (candidate ? "Enter Languages" : "Candidate Languages")
      ),
    });
    setJobPosition(candidateData?.jobPosition ? candidateData.jobPosition : (candidate ? "Enter Job Position" : "Job Position"))
  }, [candidateData]);



  const [jobPosition, setJobPosition] = useState(position)

  const editFields = Object.keys(contactInfo);

  const patchField = async (value) => {
    var patchData = { jobPosition: value }
    console.log("Patching Data")
    console.log(patchData)
    axios.patch(
      `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, patchData
    ).then((res) => {
      console.log(res.data);
      console.log(value);
    }, err => {
      console.log(err);
    });

  };
  const valueHider = (jobPosition) => {
    if (jobPosition == "Enter Job Position") return jobPosition = ""
    else return jobPosition
  }


  const [edit, setEdit] = useState(false);


  return (
    <Box sx={{
      display: 'flex',
      margin: '0rem 1.5rem 1rem 1.5rem',
      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.25)",
      borderRadius: "10px",
      padding: '1.5rem',
      // gap: '2rem',
      flexWrap: {
        xs: 'wrap',
        sm: 'nowrap'

      }
    }}>
      <Box sx={{
        marginRight: '1rem',

      }}>
        <Box>
          <Avatar sx={{ my: "auto", width: '100px', height: '100px' }}
            src="components/images/avatar.png"
          />
          <Box sx={{ display: "block" }}>
            <p
              style={{
                margin: "0.5rem",
                fontFamily: "outfit",
                fontWeight: "400",
                fontSize: "25px",
              }}
            >
              {contactInfo.name}
            </p>
            <p
              style={{
                margin: "0.5rem",
                fontFamily: "outfit",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              {!edit ? (<>
                {jobPosition}
                {candidate ? (<> <EditIcon
                  sx={{
                    fontSize: 15,
                    color: "lightgray",
                    marginLeft: "0.5rem",
                    "&:hover": { color: "gray", cursor: "pointer" },
                    display: "inline",
                  }}
                  onClick={() => {
                    setEdit(!edit);
                    // patchField(value[subValue],subValue);
                  }}
                /></>) : (<></>)}


              </>) : (<>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    size="small"
                    sx={{
                      display: "inline",
                      height: "2rem",
                    }}
                    value={valueHider(jobPosition)}
                    onChange={(e) => {
                      setJobPosition(e.target.value)
                    }}
                  />
                  <DoneIcon
                    sx={{ fontSize: 20, display: "inline", cursor: "pointer" }}
                    onClick={() => {
                      if (jobPosition !== "") {
                        setEdit(!edit)
                        console.log(jobPosition)
                        patchField(jobPosition)
                      }
                      else {
                        alert("Enter a value")
                      }
                    }}
                  />
                </Box>
              </>)}

            </p>
          </Box>
        </Box>

        <Rating
          name="read-only"
          value={3}
          readOnly
          className="basicInfo__rating"
        />

        <p className="buttons__downloadcv" onClick={print} style={{ marginTop: "1rem" }}>
          Download Resume
        </p>

        <div className="basicInfo__icons">
          <FacebookIcon
            sx={{
              zoom: "130%",
              marginLeft: "0.3rem",
              marginRight: "0.3rem",
              cursor: "pointer",
            }}
          />
          <LinkedInIcon
            sx={{
              zoom: "130%",
              marginLeft: "0.3rem",
              marginRight: "0.3rem",
              cursor: "pointer",
            }}
          />
          <InstagramIcon
            sx={{
              zoom: "130%",
              marginLeft: "0.3rem",
              marginRight: "0.3rem",
              cursor: "pointer",
            }}
          />
          <TwitterIcon
            sx={{
              zoom: "130%",
              marginLeft: "0.3rem",
              marginRight: "0.3rem",
              cursor: "pointer",
            }}
          />
        </div>
      </Box>

      <Box sx={{
        borderRight: '2px solid lightgray',
        padding: '0rem 3rem 0rem 1rem'

      }}>

        <Typography
          variant="h6"
          sx={{
            marginTop: "0rem",
            fontFamily: "outfit",
            fontWeight: "400",
          }}
        >Contact Info</Typography>

        {/* master code single piece
              <Box>
          {edit ? (
            <>
              <Box sx={{ display: "inline" }}>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  size="small"
                  sx={{
                    display: "inline",
                    height: "2rem",
                  }}
                  value={contactInfo.location}
                  onChange={(e) => {
                    setContactInfo({
                      ...contactInfo,
                      location: e.target.value,
                    });
                  }}
                />
                <DoneIcon
                  sx={{ fontSize: 20, display: "inline", cursor: "pointer" }}
                  onClick={() => {
                    setEdit(!edit);
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <p>
                {contactInfo.location}{" "}
                <EditIcon
                  sx={{
                    fontSize: 15,
                    color: "lightgray",
                    "&:hover": { color: "gray", cursor: "pointer" },
                    display: "inline",
                  }}
                  onClick={() => {
                    setEdit(!edit);
                  }}
                />
              </p>
            </>
          )}
        </Box> */}
        {editFields.map((item, key) => (


          <Box key={key}>
            <EditContactField
              candidateId={candidateId}
              value={contactInfo}
              subValue={item}
              setInputValue={setContactInfo}
              candidate={candidate}
              editFields={contactInfo}
            />
          </Box>

        ))}
      </Box>

      <Box sx={{
        marginLeft: '3rem',
        display: 'flex',
        // backgroundColor:'red',
        flexGrow: '1',
      }} >

        <Box sx={{
          flexGrow: '1',
        }}>
          <Typography variant="h6" sx={{ fontFamily: "outfit" }}>
            Skills
          </Typography>

          {
            candidateData?.devProfile?.flaskData?.Github ? (
              <>
                <Chart
                  options={options}
                  series={series}
                  type="bar"
                  height="100%"
                  width="100%"
                />

              </>
            ) : (<>
              <Box

              >
                <p
                  style={{
                    color: "gray",
                    textAlign: "center",
                    marginTop: '5rem',
                    fontSize: "1.2rem",
                    width: "100%",
                  }}
                >
                  Please Complete the Dev Profiles to get Detailed Analysis
                </p>
              </Box>
            </>)

          }

          {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Platform</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={platform}
              label="Platform"
              onChange={handleChange}
            >
              <MenuItem default value={10}>
                Github
              </MenuItem>
              <MenuItem value={20}>Linkedin</MenuItem>
              <MenuItem value={30}>GFG</MenuItem>
            </Select>
          </FormControl> */}



          {/* <ReactApexChart
          className="reactchart1"
          options={options}
          series={series}
          type="radar"
          height={320}
          width={550}
          style={{ zoom: "80%", marginLeft: "auto" }}
        /> */}
        </Box>
      </Box>

    </Box>
  );
}

export default BasicInfo;
