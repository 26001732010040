import React from "react";
import NewNavbar from "components/Layout/NewNavbar";
import HeroSection from "components/Homepage/HeroSection";
import NewBottomSection from "components/Homepage/NewBottomSection";
import NewFooter from "components/Layout/NewFooter";
import NewFeaturedJob from "components/Homepage/NewFeaturedJob";
import NewEmployersSection from "components/Homepage/NewEmployersSection";
import NewRecruitementCompany from "components/Homepage/NewRecruitementCompany";
const NewHomePage = () => {
  const [jobs, setJobs] = React.useState([]);
  return (
    <>
      <div className=" mx-auto font-sans ">
        <NewNavbar />
        <HeroSection jobs={jobs} />
        {/* <NewEmployersSection /> */}
        <NewFeaturedJob setJobs={setJobs} />
        <NewRecruitementCompany />
        <NewBottomSection />
        <NewFooter />
      </div>
    </>
  );
};

export default NewHomePage;
