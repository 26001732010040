import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import Featured from "components/Homepage/Featured";
import PersonalInfo from "components/CandidateDashboard/PersonalInfo";
import Activities from "components/CandidateDashboard/Activities";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import BigCard from "components/Cards/BigCard";
import SmallCard from "components/Cards/SmallCard";
import { useAuth } from "utils/auth";
import Navbar from "components/Navbar";
import Jobtable from "components/CandidateDashboard/Jobtable";
import Jobslist from "components/Jobboard/Jobslist";
import {
    Snackbar,
    MuiAlert,
    Alert
} from 'components/MUI'
import { candidateData, candidateNotifications, candidateAppliedJobs, getRecommendedJobs, fetchCandidateData } from "components/Fetchers";

import NewNavbar from "components/Layout/NewNavbar";

function NewCandidateDashboard() {
    const navigate = useNavigate();
    const [jobApplications, setJobApplication] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([])
    const [candidateSkills, setCandidateSkills] = useState([])
    const [showJobs, setShowJobs] = useState([])
    const [totalShortlisted, setTotalShortlisted] = useState(0);
    const [CData, setCData] = useState(null);
    const [candidateProjects, setCandidateProjects] = useState({
        github: "",
        stackOverflow: "",
    });

    const auth = useAuth()

    const jobSetter = (job) => {
        setFilteredJobs(job)
    }
    const handleApplyAfterEffect = (id, bool) => {
        if (bool) {
            const newJobs = showJobs.filter((job) => job._id !== id)
            setFilteredJobs(newJobs)
            onSuccess()
        }
        else {
            onError()
        }
    }
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorOpenPublic, setErrorOpenPublic] = useState(false);

    const onSuccess = () => {
        setSuccessOpen(true);
    };
    const onError = () => {
        setErrorOpen(true);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
        setErrorOpen(false);
    };

    const getSkillsNumberArray = (skills) => {
        if (!skills) return
        const skillarrray = [];
        var repoList = {}
        const percentageList = {}
        // console.log(skills)
        const skillNameArray = Object.keys(skills)
        const skillValueArray = []
        for (const [key, value] of Object.entries(skills)) {
            // console.log(key)
            repoList[key] = value[0]
            skillValueArray.push(value[0])
            // percentageList[key]=value[1]*100
        }

        return { nameArray: skillNameArray, valueArray: skillValueArray }
    }
    // console.log(candidateData?.devProfile?.flaskData)
    // console.log("getSkillsNumberArray")

    useEffect(() => {
        if (!CData?.devProfile?.flaskData?.Github) return
        const { nameArray, valueArray } = getSkillsNumberArray(CData?.devProfile?.flaskData?.Github)
        console.log(nameArray)
        console.log(valueArray)
        const noOfGithubProjects = getTotalNumbersFromArray(valueArray)
        setCandidateProjects({ ...candidateProjects, github: noOfGithubProjects })
    }, [CData])

    const getTotalNumbersFromArray = (array) => {
        var total = 0;
        array.forEach((num) => {
            total += num;
        });
        return total;
    }




    useEffect(async () => {
        candidateData().then((data) => {
            setUserInfo(data);
            fetchCandidateData(auth.userId).then((res) => {
                console.log('CANDIDATE DATA')
                console.log(res.data.result)
                setCData(res.data.result)
                setCandidateSkills(res.data.result.skills)
            }).then(() => {
                getRecommendedJobs().then((res) => {
                    console.log('RECOMMENDED JOBS')
                    setShowJobs(res.reverse())
                    jobSetter(res)
                }

                )
            })

        }
        ).catch((err) => {
            console.log(err);
        });
        candidateNotifications().then((data) => {
            setNotifications(data);
        }
        ).catch((err) => {
            console.log(err);
        });
        candidateAppliedJobs().then((data) => {
            setJobApplication(data);
            // console.log(data)
            totalShortListedCalc(data)
        }).catch((err) => {
            console.log(err);
        });

    }, []);

    const totalShortListedCalc = (data) => {
        var total = 0;
        data.forEach((job) => {
            if (job.status === "Shortlisted")
                total++;
        });
        setTotalShortlisted(total);
    };


    const [series, setSeries] = React.useState([
        { name: "Activity", data: [10, 41, 35, 51, 10, 62, 30] },
    ]);
    const [options, setOptions] = React.useState({
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: "Your Activity",
            align: "left",
            style: {
                fontFamily: "Outfit",
                fontSize: "25px",
                fontWeight: "600",
            },
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
            ],
        },
    });

    return (
        <div className="bg-[#F9F9F9] w-full ">
            <div className="w-full h-full center-align">
                <div className="flex justify-center w-full">
                    <Box
                        component="main"
                        className="w-full"
                    >
                        <Container
                            className="flex md:flex-row flex-col md:items-start mb-6 md:ml-0 md:pl-0 items-center md:w-full md:h-full"
                        >
                            <div className="w-full flex md:hidden mt-6">
                                <NewNavbar disableLogo={true} className="z-20" />
                            </div>
                            <h1 className="font-sans text-[18px] md:hidden">Welcome {userInfo.name}!</h1>

                        </Container>
                    </Box>
                </div>
            </div>
        </div>


        //     <div style={{ backgroundColor: "#F9F9F9" }}>
        //       {/* Navbar */}
        //       <Navbar />
        //       <div style={{ display: "flex", justifyContent: "center" }}>
        //         <Box
        //           component="main"
        //           sx={{
        //             py: '3rem',
        //             maxWidth: "xl",
        //             display: "flex",
        //             overflow: "hidden",
        //           }}
        //         >
        //           <Container
        //             sx={{ width: "70%", display: "flex", flexDirection: "column" }}
        //           >
        //             <Box>
        //               <BigCard
        //                 heading={'Welcome to WorkCrew'}
        //                 name={userInfo.name}
        //               />

        //             </Box>

        //             <Box
        //               sx={{
        //                 display: "flex",
        //                 justifyContent: "space-between",
        //                 marginTop: "20px",
        //                 flexGap: "20px",
        //               }}
        //             >
        //               <SmallCard
        //                 color="#2BC155"
        //                 number={jobApplications.length}
        //                 heading="Application Sent"
        //                 secondaryColor="#E8FFEF"
        //                 icon={<WorkIcon sx={{ color: "#2BC155", fontSize: "2.3rem" }} />}
        //               />

        //               <SmallCard
        //                 color="#3F9AE0"
        //                 heading="Total Shortlisted"
        //                 number={totalShortlisted ? totalShortlisted : 0}
        //                 secondaryColor="#E0F2FF"
        //                 icon={<BusinessCenterIcon sx={{ color: "#3F9AE0", fontSize: "2.3rem" }} />}
        //               />
        //               <SmallCard
        //                 color="#FF9B52"
        //                 number="5"
        //                 heading="Profile Viewed"
        //                 secondaryColor="#FFEFEB"
        //                 icon={<PersonIcon sx={{ color: "#FF9B52", fontSize: "2.3rem" }} />}
        //               />
        //             </Box>
        //             <Box sx={{ marginTop: "3rem" }}>
        //               <ReactApexChart
        //                 options={options}
        //                 series={series}
        //                 type="line"
        //                 height={350}
        //               />
        //             </Box>
        //             <Box sx={{ marginTop: "3rem" }}>
        //               {/* <Jobtable  /> */}
        //               <Jobtable jobApplications={jobApplications} />
        //             </Box>
        //             <Box sx={{ marginTop: "3rem" }}>
        //               <Typography variant="h4" sx={{ fontFamily: "Outfit", fontWeight: "600", m:'2rem 0rem',display:'flex' }}>
        //                 Recommended Jobs for you
        //               </Typography>

        //               {filteredJobs?.map((job) => {
        //                 return (<Jobslist job={job} handleApplyAfterEffect={(e, bool) => handleApplyAfterEffect(e, bool)} candidateSkills={candidateSkills} />)
        //               })}
        //               {/* // <Featured heading="Recommendation" /> */}
        //             </Box>

        //           </Container>
        //           <Container sx={{ width: "25%" }}>
        //             <Box>
        //               <PersonalInfo userInfo={userInfo} candidateProjects={candidateProjects} />

        //               <Activities notifications={notifications} />

        //               <Avatar
        //                 sx={{
        //                   backgroundColor: "#8743DF",
        //                   height: 56,
        //                   width: 56,
        //                   position: "fixed",
        //                   right: "30px",
        //                   bottom: "30px",
        //                   border: "10px solid white",
        //                   boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        //                   cursor: "pointer",
        //                 }}
        //               >
        //                 <QuestionMarkIcon sx={{ color: "#fff", zoom: "150%" }} />
        //               </Avatar>
        //             </Box>
        //           </Container>
        //         </Box>
        //             <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
        //                 anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        //                 <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Cant Apply to the job, Please Try Again</Alert>
        //             </Snackbar>
        //             <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
        //                 anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        //                 <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Job Applied!</Alert>
        //             </Snackbar>
        //       </div>
        //     </div>
    );
}

export default NewCandidateDashboard;
