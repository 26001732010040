import React from "react";
import NewAccordion from "components/JobAccordion/NewAccordion";

const NewFeaturedJob = ({ setJobs }) => {
  return (
    <>
      <div className="flex flex-col pt-[41px] pb-[41px] bg-[#2288FE]  text-white">
        <div className="r mb-[9px] mx-auto  text-center px-2 font-semibold text-[36px]">
          Featured Jobs
        </div>
        <div className=" text-[19px] px-3 text-center mx-auto">
          Go for your dream career & be among the top hires
        </div>
      </div>
      <div className="bg-[#EFF0F3] py-10">
        {/* <div className="center-align"> */}
        <NewAccordion setJobs={setJobs} />
        {/* </div> */}
      </div>
    </>
  );
};

export default NewFeaturedJob;
