import React from 'react'
import Navbar from 'components/Navbar'
import { useState, useEffect } from 'react'
import { Screen1 } from 'components/CompanyProfile/Screen1';
import { Screen2 } from 'components/CompanyProfile/Screen2'
import { Screen3 } from 'components/CompanyProfile/Screen3';
import { StyledNextButton, StyledSkipButton } from 'components/StyledComponents';
import {cities} from 'data/cities';
import { useParams } from "react-router-dom"
import {
  recruiterData,
  patchCompanyProfile,
  imageUpload,
  getCompanyProfile,
  fetchAdminCompanyProfile,
  patchAdminCompanyProfile,
} from 'components/Fetchers'
import { useAuth } from 'utils/auth'

import {
  Stepper, Step, StepLabel, Box, Button,
  Snackbar,
  MuiAlert,
  Typography,
  Alert,
} from 'components/MUI';
import { useNavigate } from 'react-router-dom';




const CompanyProfile = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const [companyID, setCompanyID] = useState("")
  // get params from url
  const { id } = useParams()

  console.log(id)
  useEffect(() => {
    if (id) {
      fetchAdminCompanyProfile(id).then((data) => {
        console.log(data)
        // setCompanyDetails(data)
        payloadToCompanyDetails(data)
      })

      // getCompanyProfile(id).then((data) => {
      //   console.log(data)
      //   // setCompanyDetails(data)
      //   payloadToCompanyDetails(data)
      // })

    }
  }, [id])
  const payloadToCompanyDetails= (payload) => {
    setCompanyDetails({
      companyName: payload.companyName,
      companyEmail: payload.email,
      phone: payload.phone,
      socialMediaLinks:{
        facebook: payload.socialNetwork.facebook,
        twitter: payload.socialNetwork.twitter,
        linkedin: payload.socialNetwork.linkedIn,
      },
        address: payload.address.address1,
        city: payload.address.city,
        state: payload.address.state,
        pinCode: payload.address.pincode,
      aboutCompany: payload.about,
      companyWebsite:payload.aboutCompany.website,
      companyType: payload.aboutCompany.type,
      companyStage: payload.aboutCompany.stage,
      teamSize: payload.aboutCompany.teamSize,
      establishedYear: payload.aboutCompany.estSince,
      companyVideo: payload.aboutCompany.video,
    })
    console.log(payload.aboutCompany.stage)
  }




    
  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    phone: "",
    companyPhoto: "",
    companyEmail: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    companyWebsite: "",
    companyType: "",
    companyStage: "",
    teamSize: "",
    establishedYear: "",
    companyVideo: "",
    aboutCompany: "",
    socialMediaLinks: {
      facebook: "",
      twitter: "",
      linkedin: "",
    }
  })
  const autoCompleteData = {
    companyType: ["Startup", "SME", "Large Enterprise"],
    companyStage: ["Seed", "Early", "Growth", "Expansion", "Mature"],
    cities: cities,
    states: ["Maharashtra", "Gujarat", "Karnataka", "Tamil Nadu", "Delhi", "Telangana", "West Bengal", "Other"],
    establishedYear: ['1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', ' 2018', '2019', '2020', '2021', '2022', '2023'],
  }

  const payLoadConverter = (companyDetails) => {
    const { companyName, phone, companyPhoto, companyEmail, address, city, state, pinCode, companyWebsite, companyType, companyStage, teamSize, establishedYear, companyVideo, aboutCompany, socialMediaLinks } = companyDetails
    const payload = {
      companyName: companyName,
      email: companyEmail,
      phone: phone,
      photo: companyPhoto,
      address: {
        address1: address,
        city: city,
        state: state,
        pincode: pinCode
      },
      aboutCompany: {
        website: companyWebsite,
        type: companyType,
        stage: companyStage,
        teamSize: teamSize,
        estSince: establishedYear,
        video: companyVideo
      },
      socialNetwork: {
        facebook: socialMediaLinks.facebook,
        twitter: socialMediaLinks.twitter,
        linkedIn: socialMediaLinks.linkedin
      },
      about: aboutCompany
    }
    return payload
  }
  useEffect(() => {
    recruiterData().then((data) => {
      setCompanyID(data.company._id)
    })
  }, [])
console.log()

  const handleSubmit = () => {
    const payload = payLoadConverter(companyDetails)
    console.log(payload)

    if(auth.userType==="admin") {
      console.log("user is admin")
      patchAdminCompanyProfile(id,payload).then((data) => {
        console.log(data)
        // setSuccessOpen(true);
        onSuccess()
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 3500);
      }).catch((err) => {
        console.log(err)
        onError()
      })
    } else {
      console.log("user is recruiter")
      patchCompanyProfile(companyID, payload).then((data) => {
        console.log(data)
        // setSuccessOpen(true);
        onSuccess()
        setTimeout(() => {
          navigate("/recruiter/dashboard");
        }, 3500);
      }).catch((err) => {
        console.log(err)
        onError()
      })
    }
    
   
  }




  const handleNumber = (value) => {
    return value.replace(/\D/g, '');
  };


  const [activeStep, setActiveStep] = useState(0);

  const handleChange = (e) => {
    let { name, value } = e.target
    if (name === "phone" || name === "pinCode" || name === "teamSize") {
      value = handleNumber(value)
    }
    setCompanyDetails({
      ...companyDetails,
      [name]: value
    })
    console.log(companyDetails)
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);


  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };




  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };



  const renderSwitchScreens = (param) => {

    switch (param) {
      case 0:
        return <Screen1 companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} handleChange={handleChange} autoCompleteData={autoCompleteData} companyID={companyID} token={auth.token} />
      case 1:
        return <Screen2 companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} handleChange={handleChange} autoCompleteData={autoCompleteData} />
      case 2:
        return <Screen3 companyDetails={companyDetails} setCompanyDetails={setCompanyDetails} handleChange={handleChange} autoCompleteData={autoCompleteData} />
      default:
        (<></>)
    }
  }

  const handleFirstNext = () => {
    if (companyDetails.companyName === "" ||
      companyDetails.phone === "" || companyDetails.companyEmail === "" ||
      companyDetails.address === "") {
      alert("Please fill all the required fields")
    }
    else {
      handleNext()
    }
  }

  const handleSecondNext = () => {
    // console.log("companyDetails.companyWebsite", companyDetails.companyWebsite)
    // console.log("companyDetails.companyType", companyDetails.companyType)
    // console.log("companyDetails.companyStage", companyDetails.companyStage)
    // console.log("companyDetails.teamSize", companyDetails.teamSize)
    // console.log("companyDetails.establishedYear", companyDetails.establishedYear)
    // console.log("companyDetails.companyVideo", companyDetails.companyVideo)

       const { companyWebsite, companyType, teamSize, establishedYear, companyVideo } = companyDetails;
    if (companyWebsite === "" || companyType === "" || teamSize === "" || establishedYear === "" || companyVideo === "") {
      alert("Please fill all the required fields");
    }
    else {
      handleNext()
    }
  }

  const handleThirdNext = () => {
    if (companyDetails.aboutCompany === "" ||
      companyDetails.socialMediaLinks.linkedin === "") {
      alert("Please fill all the required fields")
    }
    else {
      handleSubmit()
    }
  }



  const renderSwitchButtons = (param) => {
    switch (param) {
      case 0:
        return (<>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <StyledNextButton onClick={handleFirstNext}>Next</StyledNextButton>
          </Box></>)
      case 1:
        return (<>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledNextButton onClick={handleBack}>Back</StyledNextButton>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}>

              <StyledNextButton onClick={handleSecondNext}>Next</StyledNextButton>
            </Box>
          </Box>
        </>)
      case 2:
        return (<>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledNextButton onClick={handleBack}>Back</StyledNextButton>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}>

              <StyledNextButton onClick={handleThirdNext}>Save</StyledNextButton>
            </Box>
          </Box></>)
      default:
        (<></>)
    }
  }

  const handleAlert = () => {
    onError()
  }



  return (<>
    <div>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Cant Submit the Company Details Try Again</Alert>
      </Snackbar>
      <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Company Details is Updated</Alert>
      </Snackbar>
    </div>
    <Navbar />
    <Box>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "3rem",
          borderRadius: 5,
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
          maxWidth: "lg",
        }}

      >

        <Box
          sx={{
            width: "70%",
            margin: "3rem 0rem 1rem 0rem",
          }}
        >
          <Stepper
            activeStep={activeStep}
            sx={{
              "& .MuiStepIcon-active": { color: "red" },
              "& .MuiStepIcon-completed": { color: "green" },
              "& .Mui-disabled .MuiStepIcon-root": {
                color: "#043047",
              },
            }}
            alternativeLabel
          >
            <Step >

              <StepLabel>
                <Typography variant="h6" sx={{ textAlign: "center", fontFamily: 'Outfit', marginBottom: '0.5rem' }}>
                  General Information
                </Typography>
              </StepLabel>

            </Step>
            <Step>

              <StepLabel>
                <Typography variant="h6" sx={{ textAlign: "center", fontFamily: 'Outfit', marginBottom: '0.5rem' }}>
                  About Company
                </Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                <Typography variant="h6" sx={{ textAlign: "center", fontFamily: 'Outfit', marginBottom: '0.5rem' }}>
                  More Information
                </Typography>

              </StepLabel>
            </Step>

          </Stepper>
        </Box>

        <Box sx={{ width: "90%" }}>

          {renderSwitchScreens(activeStep)}


          {renderSwitchButtons(activeStep)}
          {/* <button onClick={handleAlert}>submit</button> */}
        </Box>


      </Box>

    </Box>
  </>
  )
}

export default CompanyProfile