import React from 'react';
import { percentageCalculator } from 'utils/job';

const PercentageMatch = ({ big, candidateSkills, job }) => {
  if (!candidateSkills && !job) return null;
  const percent = percentageCalculator(job, candidateSkills);
  if (!percent) return null;
  if (big) {
    return (
      <>
        <div className="bg-mediumblue rounded w-max text-xs sm:text-base leading-5 p-1 sm:py-2.5 sm:px-3">
          <div className="text-primarycolor">{percent}% Skills Matched</div>
        </div>
      </>
    );
  } else
    return (
      <>
        <div className="bg-mediumblue rounded w-max text-xs sm:text-base leading-[15px] p-1.5 sm:py-2.5 sm:px-2.5">
          <div className="text-primarycolor text-xs">
            {percent}% Skills Matched
          </div>
        </div>
      </>
    );
};

export default PercentageMatch;
