import React from 'react';

const ValueBox = ({ value, matched }) => {
  if (!matched)
    return (
      <div
        className=" bg-white py-1.5 px-[23px] text-sm max-w-fit "
        style={{
          border: '0.1px solid rgb(210, 210, 210)',
          borderRadius: '4px',
        }}
      >
        {value}
      </div>
    );
  else
    return (
      <div
        className=" bg-green-300 py-1.5 px-[23px] text-sm max-w-fit "
        style={{
          border: '0.1px solid rgb(210, 210, 210)',
          borderRadius: '4px',
        }}
      >
        {value}
      </div>
    );
};

const SkillsRequiredSection = (props) => {
  const { skills, data, candidateSkills, mustHaveSkills, goodToHaveSkills } =
    props;
  // console.log(candidateSkills);
  if (data.requiredExperience) {
    var ExperienceInString =
      data.requiredExperience > 1
        ? String(data.requiredExperience) + ' Years'
        : String(data.requiredExperience) + '+ Year';
  }
  return (
    <>
      <div>
        <div className="flex gap-4 flex-col spacePadding">
          <div className="flex smColsmdRow gap-3">
            <div className="md:self-center text-base whitespace-nowrap">
              Must have skills:
            </div>
            <div className="flex gap-2 flex-wrap break-words">
              {mustHaveSkills.map((skill, index) => {
                // check if skill is exist in candidateSkills

                if (
                  candidateSkills &&
                  candidateSkills.includes(skill.toLowerCase())
                ) {
                  return <ValueBox key={index} value={skill} matched />;
                } else return <ValueBox key={index} value={skill} />;
              })}
            </div>
          </div>
          <div className="flex smColsmdRow gap-3">
            <div className="md:self-center text-base whitespace-nowrap">
              Good to have skills:
            </div>
            <div className="flex gap-2 flex-wrap break-words">
              {goodToHaveSkills.map((skill, index) => {
                if (
                  candidateSkills &&
                  candidateSkills.includes(skill.toLowerCase())
                ) {
                  return <ValueBox key={index} value={skill} matched />;
                } else return <ValueBox key={index} value={skill} />;
              })}
            </div>
          </div>
          <div>
            {data.requiredExperience && (
              <div className="flex smColsmdRow gap-2">
                <div className="md:self-center text-base">
                  Experience :
                </div>
                <ValueBox value={ExperienceInString} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillsRequiredSection;
