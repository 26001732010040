import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Filters from "components/Jobboard/Filters";
import FreeSoloFilter from "components/Jobboard/FreeSoloFilter";
import Filter from "components/Jobboard/Filter";
import Jobslist from "components/Jobboard/Jobslist";
import "components/Jobboard/style.css";
import Pagination from "@mui/material/Pagination";
import Navbar from "components/Navbar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import Box from "@mui/material/Box";
import Multifilter from "components/Jobboard/Multifilter";
import { arrayMove } from "@dnd-kit/sortable";
// import { getUnappliedJobs, publicJobPosts,fetchCandidateData ,getRecommendedJobs } from 'components/Fetchers/candidate';
import {
  getUnappliedJobs,
  publicJobPosts,
  fetchCandidateData,
  getRecommendedJobs,
} from "components/Fetchers";
import { useAuth } from "utils/auth";
import { useSearchParams } from "react-router-dom";

// The default value of location.state is null if you don't pass any data.
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Jobboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const [showJobs, setShowJobs] = useState([]);
  const jobtype = ["Internship", "Fulltime", "Parttime", "Freelancer"];
  const exp = ["1", "2", "3", "4", "5+"];
  const careerlevel = ["Starter", "Expert"];
  const skills = [
    "HTML",
    "CSS",
    "Java",
    "C++",
    "Python",
    "Reactjs",
    "Redux",
    "UI/UX",
  ];
  const [jobtypeFilter, setJobtypeFilter] = useState({
    jobTitle: [],
    jobLocation: [],
    jobCategory: [],
    jobSkills: [],
    jobType: [],
  });
  const [candidateData, setCandidateData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobTypeSearch, setJobTypeSearch] = useState({
    jobTitle: "",
    jobLocation: "",
    jobCategory: "",
    jobType: "",
  });
  const getJobCategoryFromList = (list) => {
    const jobCategory = list.map((job) => job.category);
    const jobCategorySet = new Set(jobCategory);
    const jobCategoryArray = Array.from(jobCategorySet);
    return jobCategoryArray;
  };

  const getJobTitleFromList = (list) => {
    const jobTitle = list.map((job) => job.title);
    const jobTitleSet = new Set(jobTitle);
    const jobTitleArray = Array.from(jobTitleSet);
    return jobTitleArray;
  };

  const getJobLocationFromList = (list) => {
    const jobLocation = list.map((job) => job.location);
    const jobLocationSet = new Set(jobLocation);
    const jobLocationArray = Array.from(jobLocationSet);
    return jobLocationArray;
  };

  const getJobTypeFromList = (list) => {
    const jobType = list.map((job) => job.type);
    const jobTypeSet = new Set(jobType);
    const jobTypeArray = Array.from(jobTypeSet);
    return jobTypeArray;
  };
  const getSkillsFromList = (list) => {
    //get items from array of objects

    const mustHavejobSkills = list.map((job) => job.mustHaveSkills);
    const goodToHavejobSkills = list.map((job) => job.goodToHaveSkills);
    // get array items in array
    const mustHavejobSkillsArray = mustHavejobSkills.flat().flat();
    const goodToHavejobSkillsArray = goodToHavejobSkills.flat().flat();

    // console.log(mustHavejobSkills)
    const jobSkills = mustHavejobSkillsArray.concat(goodToHavejobSkillsArray);
    console.log(jobSkills);
    const jobSkillsSet = new Set(jobSkills);
    console.log(jobSkillsSet);
    const jobSkillsArray = Array.from(jobSkillsSet);
    return jobSkillsArray;
  };

  const handleParamSearch = (job) => {
    const jobSkillsArray = jobSkillsGetter(job);
    console.log(jobSkillsArray);
    const jobTitleArray = jobTitleGetter(jobSkillsArray);
    const jobLocationArray = jobLocationGetter(jobTitleArray);
    const jobCategoryArray = jobCategoryGetter(jobLocationArray);
    const jobTypeArray = jobTypeGetter(jobCategoryArray);
    console.log(jobTypeArray);
    setFilteredJobs(jobTypeArray);
  };

  const handleSearch = () => {
    // e.preventDefault()
    // console.log(showJobs)
    console.log("jobTypeSearch");
    console.log(jobTypeSearch);
    // console.log(jobTypeSearch)
    console.log(showJobs);
    const jobSkillsArray = jobSkillsGetter(showJobs);
    console.log(jobSkillsArray);
    const jobTitleArray = jobTitleGetter(jobSkillsArray);
    const jobLocationArray = jobLocationGetter(jobTitleArray);
    const jobCategoryArray = jobCategoryGetter(jobLocationArray);
    const jobTypeArray = jobTypeGetter(jobCategoryArray);
    console.log(jobTypeArray);
    setFilteredJobs(jobTypeArray);
  };

  const paramSearcher = (searchParams, job) => {
    if (searchParams.get("title")) {
      jobTypeSearch.jobTitle = [searchParams.get("title")];
    }
    if (searchParams.get("location")) {
      jobTypeSearch.jobLocation = [searchParams.get("location")];
    }
    if (searchParams.get("type")) {
      jobTypeSearch.jobType = [searchParams.get("type")];
    }
    if (searchParams.get("category")) {
      jobTypeSearch.jobCategory = [searchParams.get("category")];
    }
    // console.log("Searching")
    if (
      searchParams.get("title") ||
      searchParams.get("location") ||
      searchParams.get("type") ||
      searchParams.get("category")
    ) {
      console.log("searching from param");
      handleParamSearch(job);
    }
  };

  const jobSkillsGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      console.log(job);
      if (
        jobTypeSearch.jobSkills == "" ||
        jobTypeSearch.jobSkills == undefined
      ) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobSkills.map((skill) => {
          if (
            job.mustHaveSkills.includes(skill) ||
            job.goodToHaveSkills.includes(skill)
          ) {
            console.log(job);
            arrayOfJobs.push(job);
          }
        });
      }
    });
    console.log(arrayOfJobs);
    return arrayOfJobs;
  };
  const jobTitleGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (jobTypeSearch.jobTitle == "" || jobTypeSearch.jobTitle == undefined) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobTitle.map((title) => {
          if (job.title.toLowerCase().includes(title.toLowerCase())) {
            // console.log(job)
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };
  const jobLocationGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (
        jobTypeSearch.jobLocation == "" ||
        jobTypeSearch.jobLocation == undefined
      ) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobLocation.map((location) => {
          if (job.location.toLowerCase().includes(location.toLowerCase())) {
            // console.log(job)
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };
  const jobTypeGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (jobTypeSearch.jobType == "" || jobTypeSearch.jobType == undefined) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobType.map((type) => {
          if (job.type.toLowerCase().includes(type.toLowerCase())) {
            // console.log(job)
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };
  const jobCategoryGetter = (job) => {
    let arrayOfJobs = [];
    job.forEach((job) => {
      if (
        jobTypeSearch.jobCategory == "" ||
        jobTypeSearch.jobCategory == undefined
      ) {
        arrayOfJobs.push(job);
      } else {
        jobTypeSearch.jobCategory.map((category) => {
          if (job.category.toLowerCase().includes(category.toLowerCase())) {
            // console.log(job)
            arrayOfJobs.push(job);
          }
        });
      }
    });
    return arrayOfJobs;
  };

  const jobSetter = (job) => {
    setShowJobs(job);
    setJobtypeFilter({
      jobTitle: getJobTitleFromList(job),
      jobLocation: getJobLocationFromList(job),
      jobCategory: getJobCategoryFromList(job),
      jobSkills: getSkillsFromList(job),
      jobType: getJobTypeFromList(job),
    });
    setFilteredJobs(job);
  };

  // let params = paramCalcultor(searchParams)
  // consol
  useEffect(() => {
    if (
      searchParams.get("title") ||
      searchParams.get("location") ||
      searchParams.get("type") ||
      searchParams.get("category")
    ) {
      publicJobPosts().then((res) => {
        jobSetter(res.reverse());
        // console.log(res)
        paramSearcher(searchParams, res);
      });
    } else {
      if (auth.userType === "candidate") {
        getUnappliedJobs().then((res) => {
          jobSetter(res.reverse());
        });

        // getRecommendedJobs().then((res) => {
        //     console.log('RECOMMENDED JOBS')
        //     jobSetter(res)
        // })

        // console.log('candidate')
        fetchCandidateData(auth.userId).then((res) => {
          // console.log('CANDIDATE DATA')
          // console.log(res.data.result.skills)

          setCandidateData(res.data.result);
        });
      } else {
        publicJobPosts().then((res) => {
          jobSetter(res.reverse());
        });
      }
    }
  }, []);

  //     axios.get(`${process.env.REACT_APP_API__URL}/api/unappliedJobs`)
  //         .then((res) => {
  //             console.log(res.data.result)
  //             if (res.data.result) {
  //                 setShowJobs(res.data.result)
  //             }
  //             setShowJobs(res.data.result)
  //             setJobtypeFilter({
  //                 jobTitle:getJobTitleFromList(res.data.result),
  //                 jobLocation:getJobLocationFromList(res.data.result),
  //                 jobCategory:getJobCategoryFromList(res.data.result),
  //                 jobSkills:getSkillsFromList(res.data.result),
  //                 jobType:getJobTypeFromList(res.data.result)
  //             })

  //             setFilteredJobs(res.data.result)
  //         })
  //         .catch((err) => {

  //             console.log(err)
  //             navigate('/login')
  //         })
  // },[])

  const handleApplyAfterEffect = (id, bool) => {
    if (bool) {
      const newJobs = showJobs.filter((job) => job._id !== id);
      setFilteredJobs(newJobs);
      onSuccess();
    } else {
      if (auth.userType === "candidate") {
        onError();
      } else {
        onErrorPublic();
      }
    }
  };

  // const newJobs = showJobs.filter((job) => job._id !== id)
  // setShowJobs(newJobs)
  // onSuccess()

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorOpenPublic, setErrorOpenPublic] = useState(false);

  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };
  const onErrorPublic = () => {
    setErrorOpenPublic(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
    setErrorOpen(false);
    setErrorOpenPublic(false);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />

      {/* <Filters /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          margin: "auto",
          maxWidth: "xl",
        }}
      >
        <Box
          className="UpperFilters"
          sx={{ display: "flex", margin: "0px 50px" }}
        >
          {/* <FreeSoloFilter optionList={jobtypeFilter.jobTitle} handleChange={(value)=>{
                setJobTypeSearch({
                    ...jobTypeSearch,
                    jobTitle:value
                })
                console.log(jobTypeSearch)
            } } 
             wid="25%" label="Job Title" size="medium" sticker={<SearchIcon />} color="#F5F5F5" heading="Search by Keywords" /> */}
          <Multifilter
            optionList={jobtypeFilter.jobTitle}
            value={jobTypeSearch.jobTitle ? jobTypeSearch.jobTitle : []}
            handleChange={(value) => {
              setJobTypeSearch({
                ...jobTypeSearch,
                jobTitle: value,
              });
              console.log(jobTypeSearch);
            }}
            wid="85%"
            label="Title"
            sticker={<SearchIcon />}
            size="medium"
            color="#F5F5F5"
            heading="Job Title"
          />

          <Multifilter
            optionList={jobtypeFilter.jobLocation}
            value={jobTypeSearch.jobLocation ? jobTypeSearch.jobLocation : []}
            handleChange={(value) => {
              setJobTypeSearch({
                ...jobTypeSearch,
                jobLocation: value,
              });
              console.log(jobTypeSearch);
            }}
            wid="85%"
            label="City"
            sticker={<LocationOnOutlinedIcon />}
            size="medium"
            color="#F5F5F5"
            heading="Location"
          />

          <Multifilter
            optionList={jobtypeFilter.jobCategory}
            value={jobTypeSearch.jobCategory ? jobTypeSearch.jobCategory : []}
            handleChange={(value) => {
              setJobTypeSearch({
                ...jobTypeSearch,
                jobCategory: value,
              });
              console.log(jobTypeSearch);
            }}
            wid="85%"
            label="Categories"
            sticker={<WorkOutlinedIcon />}
            size="medium"
            color="#F5F5F5"
            heading="Categories"
          />
          {/* <FreeSoloFilter optionList={jobtypeFilter.jobLocation} handleChange={(value) => {
                    setJobTypeSearch({
                        ...jobTypeSearch,
                        jobLocation: value
                    })
                }} wid="25%" label="City Or Pincode" size="medium" sticker={<LocationOnOutlinedIcon />} color="#F5F5F5" heading="Location" /> */}
          {/* <Filter optionList={jobtypeFilter.jobCategory} handleChange={(value) => {
                    setJobTypeSearch({
                        ...jobTypeSearch,
                        jobCategory: value
                    })
                }} wid="25%" label="Categories" size="medium" sticker={<WorkOutlinedIcon />} color="#F5F5F5" heading="Category" /> */}
          <button className="filters__search" onClick={handleSearch}>
            Search
          </button>
        </Box>
        {/* <TopFilters /> */}

        <div style={{ display: "flex", margin: "70px 80px" }}>
          {/* Jobs List Section */}
          <div style={{ width: "120%" }}>
            <div style={{ display: "flex" }}>
              <p className="joblist__h">
                Showing {filteredJobs.length} results
              </p>
              {/* <p className='joblist__h'>Showing 1 – 10 of {filteredJobs.length} results</p> */}

              <div style={{ display: "flex", marginLeft: "auto" }}>
                <p className="jobboard__sortby">Sort By</p>
                <p className="jobboard__sortby">10 Per Page</p>
              </div>
            </div>

            {filteredJobs?.map((job) => {
              return (
                <Jobslist
                  job={job}
                  handleApplyAfterEffect={(e, bool) =>
                    handleApplyAfterEffect(e, bool)
                  }
                  candidateSkills={candidateData.skills}
                  candidateData={candidateData}
                />
              );
            })}

            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <Pagination count={5} color="primary" shape="circle" />
                    </div> */}
          </div>

          {/* Filter Section */}
          <div style={{ width: "60%", marginLeft: "43px" }}>
            <p className="jobboard__filterheading">Filter</p>
            <Multifilter
              optionList={jobtypeFilter.jobType}
              value={jobTypeSearch.jobType ? jobTypeSearch.jobType : []}
              handleChange={(value) => {
                setJobTypeSearch({
                  ...jobTypeSearch,
                  jobType: value,
                });
                console.log(jobTypeSearch);
              }}
              wid="85%"
              label="Type"
              size="medium"
              color="#F5F5F5"
              heading="Job Type"
            />
            {/* <Filter optionList={jobtypeFilter.jobType}
                        handleChange={(value) => {
                            setJobTypeSearch({
                                ...jobTypeSearch,
                                jobType: value
                            })
                        }}
                        wid="85%" label="Job Type" size="medium" color="#F5F5F5" heading="Job Type" /> */}
            {/* <Filter optionList={exp} wid="85%" label="Years" size="medium" color="#F5F5F5" heading="Experience" />
                    <Filter optionList={careerlevel} wid="85%" label="Starter" size="medium" color="#F5F5F5" heading="Career Level" /> */}

            {/* <div>
                        <p className='jobboard__search'>Most Searched</p>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <p className='search__fields'>Java</p>
                            <p className='search__fields'>Data Scientist</p>
                            <p className='search__fields'>Developer</p>
                            <p className='search__fields'>UI/UX Designer</p>
                            <p className='search__fields'>Frontend Developer</p>
                        </div>
                    </div> */}

            <Multifilter
              optionList={jobtypeFilter.jobSkills}
              value={jobTypeSearch.jobSkills ? jobTypeSearch.jobSkills : []}
              handleChange={(value) => {
                setJobTypeSearch({
                  ...jobTypeSearch,
                  jobSkills: value,
                });
                console.log(jobTypeSearch);
              }}
              wid="85%"
              label="Skills"
              size="medium"
              color="#F5F5F5"
              heading="Skills"
            />
            <div>
              {/* {jobTypeSearch?.jobSkills?.length > 0 && (<>
                            <p className='jobboard__search'>Selected</p>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {jobTypeSearch.jobSkills.map((skill) => {
                                    return (<p className='search__fields'>{skill}</p>)
                                })}

                            </div>
                        </>)} */}
            </div>
          </div>
        </div>
      </Box>
      <Snackbar
        open={errorOpenPublic}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Please Sign Up
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Cant Apply to the job, Please Try Again
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="success">
          Job Applied!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Jobboard;
