import React from "react";
import { Link } from "react-router-dom";
import rightAction from "../../images/components/rightAction.svg";
import leftAction from "../../images/components/leftAction.svg";

import { useState } from "react";
import NewSignup from "pages/auth/NewSignup";
import NewLogin from "pages/auth/NewLogin";

const imageLinks = {
  first: leftAction,
  second: rightAction,
};

const NewBottomSection = () => {
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const handleSignupClick = () => {
    setShowSignupPopup(true);
  };

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const handleLoginClick = () => {
    setShowLoginPopup(true);
  };

  return (
    <>
      <div className="center-align px-5 overflow-hidden">
        <div className="flex flex-col gap-5 md:gap-0   md:mt-[89px] mb-[77px]">
          <div className="flex flex-col md:flex-row gap-5">
            <img
              className="xs:w-80 sm:w-96 md:w-[400px]  lg:w-[460px]  xl:w-[572px]  object-contain "
              alt=""
              src={imageLinks.first}
            />

            <div className="text md:w-1/2 gap-4 mr-0 xl:mr-20  flex flex-col md:text-start my-auto align-middle">
              <div className="text-4xl sm:text-[48px] sm:leading-[58px] xs:w-[403px]  font-bold ">
                Create an edgy profile which recruiters love
              </div>
              <div className="text-base xs:w-[334px]">
                An edgy & smart online presence is today’s resume. Use our
                profile section to check your growth in skills, flaunt your
                badges, achievements and make a trendy resume.
              </div>
              {/* <Link to="/signup"> */}
              <div>
                <button onClick={handleSignupClick} className="blueFatButton">Create Profile</button>
                </div>
              {/* </Link> */} 
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-end gap-5">
            <div className="grow text md:w-1/2 ml-0 gap-5 flex flex-col xl:ml-20 md:items-end my-auto align-middle">
              <div className="gap-5 flex flex-col">
                <div className="text-4xl sm:text-[48px] font-bold xs:w-[366px] sm:leading-[58px]">
                  Make a move to explore latest opportunities
                </div>
                <div className="text-base xs:w-[333px]">
                  Explore the trendy, featured jobs and opportunities of the
                  tech world. Our AI-powered tools and cutting edge technology
                  will make sure everything is in your favour.
                </div>
                <Link to="/jobboard">
                  <button className="blueFatButton">Explore Jobs</button>
                </Link>
              </div>
            </div>
            <img
              className="xs:w-80 sm:w-96 md:w-[400px]  lg:w-[460px]  xl:w-[572px] object-contain "
              alt=""
              src={imageLinks.second}
            />
          </div>
        </div>
      </div>
      {showSignupPopup && <NewSignup setShowSignupPopup={setShowSignupPopup} setShowLoginPopup={setShowLoginPopup}/>}
      {showLoginPopup && <NewLogin setShowLoginPopup={setShowLoginPopup} setShowSignupPopup={setShowSignupPopup}/>}

    </>
  );
};

export default NewBottomSection;
