import React, { useEffect, useState } from "react";
import Timel from "components/RecruiterProfile/TimeL";
import "./style.css";
import {
  Avatar,
  Box,
  Snackbar,
  MuiAlert,
  TextField,
  Alert
} from "components/MUI";
import Navbar from "components/Navbar";
import { StyledBlackButton } from 'components/StyledComponents';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'utils/auth';
import {
  recruiterDataReactQuery,
  imageUpload,
  patchCompanyProfile,
  recruiterData,
  recruiterJobPosts,
  recruiterNotifications,
  recruiterJobApplications
} from 'components/Fetchers';



function RecruiterProfile() {
  const hiddenFileInput = React.useRef(null);
  const auth = useAuth()

  const [userInfo, setUserInfo] = useState({});
  const [oldImage, setOldImage] = useState("");
  const [partOneEdit, setPartOneEdit] = useState(false);
  const [partTwoEdit, setPartTwoEdit] = useState(false);
  const [showJobPosts, setShowJobPosts] = useState([]);
  const { data, isLoading, error, status } = useQuery(['recruiterData'], recruiterDataReactQuery);
  useEffect(() => {
    if (data) {
      dataFill(data.data.result);
      console.log('setting up old image')
      setOldImage(data.data.result.company.photo)
    }
  }, [data]);



  useEffect(() => {
    recruiterJobPosts().then((data) => {
      setShowJobPosts(data);
    }).catch((err) => {
      console.log(err);
    });
  }, []);


  const dataFill = (value) => {
    console.log(value)
    const data = value?.company
    setUserInfo({
      companyId: value?.company._id,
      name: value?.name,
      about: data?.about,
      companyName: data?.companyName,
      email: data?.email,
      location: data?.location,
      phone: data?.phone,
      photo: data?.photo,
      aboutCompany: {
        estSince: data?.aboutCompany?.estSince,
        website: data?.aboutCompany?.website,
        stage: data?.aboutCompany?.stage,
        teamSize: data?.aboutCompany?.teamSize,
        type: data?.aboutCompany?.type,
        // stage: data?.aboutCompany.stage,
        video: data?.aboutCompany?.video,
      },
      address: {
        address1: data?.address?.address1,
        city: data?.address?.city,
        state: data?.address?.state,
        pincode: data?.address?.pincode,
      },
      socialNetwork: {
        facebook: data?.socialNetwork?.facebook,
        linkedIn: data?.socialNetwork?.linkedIn,
        twitter: data?.socialNetwork?.twitter,
      },
      typeofindustry: data?.typeofindustry,
    });
  };


  const payLoadConverter = (userInfo) => {

    const payload = {
      companyName: userInfo.companyName,
      email: userInfo.email,
      phone: userInfo.phone,
      photo: userInfo.photo,
      about: userInfo.about,
      address: {
        address1: userInfo.address.address1,
        city: userInfo.address.city,
        state: userInfo.address.state,
        pincode: userInfo.address.pincode
      },
      aboutCompany: {
        website: userInfo.aboutCompany.website,
        type: userInfo.aboutCompany.type,
        stage: userInfo.aboutCompany.stage,
        teamSize: userInfo.aboutCompany.teamSize,
        estSince: userInfo.aboutCompany.estSince,
        video: userInfo.aboutCompany.video
      },
      socialNetwork: {
        facebook: userInfo.socialNetwork.facebook,
        twitter: userInfo.socialNetwork.twitter,
        linkedIn: userInfo.socialNetwork.linkedIn
      },

    }
    return payload
  }


  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);


  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };


  const handleNumber = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };

  const handleImageUploadClick = event => {
    hiddenFileInput.current.click();
  };
  const uploadFileHandler = async (e) => {
    console.log('uploading file')
    // console.log(userInfo.photo)
    // console.log(oldImage)
    imageUpload(e.target.files[0], userInfo.companyId, auth.token).then((res) => {
      // console.log(res)
      console.log('uploaded file')
      // setUserInfo({
      //   ...userInfo,
      //   photo: res
      // })
      window.location.reload();
    }).catch((e) => {
      onError()
      console.log(e)
    })
  };

  const handleSubmit = () => {
    if (partOneEdit || partTwoEdit) {
      alert('please save the changes first')
      return
    }

    const payload = payLoadConverter(userInfo)
    console.log(payload)
    patchCompanyProfile(userInfo.companyId, payload).then((data) => {
      console.log(data)
      // setSuccessOpen(true);
      onSuccess()
      // setTimeout(() => {
      //   navigate("/recruiter/dashboard");
      // }, 3500);
    }).catch((err) => {
      console.log(err)
      onError()
    }
    )
  }

  return (
    <> <Navbar />
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "auto",
   
      maxWidth: "xl",
      display: "flex",
    }}
    >
      <Box   sx={{ mx: "2rem"}}>
      <div className="recruiterprofile" 
      
       >
        

        <div style={{ padding: "1rem" }}>
          <div style={{ display: "flex" }}>
            <p className="rp__profile">Recruiter Profile</p>
            {/* <p className="rp__saveChanges">Save Changes</p> */}


            <StyledBlackButton onClick={handleSubmit}>Save Changes</StyledBlackButton>

          </div>

          <div className="rp__details">
            <div className="recruiterprofile__leftsection">
              <div className="ls__box">
                <Avatar
                  className="ls__avatar"
                  img='images/avtar.png'
                />
                <p className="ls__boxName">{userInfo?.name}</p>
              </div>

              <div className="ls__box">
                <p className="ls__boxheading">Job Posted</p>
                <Timel jobs={showJobPosts}/>
              </div>
            </div>

            <div className="recruiterprofile__rightsection">
              <div className="ls__box" style={{ padding: "1rem 3rem" }}>
                <div style={{ display: "flex" }}>
                  <p className="rs__heading">About Company</p>
                

                  {partOneEdit ? (<><StyledBlackButton onClick={() => setPartOneEdit(!partOneEdit)}>Save Edit</StyledBlackButton>
                  </>) : (<><StyledBlackButton onClick={() => setPartOneEdit(!partOneEdit)}>Edit</StyledBlackButton></>)}


                </div>

                <Box>
                  {partOneEdit ? (<>
                    {/* key={userInfo.photo} */}
                    <div style={{ display: "flex", gap: '4rem', justifyContent: 'space-between' }}>
                      <img src={userInfo.photo} style={{ height: "200px", width: "200px" }}></img>
                      {/* <img src={userInfo.photo} alt="" style={{ height: "200px", width: "200px" }} ></img> */}
                      <div style={{ marginTop: "auto" }}>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={(e) => uploadFileHandler(e)}
                          style={{ display: 'none', }}
                        />
                        <StyledBlackButton
                          onClick={handleImageUploadClick}
                        >
                          {(userInfo?.photo == oldImage) ? "Change Photo" : "Image uploaded"}
                        </StyledBlackButton> </div>



                    </div>



                  </>) : (<>


                    <div style={{ display: "flex", gap: '4rem', justifyContent: 'space-between' }}>
                      <img src={userInfo?.photo} style={{ height: "200px", width: "200px" }} ></img>


                    </div>


                  </>)}

                </Box>


                <div style={{marginTop:'2rem'}}>
                  <p
                    className="rs__desc"
                    style={{ display: "inline", paddingBottom: "0.2rem" }}
                  >
                    Company Description
                  </p>
                  <Box>
                    {partOneEdit ? (<>

                      <TextField variant="standard" value={userInfo?.about}
                        multiline
                        rows={4}
                        sx={{ width: "550px", mt: "1rem" }}
                        onChange={(e) => setUserInfo({ ...userInfo, about: e.target.value })}
                      />

                    </>) : (<>

                      <p className="rs__descDetails">
                        {userInfo?.about}
                      </p>
                    </>)}

                  </Box>

                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", marginRight: "2rem" }}>
                    <p className="rs__heading1">Company Name</p>

                    <Box>
                      {partOneEdit ? (<><div>
                        <TextField variant="standard" value={userInfo?.companyName}
                          onChange={(e) => setUserInfo({ ...userInfo, companyName: e.target.value })}
                        />
                      </div></>) : (<>
                        <p className="rs__headingDetails">{userInfo?.companyName}</p>
                      </>)}

                    </Box>


                  </div>
                  <div style={{ width: "50%" }}>
                    <p className="rs__heading1">Company Email</p>
                    <Box>
                      {partOneEdit ? (<>

                        <TextField variant="standard" value={userInfo?.email}
                          onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                        />


                      </>) : (<>

                        <p className="rs__headingDetails">{userInfo?.email}</p>
                      </>)}

                    </Box>

                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", marginRight: "2rem" }}>
                    <p className="rs__heading1">Phone Number</p>
                    <Box>
                      {partOneEdit ? (<>
                        <TextField variant="standard" value={userInfo?.phone}
                          onChange={(e) => setUserInfo({ ...userInfo, phone: handleNumber(e.target.value) })}
                        />


                      </>) : (<>
                        <p className="rs__headingDetails">{userInfo?.phone}</p>
                      </>)}

                    </Box>

                  </div>
                  <div style={{ width: "50%" }}>
                    <p className="rs__heading1">Website</p>
                    <Box>
                      {partOneEdit ? (<>

                        <TextField variant="standard" value={userInfo?.aboutCompany?.website}
                          onChange={(e) => setUserInfo({ ...userInfo, aboutCompany: { ...userInfo?.aboutCompany, website: e.target.value } })}
                        />

                      </>) : (<>

                        <p className="rs__headingDetails">{userInfo?.aboutCompany?.website}</p>
                      </>)}

                    </Box>

                  </div>
                </div>

                <div>
                  <p className="rs__heading1">Company Address</p>
                  <Box>
                    {partOneEdit ? (<>

                      <p className="rs__headingDetails">
                        <TextField variant="standard" value={userInfo?.address?.address1}
                          onChange={(e) => setUserInfo({ ...userInfo, address: { ...userInfo?.address, address1: e.target.value } })}
                        />
                      </p>
                      <p className="rs__headingDetails">
                        City: <TextField variant="standard" value={userInfo?.address?.city}
                          onChange={(e) => setUserInfo({ ...userInfo, address: { ...userInfo?.address, city: e.target.value } })}
                        />

                      </p>
                      <p className="rs__headingDetails"> State: <TextField
                        variant="standard" value={userInfo?.address?.state}
                        onChange={(e) => setUserInfo({ ...userInfo, address: { ...userInfo?.address, state: e.target.value } })}
                      /> </p>
                      <p className="rs__headingDetails">Pincode: <TextField
                        variant="standard" value={userInfo?.address?.pincode}
                        onChange={(e) => setUserInfo({ ...userInfo, address: { ...userInfo?.address, pincode: handleNumber(e.target.value) } })}
                      /></p>




                    </>) : (<>



                      <p className="rs__headingDetails"> {userInfo?.address?.address1}</p>
                      <p className="rs__headingDetails">
                        City: {userInfo?.address?.city}

                      </p>
                      <p className="rs__headingDetails"> State: {userInfo?.address?.state}</p>
                      <p className="rs__headingDetails">Pincode: {userInfo?.address?.pincode}</p>

                    </>)}

                  </Box>

                </div>
              </div>

              <div className="ls__box" style={{ padding: "1rem 2rem" }}>
                <div style={{ display: "flex" }}>
                  <p className="rs__heading">Company Information</p>

                  {partTwoEdit ? (<StyledBlackButton onClick={() => { setPartTwoEdit(!partTwoEdit) }}>Save Edit</StyledBlackButton>) :
                    (<StyledBlackButton onClick={() => setPartTwoEdit(!partTwoEdit)}>Edit</StyledBlackButton>)}


                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", marginRight: "2rem" }}>
                    <p className="rs__heading1">Company Type</p>

                    <Box>
                      {partTwoEdit ? (<>
                        <TextField variant="standard" value={userInfo?.aboutCompany?.type}
                          onChange={(e) => setUserInfo({ ...userInfo, aboutCompany: { ...userInfo?.aboutCompany, type: e.target.value } })}
                        />
                      </>) : (<>

                        <p className="rs__headingDetails">{userInfo?.aboutCompany?.type}</p>
                      </>)}
                    </Box>
                  </div>
                  <div style={{ width: "50%" }}>
                    <p className="rs__heading1">Company Stage</p>
                    <Box>
                      {partTwoEdit ? (<>
                        <TextField variant="standard" value={userInfo?.aboutCompany?.stage}
                          onChange={(e) => setUserInfo({ ...userInfo, aboutCompany: { ...userInfo?.aboutCompany, stage: e.target.value } })}
                        />
                      </>) : (<>


                        <p className="rs__headingDetails">{userInfo?.aboutCompany?.stage}</p>
                      </>)}
                    </Box>

                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", marginRight: "2rem" }}>
                    <p className="rs__heading1">Team Size</p>
                    <Box
                    >
                      {partTwoEdit ? (<>
                        <TextField variant="standard" value={userInfo?.aboutCompany?.teamSize}
                          onChange={(e) => setUserInfo({ ...userInfo, aboutCompany: { ...userInfo?.aboutCompany, teamSize: handleNumber(e.target.value) } })}
                        />
                      </>) : (<>

                        <p className="rs__headingDetails">{userInfo?.aboutCompany?.teamSize}</p>
                      </>)}
                    </Box>
                  </div>
                  <div style={{ width: "50%" }}>
                    <p className="rs__heading1">Est Since</p>
                    <Box>
                      {partTwoEdit ? (<>
                        <TextField variant="standard" value={userInfo?.aboutCompany?.estSince}
                          onChange={(e) => setUserInfo({ ...userInfo, aboutCompany: { ...userInfo?.aboutCompany, estSince: handleNumber(e.target.value) } })}
                        />
                      </>) : (<>

                        <p className="rs__headingDetails">{userInfo?.aboutCompany?.estSince}</p>
                      </>)}
                    </Box>

                  </div>
                </div>

                <div>
                  <p className="rs__heading1">Company Video</p>
                  <Box>
                    {partTwoEdit ? (<>
                      <TextField variant="standard" value={userInfo?.aboutCompany?.video}
                        onChange={(e) => setUserInfo({ ...userInfo, aboutCompany: { ...userInfo?.aboutCompany, video: e.target.value } })}
                      />
                    </>) : (<>
                      <p className="rs__headingDetails">{userInfo?.aboutCompany?.video}</p>
                    </>)}
                  </Box>

                  {/* <iframe style={{ marginLeft: ".5rem", marginBottom: ".5rem",boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "10px" }} width="540" height="357" src={data.cintrovediolink} allowFullScreen></iframe> */}
                </div>

                <div>
                  <p className="rs__heading1">Social Media Links</p>

                  <Box>
                    {partTwoEdit ? (<>

                      <p className="rs__headingDetails">Facebook:  <TextField variant="standard" value={userInfo?.socialNetwork?.facebook}
                        onChange={(e) => setUserInfo({ ...userInfo, socialNetwork: { ...userInfo?.socialNetwork, facebook: e.target.value } })}
                      /></p>

                      <p className="rs__headingDetails">LinkedIn:
                        <TextField variant="standard" value={userInfo?.socialNetwork?.linkedIn}
                          onChange={(e) => setUserInfo({ ...userInfo, socialNetwork: { ...userInfo?.socialNetwork, linkedIn: e.target.value } })}
                        /></p>
                      <p className="rs__headingDetails">Twitter:
                        <TextField variant="standard" value={userInfo?.socialNetwork?.twitter}
                          onChange={(e) => setUserInfo({ ...userInfo, socialNetwork: { ...userInfo?.socialNetwork, twitter: e.target.value } })}
                        /></p>
                    </>) : (<>
                      <p className="rs__headingDetails">Facebook: {userInfo?.socialNetwork?.facebook}</p>
                      <p className="rs__headingDetails">LinkedIn: {userInfo?.socialNetwork?.linkedIn}</p>
                      <p className="rs__headingDetails">Twitter: {userInfo?.socialNetwork?.twitter}</p>
                    </>)}


                  </Box>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Box>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Cant Able to Update the Profile. Try Again</Alert>
      </Snackbar>
      <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Profile is Updated</Alert>
      </Snackbar>
    </Box>
    </>
  );

}

export default RecruiterProfile;
