import React from 'react'
import Navbar from 'components/Navbar'
import { Box, Button } from '@mui/material'
import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
// import {postContactUs}  from 'components/Fetchers/others'
import {postContactUs}  from 'components/Fetchers'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
const ContactUs = () => {

  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };

    const [userInput, setUserInput] = useState({
        name: "",
        email: "",
        phone:"",
        company:"",
        description:""
    })
    const handleSubmit = (e) => {
        console.log('submit')
        e.preventDefault()
        if(userInput.name === "" || userInput.email === "" || userInput.phone === ""){
            alert("Please fill out all required fields")
        }else{
            postContactUs(userInput).then((res) => {
                console.log(res)
                onSuccess()
            }).catch((err) => {
                onError()
                console.log(err)
            })
        }
    }

    const handleNumber = (value) => {
        return value.replace(/\D/g, '');
      };
   

    
    return (<>

        <Navbar />
        <Box>
            <Box
                sx={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    alignItems: "center",
                    flexDirection: "column",
                    marginBottom: "3rem",
                    borderRadius: 5,
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
                    maxWidth: "lg",

                }}

            >

                <Typography variant="h4" sx={{ margin: "1.5rem", fontFamily: 'outfit' }}>Contact Us</Typography>
              
                <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "1rem" }}>
                <TextField 
                label="Name"
                required
                value={userInput.name}
                variant="outlined"
                sx={{width: "30rem", marginBottom: "1rem"}}
                onChange={(e) => setUserInput({ ...userInput, name: e.target.value })}
                />
                 <TextField 
                 required
                label="Email"
                value={userInput.email}
                variant="outlined"
                sx={{width: "30rem", marginBottom: "1rem"}}
                onChange={(e) => setUserInput({ ...userInput, email: e.target.value })}
                />
                 <TextField 
                 required
                label="Phone Number"
                variant="outlined"
                value={userInput.phone}
                sx={{width: "30rem", marginBottom: "1rem"}}
                onChange={(e) => setUserInput({ ...userInput, phone: handleNumber(e.target.value) })}
                />
                 <TextField 
                label="Company"
                variant="outlined"
                value={userInput.company}
                sx={{width: "30rem", marginBottom: "1rem"}}
                onChange={(e) => setUserInput({ ...userInput, company: e.target.value })}
                />
                 <TextField 
                label="Description"
                multiline
                rows={6}
                value={userInput.description}   
                variant="outlined"
                sx={{width: "30rem", marginBottom: "1rem"}}
                onChange={(e) => setUserInput({ ...userInput, description: e.target.value })}
                />
                <Button variant="contained" type='submit' sx={{width: "30rem", marginBottom: "1rem",padding:'0.8rem',fontWeight:700}}>Submit</Button>
               
                </Box>
                </form>

               





               
            </Box>


        </Box>
        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Cant Able To Connect To Backend Server</Alert>
      </Snackbar>
      <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Enquiry is Submitted</Alert>
      </Snackbar>
    </>

    )
}

export default ContactUs