import React from "react";
import Avatar from "@mui/material/Avatar";
import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  MenuIcon,
  KeyboardArrowUpIcon,
} from "components/MUI";
import { Link } from "react-router-dom";
import { useAuth } from "utils/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/system";
import companyLogo from "images/companyLogo.png";
import { namedColors } from "@progress/kendo-drawing";
import { StyledTypography } from "components/StyledComponents";

function Navbar() {
  const auth = useAuth();
  const userType = auth.userType;
  let heading = "WorkCrew";
  let Candidatelinks = [
    { name: "Home", link: "/" },
    { name: "Dashboard", link: "/candidate/dashboard" },
    { name: "Jobs", link: "/jobboard" },
    {
      name: "Blog",
      link: "https://blog.workcrew.ai/",
      target: "_blank",
      href: true,
    },
    { name: "Contact Us", link: "/contactus" },
  ];
  let Recruiterlinks = [
    { name: "Home", link: "/", target: "" },
    { name: "Dashboard", link: "/recruiter/dashboard", target: "" },
    { name: "Post Job", link: "/postjob", target: "" },
    {
      name: "Blog",
      link: "https://blog.workcrew.ai/",
      target: "_blank",
      href: true,
    },
    { name: "Contact Us", link: "/contactus", target: "" },
  ];
  let Adminlinks = [
    { name: "Home", link: "/" },
    { name: "Dashboard", link: "/admin/dashboard" },
    { name: "Jobs", link: "/admin/jobs" },
    { name: "Recruiter", link: "/admin/recruiters" },
    { name: "Company", link: "/admin/companies" },
    {
      name: "Blog",
      link: "https://blog.workcrew.ai/",
      target: "_blank",
      href: true,
    },
    // { name: "Contact Us", link: "/contactus" },
  ];
  let visitorLinks = [
    {
      name: "Blog",
      link: "https://blog.workcrew.ai/",
      target: "_blank",
      href: true,
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuComponent = ({ children }) => {
    return (
      <>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {children}
        </Menu>
      </>
    );
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };
  const handleCandidateProfile = () => {
    navigate("/candidate/profile");
  };
  const handleRecruiterProfile = () => {
    navigate("/recruiter/profile");
  };
  const handleAdminProfile = () => {
    console.log("admin");
    navigate("/recruiter/profile");
  };

  const toSignup = () => {
    navigate("/signup");
  };

  // console.log(auth)
  const profileAvatar = () => {
    return (
      <>
        <Button
          disableRipple
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            backgroundColor: "transparent",
          }}
        >
          <Avatar
            className="navbar__avatar"
            // alt="Karan"
            src="components/images/avatar.png"
          />
        </Button>
      </>
    );
  };

  const [humburger, setHumburger] = useState(true);
  const handleHumburgerClick = () => {
    setHumburger(!humburger);
  };
  const linkGen = (array) => {
    return array.map((link) => {
      if (link.href)
        return (
          <a
            href={link.link}
            key={link.name}
            target={link.target}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <StyledTypography variant="p" component="p" key={link.name}>
              {link.name}
            </StyledTypography>
          </a>
        );
      else
        return (
          <Link
            to={link.link}
            key={link.name}
            target={link.target}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <StyledTypography variant="p" component="p" key={link.name}>
              {link.name}
            </StyledTypography>
          </Link>
        );
    });
  };
  const candidateLinkGenerator = () => {
    return linkGen(Candidatelinks);
  };

  const recruiterLinkGenerator = () => {
    return linkGen(Recruiterlinks);
  };
  const adminLinkGenerator = () => {
    return linkGen(Adminlinks);
  };
  const visitorLinkGenerator = () => {
    return linkGen(visitorLinks);
  };

  const userTypeNavItemsShow = (userType) => {
    if (userType === "candidate") {
      return (
        <>
          {humburger ? (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              >
                {candidateLinkGenerator()}
              </Box>
            </>
          ) : (
            <>
              {candidateLinkGenerator()}
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  margin: "auto",
                }}
              >
                <KeyboardArrowUpIcon onClick={handleHumburgerClick} />
              </Box>
            </>
          )}
        </>
      );
    } else if (userType === "recruiter") {
      return (
        <>
          {" "}
          {humburger ? (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              >
                {recruiterLinkGenerator()}
              </Box>
            </>
          ) : (
            <>
              {recruiterLinkGenerator()}
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  margin: "auto",
                }}
              >
                <KeyboardArrowUpIcon onClick={handleHumburgerClick} />
              </Box>
            </>
          )}{" "}
        </>
      );
    } else if (userType === "admin" || userType === "super_admin") {
      return (
        <>
          {humburger ? (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              >
                {adminLinkGenerator()}
              </Box>
            </>
          ) : (
            <>
              {adminLinkGenerator()}
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  margin: "auto",
                }}
              >
                <KeyboardArrowUpIcon onClick={handleHumburgerClick} />
              </Box>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {humburger ? (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              >
                {visitorLinkGenerator()}
              </Box>
            </>
          ) : (
            <>
              {visitorLinkGenerator()}
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  margin: "auto",
                }}
              >
                <KeyboardArrowUpIcon onClick={handleHumburgerClick} />
              </Box>
            </>
          )}
        </>
      );
    }
  };

  const userTypeProfileShow = (userType) => {
    switch (userType) {
      case "candidate":
        return (
          <>
            {profileAvatar()}
            <MenuComponent>
              <MenuItem onClick={handleCandidateProfile}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuComponent>
          </>
        );
      case "recruiter":
        return (
          <>
            {profileAvatar()}
            <MenuComponent>
              <MenuItem onClick={handleRecruiterProfile}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuComponent>
          </>
        );
      case "admin":
        return (
          <>
            {profileAvatar()}
            <MenuComponent>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuComponent>
          </>
        );

      case "super_admin":
        return (
          <>
            {profileAvatar()}
            <MenuComponent>
              {/* <MenuItem onClick={handleAdminProfile}>
                        Profile
                    </MenuItem> */}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuComponent>
          </>
        );

      default:
        return (
          <>
            <img
              className="header__navbar_img"
              onClick={toSignup}
              src="https://i.ibb.co/9WJTHnx/user.png"
              alt=""
            />
            <p className="header__navbarButton">
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "#023047" }}
              >
                Login
              </Link>
              /
              <Link
                to="/signup"
                style={{ textDecoration: "none", color: "#023047" }}
              >
                Signup
              </Link>
            </p>
          </>
        );
    }
  };

  return (
    <>
      <Box
        className="navbar"
        sx={{
          display: "flex",
          maxWidth: "xl",
          margin: "auto",
          width: "100%",
          // border: 1,
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            // backgroundColor: '#fff',
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              // flexGrow: 1,
              // width: '100%',
              alignItems: "center",
              paddingLeft: "3rem",
              // backgroundColor: '#fff',
              //    width: '100%',
            }}
          >
            <Link to="/">
              <img
                src={companyLogo}
                alt="companyLogo"
                style={{
                  width: "200px",
                  height: "auto",
                }}
              />
            </Link>

            {humburger ? (
              <>
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "flex",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                    // marginTop:'auto',
                    // marginBotom:'0.5rem'
                    margin: "auto",
                  }}
                >
                  <MenuIcon onClick={handleHumburgerClick} />
                </Box>
              </>
            ) : (
              <></>
            )}
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                // marginLeft: 'auto',
              }}
            >
              {userTypeProfileShow(userType)}
            </Box>
          </Box>
          {/* Links */}
          {userTypeNavItemsShow(userType)}
        </Box>
        <Box>
          {/* Profile */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              paddingRight: "3rem",
            }}
          >
            {userTypeProfileShow(userType)}
          </Box>
        </Box>
        {/* <p onClick={""} className='header__navbarButton'>Login/Signup</p> */}
      </Box>
    </>
  );
}

export default Navbar;
