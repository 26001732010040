import React from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TelegramIcon,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon,
    EmailIcon
} from "react-share";
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';

const SocialShare = ({ shareUrl, job }) => {
    // console.log(job)
    const titleGen = (job) => {
        if (job) {
            const companyName = job?.company?.companyName
            const jobTitle = job?.title
            const title = companyName + " is hiring for " + jobTitle
            return title
        }
    }
    const jobTitle = titleGen(job)
    const summaryGen = (job) => {
        if (job) {
            const companyName = job?.company?.companyName
            const jobTitle = job?.title
            const jobDescription = job?.description
            const summary = companyName + " is hiring for " + jobTitle + "\n" + jobDescription
            // const summary=`${companyName} is hiring for "+${jobTitle}
            // ${jobDescription}`
            return summary
        }
    }
    const jobSummary = summaryGen(job)
    const linkedinGen = (job) => {
        const linkedInPost = {
            "https://www.linkedin.com/shareArticle?mini": "true",
            source: 'workCrew',
            title: jobTitle,
            //  summary:"jobSummary",
            url: "https://google.com",
        }
        return linkedInPost
    }
    const linkedinPost = linkedinGen(job)
    // var queryString = 

    const linkedinLinkGen = (data) => {
        return Object.keys(data).map(key => key + '=' + data[key]).join('&');
    }

    const queryString = linkedinLinkGen(linkedinPost)
    const linkedinSummary = linkedinGen(job)





    return (
        <>
            <Box className='socialMediaShare'
                sx={{
                    margin: '0.5rem',
                    display: 'flex',
                    gap: '0.5rem',
                    flexWrap: 'wrap',
                }}>

                <WhatsappShareButton
                    url={shareUrl}
                    title={jobTitle}
                    separator={'\n'}
                >
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                < LinkedinShareButton
                    url={shareUrl}
                    // url="google.com"
                    title={jobTitle}
                // summary={jobSummary}
                // source={shareUrl}

                >
                    <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
                < FacebookShareButton
                    url={shareUrl}
                    quote={jobTitle}
                // url={"google.com"}
                >


                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                    title={jobTitle}
                    url={shareUrl}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <EmailShareButton
                    subject={jobTitle}
                    body={jobSummary}
                    separator={'\n'}
                    url={shareUrl} >
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
                <TelegramShareButton
                    url={shareUrl}
                    title={jobTitle}
                >
                    <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
                <EmailShareButton
                    onClick={() => {
                        navigator.clipboard.writeText(shareUrl)
                        // alert('Link Copied to Clipboard')
                    }}
                >
                    <LinkIcon />
                </EmailShareButton>
                {/* <div> {queryString}</div> */}





            </Box>
        </>
    )
}

export default SocialShare