import React, { useEffect, useState } from 'react'
import {
    Box,Chip,EditIcon,DoneIcon,DeleteIcon,TextField,Autocomplete
} from 'components/MUI'

import {StyledBrownButton}from 'components/StyledComponents';
import { patchSkills } from 'components/Fetchers';
const Skills = ({candidate,candidateData,candidateId}) => {
    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };
    // console.log(candidateData)
    const skillGenerator = (skill) => {
        return { key: (Math.floor(Math.random() * 10000000) + 1), label: skill }
    }

    // console.log(skillGenerator(apiSkills[0]))
    //convert skills from api to chipData
    // const [chipData, setChipData] = React.useState();
    // console.log("candidateData Skills")
    // console.log(candidateData.skills)
    const skillOptions = ['C++', 'C', 'Python']
    const [chipData, setChipData] = React.useState(candidateData?.skills?.map(skillGenerator));
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if(candidateData.skills){
            setChipData(candidateData.skills.map(skillGenerator))
        }
    }, [candidateData])

    const getSkillsFromChipData = () => {
        let skills = []
        chipData.forEach((data) => {
            skills.push(data.label)
        })
        console.log(skills)
        return skills
    }

    const submitSkills = async () => {
        let skills = getSkillsFromChipData(chipData)
        console.log(skills)
        const response = await patchSkills(candidateId, skills)
        console.log(response)
    }

   
 
    return (
        <Box className='projectsW_Full'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                
            }}>
                {/* <button onClick={getSkillsFromChipData}>click me</button> */}
            <div className='projectsW__heading'>Skills</div>

            <Box sx={{ margin: "1.2rem 0rem 0.5rem 1rem", display: 'flex' }}>
              
              {candidate ? (<>{edit ? (<>
                    <Box sx={{ flex: '1' ,flexDirection:'column'}}>
                        {chipData?.map((data) => {
                            return (
                                <Chip
                                    sx={{ margin: '0.2rem', bgcolor: '#ECB365', border: '1', borderColor: 'black', padding: '0.2rem' }}
                                    key={data.key}
                                    label={data.label}
                                    variant="outlined"
                                />
                            );
                        })}
                        <div>
                        <StyledBrownButton
                            variant="outlined"
                            size="small"
                            onClick={() => {
                                setEdit(!edit)}}
                        >
                            Add Skills
            </StyledBrownButton>
            </div>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <EditIcon
                            sx={{
                                fontSize: 15,
                                color: "lightgray",
                                margin: "auto 0rem",
                                "&:hover": { color: "gray", cursor: "pointer" },
                                display: "flex",
                            }}
                            onClick={() => {
                                setEdit(!edit);
                            }}
                        />

                    </Box>
                </>) : (<>
                    <Box sx={{ flex: '1' }}>
                        {chipData?.map((data) => {
                            return (
                                <Chip
                                    sx={{ margin: '0.2rem', bgcolor: '#ECB365', border: '1', borderColor: 'black', padding: '0.2rem' }}
                                    key={data.key}
                                    label={data.label}
                                    variant="outlined"
                                    onDelete={()=>setChipData(chipData.filter((item) => item.key !== data.key))}/>
                            );
                        })}
    
                    </Box>
                    <Box sx={{flex:0.5}}>
                        <Autocomplete
                            sx={{  mx:'2rem' }}
                            key={chipData?.length}
                            options={skillOptions}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{border:"2!important"}}
                                    placeholder="Skills"
                                />
                            )}
                            
                            freeSolo
                            onChange={(e, value) => {
                                if(value){
                                setChipData( [...chipData, { key: chipData.length, label: value }]);
                                }
                          }}
                        />
                        <div></div>
                  
                    </Box>
                   
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <DoneIcon
                            sx={{
                                fontSize: 15,
                                color: "gray",
                                margin: "auto 0rem",
                                "&:hover": { color: "black", cursor: "pointer" },
                                display: "flex",
                            }}
                            onClick={() => {
                                // setEdit(!edit);
                                submitSkills()
                            }}
                        />

                    </Box></>)} </>) : (<>

                        <div style={{display:'flex',flexWrap:'wrap'}}>
                        {chipData?.map((data) => {
                            return (
                                <Box
                                    sx={{ margin: '0.3rem', backgroundColor: '#ECB365', border: '1',borderRadius:'10rem',borderColor:'black',border: 1, borderColor: 'black', padding: '0.5rem' }}
                                    key={data.key}
                                  
                                >{data.label}</Box>
                            );
                        })}
                        </div>

                        
                        {/* <div>
                        {chipData.map((data) => {
                            return (
                                <Chip
                                    sx={{ margin: '0.2rem', bgcolor: '#ECB365', border: '1', borderColor: 'black', padding: '0.2rem' }}
                                    key={data.key}
                                    label={data.label}
                                    text={data.label}
                                    variant="outlined"
                                />
                            );
                        })}
                        </div> */}
                        {/* {chipData.map((data) => {
                            return (
                                <Chip
                                    sx={{ margin: '0.2rem', bgcolor: '#ECB365', border: '1', borderColor: 'black', padding: '0.2rem' }}
                                    key={data.key}
                                    label={data.label}
                                    text={data.label}
                                    variant="outlined"
                                />
                            );
                        })} */}
                      
                    </>)}
                    
                
            </Box>
            
            
        </Box>
    )
}


export default Skills
